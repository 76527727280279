import api, { apiConfig } from 'constants/api'
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects'
import * as types from 'actions/areas/types'
import * as notifyTypes from 'actions/notifications/types'
import formSelector from './selectors/form'

const publicApi = apiConfig({ baseURL: '/api' })

function* getAreas({ payload }) {
  try {
    const statePage = yield select(state => state.areas.page)
    const stateLimit = yield select(state => state.areas.limit)
    const { limit = stateLimit, page = statePage } = payload
    const selector = yield formSelector('admin-areas-filter-form')
    const keyword = yield select(state => selector(state, 'admin_search_filter'))
    const place = yield select(state => selector(state, 'admin_place_filter'))
    const status = yield select(state => selector(state, 'admin_filter_status'))
    const placePayload = place && place.map(p => p.value)
    const { data } = yield call(api.get, '/areas', {
      params: {
        limit,
        page,
        name: keyword,
        places: placePayload,
        published: status
      },
    })

    yield delay(300) // to simulate tableloader
    console.log('getAreas', data)
    yield put({ type: types.FETCHED_AREAS, response: data.area })
  } catch ({ response }) {
    if (response) console.log(response.message)
  }
}

function* getArea({ payload }) {
  try {
    const { data } = yield call(api.get, `/areas/${payload}`)
    yield put({ type: types.FETCHED_AREA, area: data.area })
  } catch (error) {
    console.log(error)
  }
}

function* getAreasPerCity({ payload }) {
  try {
    const { data } = yield call(api.get, `/areas/by-city-id/${payload}`)
    yield put({ type: types.FETCHED_AREAS_PER_CITY, areas: data })
  } catch ({ response }) {
    if (response) console.error(response.data)
  }
}

function* createArea({ payload }) {
  try {
    const { areaName, place, published, description } = payload

    const { data } = yield call(api.post, '/areas', {
      name: areaName,
      place,
      is_published: published,
      description,
    })

    yield all([put({ type: notifyTypes.SUCCESS, ...data }),
    put({ type: types.CREATED_AREA }),
    put({ type: types.FETCH_AREA_OPTIONS })
    ])
  } catch ({ response }) {
    if (response) console.log(response.data)
    yield put({ type: notifyTypes.ERROR, ...response.data })
  }
}

function* editArea({ payload }) {
  try {
    const { data } = yield call(api.put, `/areas/${payload.id}`, payload)

    yield all([put({ type: notifyTypes.SUCCESS, ...data }),
    put({ type: types.EDITED_AREA }),
    put({ type: types.FETCH_AREA_OPTIONS })
    ])
  } catch ({ response }) {
    if (response) console.log(response.data)
    yield put({ type: notifyTypes.ERROR, ...response.data })
  }
}

function* reloadPage() {
  const form = yield select(state => state.form)
  const payload = form['admin-areas-filter-form'].values
  yield call(getAreas, payload ? { payload } : { payload: {} })
}

function* getAreaOptions({ payload }) {
  try {
    const { place } = payload

    const { data } = yield call(api.get, '/area/options', {
      params: {
        place,
      },
    })

    const options = data.map(area => {
      return {
        label: area.name,
        value: area.slug,
      }
    })

    yield put({ type: types.FETCHED_AREA_OPTIONS, options })
  } catch (err) {
    console.log(err)
  }
}

function* deleteArea({ payload }) {
  try {
    yield call(api.delete, `areas/${payload}`)
    yield all([put({ type: notifyTypes.SUCCESS, message: `Delete Success` }),
    put({ type: types.FETCH_AREA_OPTIONS })])
    yield call(reloadPage, {})
  } catch (err) {
    yield put({ type: notifyTypes.ERROR, message: `Delete Fail` })
  }
}

function* quickSearchAreas({ payload, resolve }) {
  try {
    const { data } = yield call(publicApi.get, '/spaces/areas/quick-search', {
      params: { name: payload },
    })
    resolve(data)
  } catch (error) {
    yield put({ type: notifyTypes.ERROR, message: error.message })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_AREAS_PER_CITY, getAreasPerCity)
  yield takeLatest(types.CREATE_AREA, createArea)
  yield takeLatest(types.EDIT_AREA, editArea)
  yield takeLatest(types.FETCH_AREAS, getAreas)
  yield takeLatest(types.FETCH_AREA, getArea)
  yield takeLatest(types.FETCH_AREA_OPTIONS, getAreaOptions)
  yield takeLatest(types.DELETE_AREA, deleteArea)
  yield takeLatest(types.QUICK_SEARCH_AREA, quickSearchAreas)
}

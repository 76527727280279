import React from 'react';
import PropTypes from 'prop-types';

const LoadingOverlay = ({ loading, children }) => {
  const layerAndContentClassNames = `fixed w-full h-full top-0${!loading ? ' -z-1' : ''}`;

  const layerSteadyClassNames = 'opacity-0 bg-white';
  const layerLoadingClassNames = 'z-199 opacity-75';

  const contentLoadingClassNames = 'justify-center flex z-200 items-center flex-col';

  const layerClassNames = `${layerAndContentClassNames} ${layerSteadyClassNames}${loading ? ` ${layerLoadingClassNames}` : ''}`;
  const contentClassNames = `${layerAndContentClassNames}${loading ? ` ${contentLoadingClassNames}` : ''}`;

  return (
    <div>
      {
        loading && <div>
          <div className={layerClassNames}></div>
          <div className={contentClassNames}>
            <i className="fa fa-spinner fa-spin fa fa-spinner fa-spin mb-4 text-6xl text-orange-500" />
          </div>
        </div>
      }
      {children}
    </div>
  );
};

LoadingOverlay.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default LoadingOverlay;

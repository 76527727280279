import { stopSubmit, setSubmitFailed, change } from 'redux-form';
import { fork, put, select, all } from 'redux-saga/effects';

function* getSyncErrorMessages() {
  const errors = yield select(state => state.auth.errors);
  const target = yield select(state => state.auth.target);
  const correctValues = yield select(state => state.auth.correctValues);
  if (errors && errors.length > 0) {
    const fieldErrors = errors.reduce((acc, error) => {
      acc[error.type] = error.message;
      return acc;
    }, {});

    yield all([
      ...errors.map(error => put(setSubmitFailed(target, error.type))),
      ...errors.map(error => put(change(target, error.type, error.input))),
      put(stopSubmit(target, fieldErrors)),
    ]);
  }
  yield correctValues && all([
    ...correctValues.map(value => value.input && put(change(target, value.field, value.input))),
  ]);
}


export default function* () {
  yield fork(getSyncErrorMessages)
}

import { put, call, takeLatest, select, delay } from 'redux-saga/effects'
import FileDownload from 'js-file-download'
import _ from 'lodash'
import * as types from 'actions/buildings/types'
import * as notifTypes from 'actions/notifications/types'
import { apiConfig } from 'constants/api'
import { change } from 'redux-form'
import formSelector from './selectors/form'

const apiAdmin = apiConfig({ baseURL: '/api/admin' })
const publicApi = apiConfig({ baseURL: '/api' })

// ADMIN
function* adminFetchBuildings({ payload }) {
  try {
    yield delay(300)
    const selector = yield formSelector('admin-buildings-filter-form')
    const search_bar = yield select((state) => selector(state, 'search_bar'))
    const place = yield select((state) => selector(state, 'place_filter'))
    const brand = yield select((state) => selector(state, 'brand_filter'))
    const classification_id = yield select((state) =>
      selector(state, 'building_classification_filter')
    )
    const buildingUses = yield select((state) => selector(state, 'building_use_filter'))
    const buildingArea = yield select((state) => selector(state, 'area_filter'))
    const buildingStatus = yield select((state) => selector(state, 'building_status'))
    const building_status = buildingStatus === '*' ? undefined : buildingStatus;
    const place_ids = []
    place && place_ids.push(place)
    const brand_ids = []
    brand && brand_ids.push(brand.value)
    const building_use_ids = []
    buildingUses && building_use_ids.push(buildingUses)
    const area_id = []
    buildingArea && area_id.push(buildingArea)
    const merchantFilter = yield select((state) => selector(state, 'merchant_filter'))
    const merchant_ids = merchantFilter ? merchantFilter.map((merchant) => merchant.value) : []
    const { limit, page } = payload
    const { data } = yield call(apiAdmin.get, `/building`, {
      params: {
        size: limit,
        page,
        search_bar: search_bar ?? payload.searchKeyword ?? '',
        place_ids,
        brand_ids,
        merchant_ids,
        building_use_ids,
        classification_id,
        area_id,
        building_status,
      },
    })
    yield put({ type: types.ADMIN_FETCHED_BUILDINGS, buildings: data })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, error })
  }
}

function* fetchBuildingDetails({ payload }) {
  try {
    const { data } = yield call(apiAdmin.get, `/building/${payload}`)
    yield put({ type: types.ADMIN_FETCHED_BUILDING_DETAILS, building: data })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}

function* adminFetchBuildingUse() {
  try {
    const { data } = yield call(publicApi.get, `/building-use/options`)
    yield put({ type: types.ADMIN_FETCHED_BUILDING_USE, buildingUse: data })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, error })
  }
}

function* adminFetchBuildingArea({ payload }) {
  try {
    const { data } = yield call(publicApi.get, '/areas/quick-search', { params: { name: payload } })
    yield put({ type: types.ADMIN_FETCHED_BUILDING_AREA, buildingArea: data })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}

function* adminBuildingChangePublish({ payload }) {
  try {
    const { data } = yield call(apiAdmin.put, `/building/${payload.id}/changePublish`)
    yield put({ type: notifTypes.SUCCESS, message: 'Change Succesfully' })
    const details = yield select((state) => state.building.admin.details)
    yield put({
      type: types.ADMIN_FETCHED_BUILDING_DETAILS,
      building: { ...details, published: data.published ? 1 : 0 },
    })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}

function* updateBuilding({ payload }) {
  const { values, formName } = payload
  try {
    const bodyRequest = values && {
      ...values,
      lat: values?.latLng?.lat ?? values.lat,
      lng: values?.latLng?.lng ?? values.lng,
      gmaps_address: values?.latLng?.address ?? values?.gmaps_address,
      venue_ids: values?.venues ? values.venues.map(({ value }) => value) : [],
      merchant_ids: values?.merchants ? values.merchants.map(({ value }) => value) : [],
      brand_ids: values?.brands ? values.brands.map(({ value }) => value) : [],
      building_uses:
        (values.buildingUses?.length && values?.buildingUses?.map((item) => item.value)) || [],
    }

    if (!bodyRequest.buildingUses.length) {
      delete bodyRequest.buildingUses
    }

    yield call(apiAdmin.put, `/building/${bodyRequest.id}`, bodyRequest)
    yield put({ type: notifTypes.SUCCESS, message: 'Update Succesfully' })
  } catch ({ response, message }) {
    const errors = { errors: response?.data || [], formName }
    yield put({
      type: notifTypes.ERROR,
      message: message ?? 'Error edit building.',
      payload: errors,
    })
  }
}

function* createBuilding({ payload }) {
  const { values, formName } = payload
  try {
    let bodyRequest = values || {}
    if (values.gmaps_address)
      bodyRequest = {
        ...bodyRequest,
        lat: values.gmaps_address.lat,
        lng: values.gmaps_address.lng,
        gmaps_address: values.gmaps_address.address,
      }
    if (!_.isEmpty(values.buildingUses))
      payload.building_use_ids = values.buildingUses.map((b) => b.id)

    bodyRequest = {
      ...bodyRequest,
      building_use_ids: payload.building_use_ids,
      venue_ids: values?.venues ? values.venues.map(({ value }) => value) : [],
      merchant_ids: values?.merchants ? values.merchants.map(({ value }) => value) : [],
      brand_ids: values?.brands ? values.brands.map(({ value }) => value) : [],
    }

    yield call(apiAdmin.post, '/building', bodyRequest)
    yield put({ type: notifTypes.SUCCESS, message: 'Create Building Succesfully' })
  } catch ({ message, response }) {
    const errors = { errors: response?.data || [], formName }
    yield put({
      type: notifTypes.ERROR,
      message: message ?? 'Error create building.',
      payload: errors,
    })
  }
}

function* deleteBuilding({ payload }) {
  try {
    yield call(apiAdmin.delete, `/building/${payload}`, payload)
    yield call(adminFetchBuildings, { payload: {} })
    yield put({ type: notifTypes.SUCCESS, message: 'Delete Succesfully' })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}

function* exportBuildings() {
  try {
    const response = yield call(apiAdmin.get, '/export-bookings', { responseType: 'arraybuffer' })
    FileDownload(response.data, 'bookings.xlsx')
    yield put({ type: types.EXPORT_BUILDINGS })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error Export Bookings' })
  }
}

function* handleFetchBuildingDetailsRequest({ payload }) {
  try {
    const { data } = yield call(publicApi.get, `/building/${payload}`)
    yield put({ type: types.FETCH_BUILDING_DETAILS_SUCCESS, payload: data })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error fetch building' })
  }
}

function* handleFetchSuggestionBuildingsRequest({ payload }) {
  try {
    const { data } = yield call(publicApi.get, `/building/suggestion`, {
      params: payload })
    yield put({ type: types.FETCH_SUGGESTION_BUILDING_SUCCESS, payload: data })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error fetch suggestion buildings' })
  }
}

function* handleFetchBuildingListingVenuesRequest({ payload }) {
  try {
    const { data } = yield call(publicApi.get, `/venues/venue-building/${payload.id}`, {
      params: {
        ...payload,
      },
    })
    const result = {
      list: data.results,
      page: data.page,
      limit: data.limit,
      total: data.total,
      lastPage: data.lastPage,
    }
    yield put({ type: types.FETCH_BUILDING_LISTING_VENUE_SUCCESS, payload: result })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error fetch building venues' })
  }
}

function* fetchLandLord({ payload }) {
  try {
    const { name, place_id } = payload
    const { data } = yield call(
      apiAdmin.get,
      `/landlord/options?place_id=${place_id}&name=${name ?? ''}`
    )
    yield put({ type: types.FETCHED_LAND_LORD, payload: data })
  } catch ({ response }) {
    console.log(response)
  }
}

function* fetchPublicTransports({ payload }) {
  try {
    const { lat, lng } = payload
    const { data } = yield call(apiAdmin.get, `/public_transport?lat=${lat}&lng=${lng}`)
    yield put({ type: types.FETCHED_PUBLIC_TRANSPORTS, payload: data })
  } catch (error) {
    console.log(error)
  }
}

function* adminFetchBuildingGoogleMapImages({ payload }) {
  let { buildingName } = payload
  const { country, form, action, localImages = [] } = payload

  yield put({ type: types.SET_FETCH, payload: true })

  if (action === 'create') {
    const isInvalidValid = !buildingName || !buildingName.trim() || !country.trim()
    if (isInvalidValid) {
      yield put({
        type: notifTypes.ERROR,
        message: 'Please fill to building name and choose city before load google maps photos!',
      })
      yield put({ type: types.SET_FETCH, payload: false })
      return
    }
  }
  // is edit action
  if (!buildingName || !buildingName.trim()) {
    yield put({
      type: notifTypes.ERROR,
      message: 'Please fill to building name field before load google maps photos!',
    })
    yield put({ type: types.SET_FETCH, payload: false })
    return
  }
  const haveBuildingTextInString = /building/g.test(buildingName)
  buildingName = haveBuildingTextInString ? buildingName : `${buildingName} building`
  const address = `${buildingName}, ${country}`

  try {
    const url = `/building/getGMImages?address=${address}`
    const { data } = yield call(apiAdmin.get, url)
    if (action === 'edit') {
      yield put({ type: types.ADMIN_FETCHED_BUILDING_GM_IMGS, payload: data.images })
    } else {
      let images = [...localImages, ...data.images]
      images = images.map((img, idx) => ({ ...img, order: idx + 1 }))
      yield put(change(form, 'images', images))
    }
    yield put({ type: types.SET_FETCH, payload: false })
  } catch (error) {
    console.log(error)
  }
}

function* getCommercialBuildingAreas() {
  try {
    const url = '/building/commercial-building-area'
    const { data } = yield call(publicApi.get, url)
    yield put({ type: types.FETCHED_COMMERCIAL_BUILDING_AREAS, payload: data })
  } catch (error) {
    console.log(error)
  }
}

function* getCommercialBuildings({ payload }) {
  try {
    const { page, area } = payload
    let url = `/building/commercial-buildings?area=${area}`
    if (page) {
      url += `&page=${page}`
    }
    const { data } = yield call(publicApi.get, url)
    yield put({ type: types.FETCHED_COMMERCIAL_BUILDINGS, payload: data })
  } catch (error) {
    console.log(error)
  }
}

function* getBuildingOfficeTable({ payload }) {
  try {
    const url = `/building/offices-table?building_id=${payload}`
    const { data } = yield call(publicApi.get, url)
    yield put({ type: types.FETCHED_BUILDING_OFFICE_TABLE, payload: data })
  } catch (error) {
    console.log(error)
  }
}

function* getBuildingOfficeVenueCards({ payload: { id, limit = 6, page = 1, cb, isFirstTimeFetch = false } }) {
  try {
    yield delay(300)
    // const url = `/spaces/space-building/${id}?categoryId=4&spaceFormat=venue-card&limit=${limit}&page=${page}`
    const url = `/spaces/space-building/${id}?limit=${limit}&page=${page}`
    const { data } = yield call(publicApi.get, url)
    yield put({ type: types.FETCHED_BUILDING_OFFICE_VENUE_CARD, payload: { ...data, isFirstTimeFetch } })
    cb && cb(false)
  } catch (error) {
    console.log(error)
  }
}

export default function* () {
  yield takeLatest(types.ADMIN_CREATE_BUILDING, createBuilding)
  yield takeLatest(types.ADMIN_DELETE_BUILDING, deleteBuilding)
  yield takeLatest(types.ADMIN_UPDATE_BUILDING, updateBuilding)
  yield takeLatest(types.ADMIN_FETCH_BUILDINGS, adminFetchBuildings)
  yield takeLatest(types.EXPORT_BUILDINGS, exportBuildings)
  yield takeLatest(types.ADMIN_FETCH_BUILDING_USE, adminFetchBuildingUse)
  yield takeLatest(types.ADMIN_FETCH_BUILDING_DETAILS, fetchBuildingDetails)
  yield takeLatest(types.FETCH_BUILDING_DETAILS_REQUEST, handleFetchBuildingDetailsRequest)
  yield takeLatest(types.FETCH_SUGGESTION_BUILDING_REQUEST, handleFetchSuggestionBuildingsRequest)
  yield takeLatest(types.ADMIN_CHANGE_PUBLISH, adminBuildingChangePublish)
  yield takeLatest(types.FETCH_LAND_LORD, fetchLandLord)
  yield takeLatest(types.FETCH_PUBLIC_TRANSPORTS, fetchPublicTransports)
  yield takeLatest(
    types.FETCH_BUILDING_LISTING_VENUE_REQUEST,
    handleFetchBuildingListingVenuesRequest
  )
  yield takeLatest(types.ADMIN_FETCH_BUILDING_GM_IMGS, adminFetchBuildingGoogleMapImages)
  yield takeLatest(types.FETCH_COMMERCIAL_BUILDING_AREAS, getCommercialBuildingAreas)
  yield takeLatest(types.FETCH_COMMERCIAL_BUILDINGS, getCommercialBuildings)
  yield takeLatest(types.FETCH_BUILDING_OFFICE_TABLE, getBuildingOfficeTable)
  yield takeLatest(types.FETCH_BUILDING_OFFICE_VENUE_CARD, getBuildingOfficeVenueCards)
  yield takeLatest(types.ADMIN_FETCH_BUILDING_AREA, adminFetchBuildingArea)
}

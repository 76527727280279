import * as types from 'actions/traditionalOffice/types'
import { handleActions } from 'redux-actions'

const initialState = {
  spaceInfo: {},
  discounted: false,
  isPassport: null,
  link: null,
  leadsAndBookings: {},
  selectedCategories: [],
  headers: [
    { name: 'HEADING', field: 'heading', sortable: true },
    { name: 'VENUE', field: 'venue', sortable: true },
    { name: 'PLACE', field: 'place', sortable: false },
    { name: 'BUILDING NAME', field: 'building_name', sortable: true },
    { name: 'UNIT SIZE', field: 'floor_size', sortable: true },
    { name: 'ASKING RENT', field: 'asking_rent', sortable: true },
    { name: 'UNIT NO', field: 'unit_number', sortable: true },
    { name: 'SHOW ON SRP', field: 'is_show_on_srp', sortable: true },
    { name: 'BUILDING FORMAT', field: 'building_format', sortable: true },
    { name: 'PUBLISHED', field: 'published', sortable: true },
    { name: 'LAST UPDATE', field: 'last_update', sortable: true },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  featuredPassportList: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
  spaceVenueList: [],
  spaceDetails: {},
  initCreate: {},
}

const traditionalOfficeReducer = handleActions(
  {
    [types.FETCH_TRADITIONAL_OFFICE_LIST](state) {
      return {
        ...state,
        fetching: true,
        list: [],
      }
    },
    [types.FETCHED_TRADITIONAL_OFFICE_LIST](state, { response }) {
      const { data, page, total, lastPage } = response
      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_TRADITIONAL_OFFICE](state) {
      return {
        ...state,
        fetching: true,
        spaceInfo: {},
      }
    },
    [types.FETCHED_TRADITIONAL_OFFICE](state, { spaceInfo }) {
      return {
        ...state,
        spaceInfo,
        fetching: false,
      }
    },
    [types.FETCHED_TRADITIONAL_OFFICE_LIST_VENUE](state, { spaces }) {
      return {
        ...state,
        spaceVenueList: spaces,
        spaceDetails: {},
      }
    },
    [types.FETCH_TRADITIONAL_OFFICE_DETAILS](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.FETCHED_TRADITIONAL_OFFICE_DETAILS](state, { space }) {
      return {
        ...state,
        fetching: false,
        spaceDetails: {
          ...space,
        },
      }
    },
    [types.EXPORT_TRADITIONAL_OFFICE_LIST](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EXPORTED_TRADITIONAL_OFFICE_LIST](state) {
      return {
        ...state,
        fetching: false,
      }
    },
  },
  initialState
)

export default traditionalOfficeReducer

import React from 'react'
import PropTypes from 'prop-types'
import BodyClass from 'components/shared/body-class'

const Modal = ({
  className = 'bg-white w-full rounded shadow-lg z-50',
  titleClassName = 'text-base lg:text-xl text-center lato-bold',
  contentClassName = 'text-left px-6 pb-8',
  children,
  onClose,
  title,
  headerClass = '',
  header = '',
  headerClose = true,
  style = {}
}) => (
  <div className="modal-container" style={style}>
    <BodyClass className="modal-open" />
    <div className={`modal-content-con ${className}`}>
      {onClose && <div className={`w-full flex justify-end ${headerClass}`}>
        {header && <div className="w-full px-5 py-3 header-title">
          <span>{header}</span>
        </div>}
        {headerClose && <div className="modal-close cursor-pointer z-50 p-3" onClick={onClose}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>}
      </div>}
      <div className={`modal-content ${contentClassName}`}>
        <p className={titleClassName}>{title}</p>
        {children}
      </div>
    </div>
  </div>
)

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  headerClass: PropTypes.string,
  header: PropTypes.string,
  headerClose: PropTypes.bool,
  style: PropTypes.object,
}

export default Modal

import * as types from 'actions/spaces/types'
import { handleActions } from 'redux-actions'

const initialState = {
  spaceInfo: {},
  discounted: false,
  isPassport: null,
  link: null,
  leadsAndBookings: {},
  selectedCategories: [],
  headers: [
    { name: 'HEADING', field: 'heading', sortable: true },
    { name: 'MERCHANTS', field: 'merchants', sortable: true },
    { name: 'PLACE', field: 'place', sortable: true },
    { name: 'GUESTS', field: 'guests', sortable: false },
    { name: 'RATE', field: 'rate', sortable: true },
    { name: 'CATEGORIES', field: 'categories', sortable: true },
    { name: 'MAP', field: 'map', sortable: true },
    { name: 'VENUE', field: 'venue', sortable: true },
    { name: 'PUBLISHED', field: 'published', sortable: true },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  featuredPassportList: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
  spaceVenueList: [],
  spaceDetails: {},
  initCreate: {},
}

const spaceReducer = handleActions(
  {
    [types.FETCH_SPACES](state) {
      return {
        ...state,
        fetching: true,
        list: [],
      }
    },
    [types.ADMIN_CREATE_SPACE_FAILED](state, { errors }) {
      console.log('erorrs', state)
      return {
        ...state,
        errors,
      }
    },
    [types.FETCHED_SPACES](state, { response }) {
      const { data, page, total, lastPage } = response
      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_SPACE](state) {
      return {
        ...state,
        fetching: true,
        spaceInfo: {},
      }
    },
    [types.FETCH_SPACE_TYPE](state) {
      return {
        ...state,
        fetching: true,
        isPassport: null,
        link: null,
      }
    },
    [types.DISCOUNT_TOGGLE](state, { payload }) {
      return {
        ...state,
        discounted: payload,
      }
    },
    [types.FETCHED_SPACE](state, { spaceInfo }) {
      return {
        ...state,
        spaceInfo,
        fetching: false,
      }
    },
    [types.FETCHED_SPACE_TYPE](state, { isPassport, link }) {
      return {
        ...state,
        fetching: false,
        isPassport,
        link,
      }
    },
    [types.SPACE_LINK_CREATED](state, { url }) {
      return {
        ...state,
        spaceInfo: {
          ...state.spaceInfo,
          url,
        },
      }
    },
    [types.SPACE_UPDATED](state, { spaceInfo }) {
      return {
        ...state,
        spaceInfo,
      }
    },
    [types.FETCHED_SPACE_LEADS_BOOKINGS](state, { leadsAndBookings }) {
      return {
        ...state,
        leadsAndBookings,
      }
    },
    [types.FETCH_FEATURED_PASSPORT_SPACES](state) {
      return {
        ...state,
        fetching: true,
        featuredPassportList: [],
      }
    },
    [types.FETCHED_FEATURED_PASSPORT_SPACES](state, { spaces }) {
      return {
        ...state,
        fetching: false,
        featuredPassportList: spaces,
      }
    },
    [types.FETCHED_SPACES_VENUE](state, { spaces }) {
      return {
        ...state,
        spaceVenueList: spaces,
        spaceDetails: {},
      }
    },
    [types.CLEAR_SPACE_DETAILS](state) {
      return {
        ...state,
        spaceDetails: {},
      }
    },
    [types.NEW_VENUE_SPACE](state, { space }) {
      return {
        ...state,
        spaceVenueList: [...state.spaceVenueList, space],
        fetching: false,
      }
    },
    [types.CREATE_NEW_SPACE](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDIT_SPACE](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_SPACE](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDITED_SPACE_PUBLISH](state, { data }) {
      const list = state.spaceVenueList
      const space = list.find((item) => item.id === data.id)
      space.published = data.publish
      return {
        ...state,
        spaceVenueList: [...list],
      }
    },
    [types.FETCH_SPACE_DETAILS](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.FETCHED_SPACE_DETAILS](state, { space }) {
      return {
        ...state,
        fetching: false,
        spaceDetails: {
          ...space,
        },
      }
    },
    [types.SET_INIT_CREATE](state, { payload }) {
      return {
        ...state,
        initCreate: {
          ...payload,
        },
      }
    },
    [types.SPACE_DISABLE_LOADING](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EXPORT_SPACES](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EXPORTED_SPACES](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.FETCHED_SPACE_FEATURED](state, { list, page }) {
      return {
        ...state,
        [page]: {
          list,
        },
      }
    },
  },
  initialState
)

export default spaceReducer

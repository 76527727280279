import api from 'constants/api'
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects'
import * as types from 'actions/categories/types'
import * as notifTypes from 'actions/notifications/types'

function* getCategories({ payload }) {
  try {
    const statePage = yield select(state => state.category.page)
    const stateLimit = yield select(state => state.category.limit)
    const { limit = stateLimit, page = statePage } = payload

    const { data } = yield call(api.get, '/categories', {
      params: {
        limit,
        page,
      },
    })

    yield delay(300) // to simulate tableloader
    yield put({ type: types.FETCHED_CATEGORIES, response: data.categories })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* getCategory({ payload }) {
  try {
    const { data } = yield call(api.get, `/categories/${payload}`)
    console.log('getCategory', data)
    yield put({ type: types.FETCHED_CATEGORY, category: data.category })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* getCategoryOptions({ payload }) {
  try {
    const { data } = yield call(api.get, '/category/options', {
      params: {
        type: payload,
      },
    })

    yield put({ type: types.FETCHED_CATEGORY_OPTIONS, options: data })
  } catch (error) {
    console.log(error)
  }
}

function* createCategory({ payload }) {
  try {
    const { data } = yield call(api.post, '/categories', {
      ...payload,
      is_published: payload.published,
    })

    console.log('createCategory', data)
    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: types.CREATED_CATEGORY })])
  } catch ({ response }) {
    if (response) console.log(response.message)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* editCategory({ payload }) {
  try {
    const { data } = yield call(api.put, `/categories/${payload.categoryId}`, {
      ...payload,
      is_published: payload.published,
    })

    console.log('editCategory', data)
    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: types.EDITED_CATEGORY })])
  } catch ({ response }) {
    if (response) console.log(response.message)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* deleteCategory({ payload }) {
  try {
    yield call(api.delete, `categories/${payload}`)
    yield put({ type: notifTypes.SUCCESS, message: `Delete Success` })
  } catch (err) {
    yield put({ type: notifTypes.ERROR, message: err })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_CATEGORIES, getCategories)
  yield takeLatest(types.FETCH_CATEGORY, getCategory)
  yield takeLatest(types.CREATE_CATEGORY, createCategory)
  yield takeLatest(types.EDIT_CATEGORY, editCategory)
  yield takeLatest(types.FETCH_CATEGORY_OPTIONS, getCategoryOptions)
  yield takeLatest(types.DELETE_CATEGORY, deleteCategory)
}

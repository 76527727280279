export const AUTHENTICATE = 'authenticate';
export const AUTHENTICATED = 'authenticated';
export const AUTHENTICATION_FAILED = 'authenticationFailed';
export const LOGGING_OUT = 'loggingOut';
export const LOGGED_OUT = 'loggedOut';
export const CREDENTIALS_STORED = 'credentialsStored';
export const PROFILE_STORED = 'profileStored';
export const RESET_PASSWORD = 'resetPassword';
export const VALIDATE_TOKEN = 'validateToken';
export const UPDATE_PASSWORD = 'updatePassword';
export const UPDATE_PROFILE = 'updateProfile';
export const PROFILE_UPDATED = 'profileUpdated';
export const CHANGE_PASSWORD = 'changePassword';
export const CHANGED_PASSWORD = 'changedPassword';

export const MODAL_AUTHENTICATE = 'modalAuthenticate'
export const MODAL_AUTHENTICATED = 'modalAuthenticated'
export const MODAL_REGISTER = 'modalRegister'
export const MODAL_REGISTERED = 'modalRegistered'

export const FETCH_USER_FAVORITES = 'fetchUserFavorites'
export const FETCHED_USER_FAVORITES = 'fetchedUserFavorites'
export const SELECT_AS_FAVORITE = 'selectAsFavorite'
export const SELECTED_AS_FAVORITE = 'selectedAsFavorite'
export const ADD_FAVORITE = 'addFavorites'
export const REMOVE_FAVORITE = 'removeFavorite'
export const UPDATE_FORMPATH = 'updateFormpath'

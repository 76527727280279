/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modalhandler from 'components/shared/modal/modal-handler'
import ClientAuthModal from 'components/shared/modals/client-auth'
import HeartIcon from 'components/shared/icons/heart'
import HeartOutlineIcon from 'components/shared/icons/heart-outline'
import authActions from 'actions/auth/actions'
import notifactionActions from 'actions/notifications/actions'
import FavoriteHeartTextHandler from 'components/shared/favorites/heart-text'

const FavoriteHeartHandler = ({
  id,
  userRole,
  favoriteList,
  selectAsFavorite,
  notify,
  className = 'rounded-full m-3 h-8 w-8 flex absolute items-center justify-center bg-secondary-graywhite cursor-pointer right-0',
}) => {
  const [isFave, setFave] = useState(false)
  useEffect(() => {
    const isFavorite = favoriteList.some((fave) => fave.id === id)
    setFave(isFavorite)
  }, [id])

  const handleOnClick = () => {
    selectAsFavorite({ id, isFave: !isFave })
    setFave(!isFave)
  }
  // allow only the client
  const renderAuthenticated = () => (
    <>
      {userRole[0].name === 'client' ? (
        <span className="authenticated" onClick={handleOnClick}>
          {isFave ? <HeartIcon /> : <HeartOutlineIcon />}
        </span>
      ) : (
        <span className="select-none">
          <FavoriteHeartTextHandler id={id} />
        </span>
      )}
    </>
  )

  return (
    <span className={`${className} z-30`}>
      {userRole ? (
        renderAuthenticated()
      ) : (
        <Modalhandler title={<HeartOutlineIcon />}>
          {({ toggle }) => <ClientAuthModal toggle={toggle} initial={'sign-in'} />}
        </Modalhandler>
      )}
    </span>
  )
}

FavoriteHeartHandler.propTypes = {
  id: PropTypes.number,
  selectAsFavorite: PropTypes.func.isRequired,
  className: PropTypes.string,
  favoriteList: PropTypes.array,
  userRole: PropTypes.array,
  notify: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  userRole: auth.user ? auth.user.roles : null,
  favoriteList: auth.user ? auth.user.favorites : [],
})

const mapDispatchToProps = {
  selectAsFavorite: authActions.selectAsFavorite,
  notify: notifactionActions.notify,
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteHeartHandler)

import api from 'constants/api'
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects'
import * as types from 'actions/places/types'
import * as notifyTypes from 'actions/notifications/types'
import currencies from 'currencies.json'
import formSelector from './selectors/form'

function* getPlaces({ payload }) {
  try {
    const statePage = yield select(state => state.places.page)
    const stateLimit = yield select(state => state.places.limit)
    const { limit = stateLimit, page = statePage } = payload

    const selector = yield formSelector('admin-places-filter-form')
    const keyword = yield select(state => selector(state, 'admin_search_filter'))
    const { data } = yield call(api.get, '/places', {
      params: {
        limit,
        page,
        keyword,
      },
    })

    yield delay(300) // to simulate tableloader
    console.log('getPlaces', data)
    yield put({ type: types.FETCHED_PLACES, response: data.places })
  } catch (error) {
    console.log(error)
  }
}

function* deletePlace({ payload }) {
  try {
    yield call(api.delete, `/places/${payload}`)
    yield put({
      type: notifyTypes.SUCCESS,
      message: `Delete success`
    })
    yield call(getPlaces, { payload: {} })
  } catch (err) {
    yield put({
      type: notifyTypes.ERROR,
      message: `${err?.message}`
    })
  }
}

function* getPlaceOptions({ payload }) {
  try {
    const { data } = yield call(api.get, '/place/options', {
      params: {
        ...payload,
      },
    })

    const options = data.map(place => {
      return {
        label: place.name,
        value: place.slug,
        id: place.id
      }
    })
    yield put({ type: types.FETCHED_PLACE_OPTIONS, options })
  } catch (error) {
    console.log(error)
  }
}

function* getPlace({ payload }) {
  try {
    const { data } = yield call(api.get, `/places/${payload}`)
    yield put({ type: types.FETCHED_PLACE, place: data.place })
  } catch (error) {
    console.log(error)
  }
}

function* createPlace({ payload }) {
  try {
    let { currency } = payload
    currency = currencies.find(c => c.value === currency)
    const { data } = yield call(api.post, `/places`, { ...payload, currency })

    yield all([
      put({ type: notifyTypes.SUCCESS, ...data }),
      put({ type: types.CREATED_PLACE }),
      put({ type: types.FETCH_PLACE_OPTIONS })
    ])
  } catch ({ response }) {
    if (response) console.warn(response.data)
    yield put({ type: notifyTypes.ERROR, ...response.data })
  }
}

function* editPlace({ payload }) {
  try {
    const { currency, placeId } = payload
    const selectedCurrency = currencies.find(c => c.value === currency)
    const { data } = yield call(api.put, `/places/${placeId}`, { ...payload, currency: selectedCurrency })

    yield all([
      put({ type: notifyTypes.SUCCESS, ...data }),
      put({ type: types.EDITED_PLACE }),
      put({ type: types.FETCH_PLACE_OPTIONS })
    ])
  } catch ({ response }) {
    if (response) console.warn(response.data)
    yield put({ type: notifyTypes.ERROR, ...response.data })
  }
}

function* getPlaceSpaceCount({ payload: { city } }) {
  try {
    const { data } = yield call(api.get, `/place/space-count/${city}`)

    console.log('getPlaceSpaceCount', data)
    yield put({ type: types.FETCHED_PLACE_SPACE_COUNT, count: data.count })
  } catch (error) {
    console.warn(error)
  }
}

export default function* () {
  yield takeLatest(types.FETCH_PLACES, getPlaces)
  yield takeLatest(types.FETCH_PLACE, getPlace)
  yield takeLatest(types.CREATE_PLACE, createPlace)
  yield takeLatest(types.EDIT_PLACE, editPlace)
  yield takeLatest(types.FETCH_PLACE_OPTIONS, getPlaceOptions)
  yield takeLatest(types.FETCH_PLACE_SPACE_COUNT, getPlaceSpaceCount)
  yield takeLatest(types.DELETE_PLACE, deletePlace)
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

const Contact = ({
  input,
  meta,
  disabled,
  placeHolder,
  label,
  labelClassName,
  groupClassName,
  inputClassName,
  errorClassName,
  inputErrorClassName,
  inputGroupClassName,
}) => {
  const [value, setValue] = useState(input.value)

  const inputClasses = `form-control ${
    meta.touched && meta.error ? [inputClassName, inputErrorClassName].join(' ') : inputClassName
  }`

  const inputGroupClasses = `form-control ${
    meta.touched && meta.error
      ? [inputGroupClassName, inputErrorClassName].join(' ')
      : inputGroupClassName
  }`

  const formatPhoneNumber = (...args) => {
    setValue(args[3])
    return args[3]
  };
  return (
    <div className={`form-group ${groupClassName}`}>
      <label className={labelClassName}>{label}</label>
      <div className={`${inputGroupClasses}`}>
        <IntlTelInput
          defaultValue={value}
          formatOnInit
          preferredCountries={['sg', 'ph', 'hk', 'my', 'id', 'mm']}
          containerClassName={`intl-tel-input w-full`}
          inputClassName={inputClasses}
          separateDialCode
          fieldName={input.name}
          fieldId={`#contact-${input.name}`}
          onPhoneNumberChange={(...args) => {
            input.onChange(formatPhoneNumber(...args));
          }}
          onSelectFlag={(...args) => {
            setValue(args[2])
            input.onChange(args[2])
          }}
          disabled={disabled}
          customPlaceholder={placeHolder}
          useMobileFullscreenDropdown={false}
        />
      </div>
      {meta.touched && meta.error && <p className={`form-error ${errorClassName}`}>{meta.error}</p>}
    </div>
  )
}

Contact.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  groupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  inputErrorClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
}

export default Contact

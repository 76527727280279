import * as types from 'actions/uploader/types';
import { handleActions } from 'redux-actions';

const initialState = {
  images: [],
  processing: false,
};

const uploadReducer = handleActions(
  {
    [types.UPLOADED_IMAGES](state, { images }) {
      return {
        ...state,
        images,
      };
    },
    [types.UPLOAD_BASIC](state) {
      return {
        ...state,
        processing: true,
      };
    },
    [types.UPLOADED_BASIC](state) {
      return {
        ...state,
        processing: false,
      };
    },
  },
  initialState,
);

export default uploadReducer;

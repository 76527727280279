import * as types from 'actions/areas/types'
import { handleActions } from 'redux-actions'

const initialState = {
  headers: [
    { name: 'AREA NAME', field: 'area_name' },
    { name: 'PLACE', field: 'place' },
    { name: 'STATUS', field: 'is_published' },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  listPerCity: [],
  options: [],
  areaQuickSearch: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
}

const areasReducer = handleActions(
  {
    [types.FETCH_AREAS](state) {
      return {
        ...state,
        list: [],
        fetching: true,
      }
    },
    [types.FETCHED_AREAS](state, { response }) {
      const { data, page, total, lastPage } = response;

      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_AREA](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_AREA](state, { area }) {
      return {
        ...state,
        details: area,
        fetching: false,
      }
    },
    [types.FETCH_AREAS_PER_CITY](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.FETCHED_AREAS_PER_CITY](state, { areas }) {
      return {
        ...state,
        listPerCity: areas,
        fetching: false,
      }
    },
    [types.RESET_AREAS_PER_CITY](state) {
      return {
        ...state,
        fetching: true,
        listPerCity: []
      }
    },
    [types.CREATE_AREA](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_AREA](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_AREA](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_AREA](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.FETCH_AREA_OPTIONS](state) {
      return {
        ...state,
        options: [],
        fetching: true,
      }
    },
    [types.FETCHED_AREA_OPTIONS](state, { options }) {
      return {
        ...state,
        options,
        fetching: false,
      }
    },
    [types.QUICK_SEARCH_AREA](state) {
      return {
        ...state,
        areaQuickSearch: [],
        fetching: true,
      }
    },
    [types.QUICK_SEARCHED_AREA](state, { areaQuickSearch }) {
      return {
        ...state,
        areaQuickSearch,
        fetching: false,
      }
    },
  },
  initialState,
)

export default areasReducer

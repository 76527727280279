export const USER_GET_LEADS = 'userGetLeads'
export const USER_GET_BOOKINGS = 'userGetBookings'
export const ASSIGN_CONVERSATION = 'assignConversation'

export const CREATE_USER = 'createUser'
export const CREATED_USER = 'createdUser'
export const EDIT_USER = 'editUser'
export const EDITED_USER = 'editedUser'
export const DELETE_USER = 'deleteUser'
export const DELETE_VENUE = 'deleteVenue'

export const FETCH_USER_VENUES = 'fetchUserVenues'
export const FETCHED_USER_VENUES = 'fetchedUserVenues'
export const SET_USER_VENUES_IS_FETCHING = 'setUserVenuesIsFetching'

export const FETCH_USERS = 'fetchUsers'
export const FETCHED_USERS = 'fetchedUsers'
export const FETCH_USER = 'fetchUser'
export const FETCHED_USER = 'fetchedUser'

export const FETCH_USER_PASSPORT_SUBSCRIPTION = 'fetchUserPassportSubscription'
export const FETCHED_USER_PASSPORT_SUBSCRIPTION = 'fetchedUserPassportSubscription'
export const CANCEL_USER_PASSPORT_SUBSCRIPTION = 'cancelUserPassportSubscription'
export const CANCELLED_USER_PASSPORT_SUBSCRIPTION = 'cancelledUserPassportSubscription'

export const SORT_USER_LIST = 'sortUserList'
export const SORTED_USER_LIST = 'sortedUserList'

export const DETAILS_FETCH_VENUE = 'detailsFetchVenue'
export const DETAILS_FETCHED_VENUE = 'detailsFetchedVenue'
export const DETAILS_FETCH_SPACES = 'detailsFetchSpaces'
export const DETAILS_FETCHED_SPACES = 'detailsFetchedSpaces'
export const DETAILS_FETCH_LEAD = 'detailsFetchLead'
export const DETAILS_FETCHED_LEAD = 'detailsFetchedLead'
export const DETAILS_FETCH_BOOKING = 'detailsFetchBooking'
export const DETAILS_FETCHED_BOOKING = 'detailsFetchedBooking'

export const USER_RESET_DETAILS = 'userResetDetails'
// ADMIN
export const ADMIN_SEARCH_USER = 'adminSearchUser'
export const LOGIN_AS_USER = 'loginAsUser'
export const SWITCH_TO_ADMIN = 'switchToAdmin'

export const EXPORT_USERS = 'exportUsers'
export const EXPORTED_USERS = 'exportedUsers'

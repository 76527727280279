import api, { apiConfig } from 'constants/api'
import moment from 'moment'
import FileDownload from 'js-file-download'

import { all, select, takeLatest, put, call, delay } from 'redux-saga/effects'
import * as types from 'actions/traditionalOffice/types'
import * as notifTypes from 'actions/notifications/types'
import formSelector from './selectors/form'

const apiAdmin = apiConfig({ baseURL: '/api/admin' })

const apiMerchant = apiConfig({ baseURL: '/api/merchant' })

const compare = (key) => (a, b) => {
  if (a[key] < b[key]) return -1
  if (a[key] > b[key]) return 1
  return 0
}

function* getTraditionalOffice({ payload }) {
  try {
    const { data } = yield call(api.get, `/spaces/${payload}/detail`)
    const { spaceInfo } = data
    spaceInfo.reviews = spaceInfo.reviews.sort((a, b) => b.id - a.id)
    const { reviews } = spaceInfo
    const reviewsFormat = reviews.map((review) => {
      return {
        ...review,
        date: moment(review.date).format('MMMM YYYY'),
      }
    })
    yield all([
      // other functions later
      put({
        type: types.FETCHED_TRADITIONAL_OFFICE,
        spaceInfo: { ...spaceInfo, reviews: reviewsFormat },
      }),
    ])
  } catch ({ response }) {
    const { message } = response.data
    console.warn(message)
    // return empty space info
    yield put({ type: types.FETCHED_TRADITIONAL_OFFICE, spaceInfo: null })
  }
}

function* fetchTraditionalOfficeListVenue({ payload }) {
  try {
    const { data } = yield call(api.get, `/spaces/options/${payload}`)
    const spaces = data.spaces
    spaces.map((space) => {
      space.link = '/'
      return space
    })
    yield put({ type: types.FETCHED_TRADITIONAL_OFFICE_LIST_VENUE, spaces: data.spaces })
  } catch ({ response }) {
    console.log(response)
  }
}

function* copyTraditionalOffice({ payload }) {
  const id = payload
  try {
    yield call(apiAdmin.get, `/spaces/${id}/clone`)
    yield put({ type: types.FETCH_TRADITIONAL_OFFICE_LIST, payload: {} })
  } catch (error) {
    console.log(error)
  }
}

function* getTraditionalOfficeDetails({ payload }) {
  try {
    const { data } = yield call(apiMerchant.get, `/spaces/${payload}`)
    yield put({ type: types.FETCHED_TRADITIONAL_OFFICE_DETAILS, space: data })
  } catch ({ response }) {
    const { message } = response.data
    console.warn(message)
    // return empty space info
    yield put({ type: types.FETCHED_TRADITIONAL_OFFICE, spaceInfo: null })
  }
}

// ADMIN
function* getTraditionalOfficeList({ payload }) {
  try {
    const selector = yield formSelector('admin-traditional-office-filter-form')
    const buildingGrade = yield select((state) => selector(state, 'building_filter'))
    const buildingGradeFilter =
      buildingGrade?.[0].value !== '*' ? buildingGrade?.map((building) => building.value) : []
    const places = yield select((state) => selector(state, 'place_filter'))
    const placesFilter = places ? places.map((place) => place.value) : []
    const floorSizeMax = yield select((state) => selector(state, 'floor_size_max'))
    const floorSizeMin = yield select((state) => selector(state, 'floor_size_min'))
    const floorSizeStart = floorSizeMin || undefined
    const floorSizeEnd = floorSizeMax || undefined
    const condition = yield select((state) => selector(state, 'condition_filter'))
    const conditionFilter = condition && condition !== '*' ? condition : undefined
    const status = yield select((state) => selector(state, 'status_filter'))
    const statusFilter = status && status !== '*' ? status : undefined
    const areas = yield select((state) => selector(state, 'area_filter'))
    const areaFilter = areas ? areas.map((area) => area.value) : []
    const { limit, page, searchKeyword } = payload

    const { data } = yield call(apiAdmin.get, '/spaces?categoriesFilter[]=traditional-office', {
      params: {
        limit,
        page,
        floorSizeStart,
        floorSizeEnd,
        statusFilter,
        conditionFilter,
        search_bar: searchKeyword ?? '',
        placesFilter,
        buildingGradeFilter,
        areaFilter,
      },
    })

    yield delay(300) // to simulate tableloader
    yield put({ type: types.FETCHED_TRADITIONAL_OFFICE_LIST, response: data.spaces })
  } catch (error) {
    console.log(error)
  }
}

function* adminGetTraditionalOfficeDetails({ payload }) {
  try {
    const { data } = yield call(apiAdmin.get, `/spaces/${payload}`)
    const { space } = data
    space.images = data.space.images.sort(compare('order'))
    space.spaceFunctions = data.space.tags.map((t) => ({ value: t.id, label: t.name }))
    space.merchant_id = space.venue.user.id
    space.merchant_name = space.venue.user.name
    yield put({ type: types.FETCHED_TRADITIONAL_OFFICE_DETAILS, space })
  } catch ({ message }) {
    console.error(message)
  }
}

function* deleteTraditionalOffice({ payload }) {
  try {
    yield call(apiAdmin.delete, `/spaces/${payload.space_id}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Success delete space.' })
    yield call(fetchTraditionalOfficeListVenue, { payload: payload.venue_id })
  } catch ({ message }) {
    yield put({ type: notifTypes.ERROR, message: 'Error delete space.' })
  }
}

function* adminDeleteTraditionalOffice({ payload }) {
  const { limit, page, deleteId } = payload
  try {
    yield call(apiAdmin.delete, `/spaces/${deleteId}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Success delete space.' })
    yield call(getTraditionalOfficeList, { payload: { limit, page } })
  } catch ({ message }) {
    yield put({ type: notifTypes.ERROR, message: 'Error delete space.' })
  }
}

function* exportTraditionalOfficeList({ payload }) {
  try {
    const selector = yield formSelector('admin-traditional-office-filter-form')
    const available = yield select((state) => selector(state, 'available_filter'))
    const availableFilter = available && available !== '*' ? available : undefined
    const areasqft = yield select((state) => selector(state, 'areasqft_filter'))
    const areasqftFilter = areasqft && areasqft !== '*' ? areasqft : undefined
    const places = yield select((state) => selector(state, 'place_filter'))
    const placesFilter = places ? places.map((place) => place.value) : []
    const buildingGrade = yield select((state) => selector(state, 'building_filter'))
    const buildingGradeFilter =
      buildingGrade?.[0].value !== '*' ? buildingGrade?.map((building) => building.value) : []
    const condition = yield select((state) => selector(state, 'condition_filter'))
    const conditionFilter = condition && condition !== '*' ? condition : undefined
    const floorSizeMax = yield select((state) => selector(state, 'floor_size_max'))
    const floorSizeMin = yield select((state) => selector(state, 'floor_size_min'))
    const floorSizeStart = floorSizeMin || undefined
    const floorSizeEnd = floorSizeMax || undefined
    const status = yield select((state) => selector(state, 'status_filter'))
    const statusFilter = status && status !== '*' ? status : undefined
    const searchBar = yield select((state) => selector(state, 'search_bar'))
    const areas = yield select((state) => selector(state, 'area_filter'))
    const areaFilter = areas ? areas.map((area) => area.value) : []

    const { selectedFields } = payload

    const response = yield call(
      apiAdmin.get,
      '/export-traditional-spaces?categoriesFilter[]=traditional-office',
      {
        responseType: 'arraybuffer',
        params: {
          availableFilter,
          areasqftFilter,
          placesFilter,
          buildingGradeFilter,
          statusFilter,
          conditionFilter,
          floorSizeStart,
          floorSizeEnd,
          areaFilter,
          search_bar: searchBar ?? '',
          selectedFields,
        },
      }
    )
    FileDownload(response.data, 'traditionalOffice.xlsx')
    yield put({ type: types.EXPORTED_TRADITIONAL_OFFICE_LIST })
    yield put({ type: notifTypes.SUCCESS, message: 'Export Space Successfully' })
  } catch ({ response }) {
    yield put({ type: types.EXPORTED_TRADITIONAL_OFFICE_LIST })
    yield put({ type: notifTypes.ERROR, message: 'Error Export Spaces' })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_TRADITIONAL_OFFICE_LIST, getTraditionalOfficeList)
  yield takeLatest(types.ADMIN_FETCH_TRADITIONAL_OFFICE_DETAILS, adminGetTraditionalOfficeDetails)
  yield takeLatest(types.FETCH_TRADITIONAL_OFFICE_DETAILS, getTraditionalOfficeDetails)
  yield takeLatest(types.FETCH_TRADITIONAL_OFFICE, getTraditionalOffice)
  yield takeLatest(types.FETCH_TRADITIONAL_OFFICE_LIST_VENUE, fetchTraditionalOfficeListVenue)
  yield takeLatest(types.COPY_TRADITIONAL_OFFICE, copyTraditionalOffice)
  yield takeLatest(types.DELETE_TRADITIONAL_OFFICE, deleteTraditionalOffice)
  yield takeLatest(types.EXPORT_TRADITIONAL_OFFICE_LIST, exportTraditionalOfficeList)
  yield takeLatest(types.ADMIN_DELETE_TRADITIONAL_OFFICE, adminDeleteTraditionalOffice)
}

import { all } from 'redux-saga/effects';

import authSagas from 'sagas/authSagas';
import amenitiesSagas from 'sagas/amenitiesSagas';
import spaceSagas from 'sagas/spaceSagas';
import categoriesSagas from 'sagas/categoriesSagas';
import merchantSagas from 'sagas/merchantSagas';
import uploadSagas from 'sagas/uploadSagas';
import imageSagas from 'sagas/imageSagas';
import messageSagas from 'sagas/messageSagas';
import formSagas from 'sagas/formSagas';
import blogSagas from 'sagas/blogSagas';
import hubspotSagas from 'sagas/hubspotSagas';
import venueSagas from 'sagas/venueSagas';
import seoSagas from 'sagas/seoSagas';
import areaSagas from 'sagas/areaSagas';
import placeSagas from 'sagas/placeSagas';
import userSagas from 'sagas/userSagas';
import landmarkSagas from 'sagas/landmarkSagas'
import countrySagas from 'sagas/countrySagas'
import spaceFunctions from 'sagas/spaceFunctionsSagas'
import promocodeSagas from 'sagas/promocodeSagas'
import locationSagas from 'sagas/locationSagas'
import searchSagas from 'sagas/searchSagas'
import leadSagas from 'sagas/leadSagas'
import clientSagas from 'sagas/clientSagas'
import passportSagas from 'sagas/passportSagas'
import reviewSagas from 'sagas/reviewSagas'
import generalSagas from 'sagas/generalSagas'
import bookingSagas from 'sagas/bookingSagas'
import brandSagas from 'sagas/brandSagas'
import notificationSagas from 'sagas/notificationSagas'
import contactSagas from 'sagas/contactSagas';
import emsSagas from 'sagas/emsSagas'
import citySagas from 'sagas/citySagas'
import buildingSagas from 'sagas/buildingSagas'
import developerSagas from 'sagas/developerSagas';
import traditonalOfficeSagas from 'sagas/traditionalOfficeSagas'


export default function* () {
  yield all([
    messageSagas(),
    authSagas(),
    merchantSagas(),
    spaceSagas(),
    imageSagas(),
    uploadSagas(),
    formSagas(),
    blogSagas(),
    hubspotSagas(),
    locationSagas(),
    seoSagas(),
    searchSagas(),
    venueSagas(),
    areaSagas(),
    placeSagas(),
    userSagas(),
    landmarkSagas(),
    spaceFunctions(),
    countrySagas(),
    categoriesSagas(),
    amenitiesSagas(),
    promocodeSagas(),
    leadSagas(),
    clientSagas(),
    passportSagas(),
    reviewSagas(),
    generalSagas(),
    bookingSagas(),
    notificationSagas(),
    contactSagas(),
    emsSagas(),
    citySagas(),
    brandSagas(),
    buildingSagas(),
    developerSagas(),
    traditonalOfficeSagas()
  ]);
}

export const validator = ({
  email,
  password,
  passwordConfirmation,
  phone,
  place,
  terms,
}) => {
  const errors = {}
  if (!email) errors.email = 'email is required'
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
    errors.email = 'invalid email format'
  if (!password) errors.password = 'password is required'
  if (!place) errors.place = 'location is required'
  if (!phone) errors.phone = 'phone is required'
  if (password !== passwordConfirmation) errors.password = 'passwords do not match'
  if (!terms) errors.terms = 'you must agree to terms and conditions'

  return errors
}

export default validator

import * as types from 'actions/users/types'
import { handleActions } from 'redux-actions'

const initialState = {
  info: {
    id: 1,
    leads: [{ id: 112311123123 }, { id: 21123123123 }],
    bookings: [{ id: 15454312332312 }, { id: 2433423434234 }],
  },
  venues: {
    isFetching: false,
    list: [],
    page: 1,
    lastPage: 0,
    total: 0,
    limit: 5,
  },
  spaces: {
    isFetching: false,
    list: [],
    page: 1,
    lastPage: 0,
    total: 0,
    limit: 5,
  },
  leads: {
    isFetching: false,
    list: [],
    page: 1,
    lastPage: 0,
    total: 0,
    limit: 4,
  },
  bookings: {
    isFetching: false,
    list: [],
    page: 1,
    lastPage: 0,
    total: 0,
    limit: 4,
  },
  headers: [
    { name: 'FIRST NAME', field: 'firstName', sortable: true },
    { name: 'LAST NAME', field: 'lastName', sortable: true },
    { name: 'FULL NAME', field: 'fullName', sortable: true },
    { name: 'EMAIL', field: 'email', sortable: true },
    { name: 'TYPE', field: 'type', sortable: false },
    { name: 'DATE JOINED', field: 'dateJoined', sortable: true },
    { name: 'MOBILE NUMBER', field: 'phone', sortable: false },
    { name: 'CITY', field: 'city', sortable: true },
    { name: '', field: 'actions' },
  ],
  details: {},
  subscription: {},
  list: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
}

const setVenues = ({ venues, isFetching, list, page, lastPage }) => ({
  venues: {
    ...venues,
    isFetching,
    ...(list !== undefined ? { list } : {}),
    ...(page !== undefined ? { page } : {}),
    ...(lastPage !== undefined ? { lastPage } : {}),
  },
})

const userReducer = handleActions(
  {
    [types.FETCH_USERS](state) {
      return {
        ...state,
        fetching: true,
        list: [],
      }
    },
    [types.FETCHED_USERS](state, { response }) {
      const { data, page, total, lastPage } = response
      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_USER](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_USER](state, { user }) {
      return {
        ...state,
        details: user,
        fetching: false,
      }
    },
    [types.USER_GET_LEADS](state, { leads }) {
      return {
        ...state,
        leads,
      }
    },
    [types.USER_GET_BOOKINGS](state, { bookings }) {
      return {
        ...state,
        bookings,
      }
    },
    [types.CREATE_USER](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_USER](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_USER](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_USER](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.FETCH_USER_VENUES](state) {
      return {
        ...state,
        ...setVenues({ venues: state.venues, isFetching: true }),
      }
    },
    [types.FETCHED_USER_VENUES](state, { payload }) {
      return {
        ...state,
        ...setVenues({ venues: state.venues, isFetching: false, ...payload }),
      }
    },
    [types.SET_USER_VENUES_IS_FETCHING](state, { payload: isFetching }) {
      return {
        ...state,
        ...setVenues({ venues: state.venues, isFetching }),
      }
    },
    [types.FETCH_USER_PASSPORT_SUBSCRIPTION](state) {
      return {
        ...state,
        subscription: {},
        fetching: true,
      }
    },
    [types.FETCHED_USER_PASSPORT_SUBSCRIPTION](state, { subscription }) {
      return {
        ...state,
        subscription,
        fetching: false,
      }
    },
    [types.CANCELLED_USER_PASSPORT_SUBSCRIPTION](state) {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          status: 'cancelled',
          end_date: state.subscription.nextBill,
        },
      }
    },
    [types.SORTED_USER_LIST](state, { list }) {
      return {
        ...state,
        list: [...list],
      }
    },
    [types.DETAILS_FETCHED_VENUE](state, { data }) {
      const { isFirstFetch } = data
      const newList =
        state.venues.list.length < data.total
          ? [...state.venues.list, ...data.data]
          : state.venues.list
      const finalList = isFirstFetch ? data.data : newList
      return {
        ...state,
        venues: {
          ...state.venues,
          ...data,
          list: finalList,
        },
      }
    },
    [types.DETAILS_FETCHED_SPACES](state, { data }) {
      const { isFirstFetch } = data
      const newList =
        data.total > state.spaces.list.length
          ? [...state.spaces.list, ...data.data]
          : state.spaces.list
      const finalList = isFirstFetch ? data.data : newList
      return {
        ...state,
        spaces: {
          ...state.spaces,
          ...data,
          list: finalList,
        },
      }
    },
    [types.DETAILS_FETCHED_LEAD](state, { data }) {
      return {
        ...state,
        leads: {
          ...state.leads,
          ...data,
          list: data.data,
        },
      }
    },
    [types.DETAILS_FETCHED_BOOKING](state, { data }) {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          ...data,
          list: data.data,
        },
      }
    },
    [types.USER_RESET_DETAILS](state) {
      return {
        ...state,
        details: {},
      }
    },
    [types.EXPORT_USERS](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EXPORTED_USERS](state) {
      return {
        ...state,
        fetching: false,
      }
    },
  },
  initialState
)

export default userReducer

import * as types from 'actions/cities/types'
import { handleActions } from 'redux-actions'

const initialState = {
  category: {},
  selectedCity: null,
  lastPage: 1,
  page: 1,
  perPage: 20,
  total: 12,
  data: [],
}

const citiesReducer = handleActions(
  {
    [types.FETCH_CITIES](state, { cities }) {
      return {
        ...state,
        list: cities,
      }
    },
    [types.SELECT_CITY](state, { payload }) {
      return {
        ...state,
        selectedCity: payload,
      }
    },
    [types.FETCHED_CITIES](state, { places }) {
      return {
        ...state,
        ...places,
      }
    },
    [types.FETCHED_CITY_MANAGER](state, { city }) {
      const {
        id,
        bank_information,
        contact_name,
        contact_image,
        contact_text,
        country_manager_email,
        name,
        phone,
      } = city
      return {
        ...state,
        details: {
          id,
          bank_information,
          contact_name,
          contact_image,
          contact_text,
          country_manager_email,
          name,
          phone,
        },
      }
    },
  },
  initialState
)

export default citiesReducer

const queryString = params => Object.keys(params).map(key => `${key}= ${params[key]}`).join('&')

export const getQueryString = string => {
  const newString = string.trim().replace(/^\?/, '')
  const splittedAmpersandArr = newString.split('&')
  let queryStringObj = {}

  splittedAmpersandArr.forEach(value => {
    const indexOfEqual = value.indexOf('=')
    const key = value.slice(0, indexOfEqual)
    const val = value.slice(indexOfEqual + 1, value.length)
    const isArr = /[\[\]]{2}$/.test(key)

    if (key && val) {
      if (isArr) {
        const trimmedKey = key.trim().replace(/[\[\]]{2}$/, '') // remove [] at the end

        if (queryStringObj[trimmedKey]) {
          queryStringObj[trimmedKey].push(val)
        } else {
          queryStringObj[trimmedKey] = [val]
        }
      } else {
        queryStringObj = { [key]: val, ...queryStringObj }
      }
    }
  })

  return queryStringObj
}

export const createQueryString = obj => Object.keys(obj).map(key => {
  if (Array.isArray(obj[key])) {
    return obj[key].map(value => `${key}[]=${value}`).join('&')
  }

  return `${key}=${obj[key]}`
}).join('&')

export default queryString

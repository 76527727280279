import React from 'react'
import PropTypes from 'prop-types'

const HeartOutline = ({ color = '#999999' }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Icons-/-Light-Grey-/-Favourite"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
      <path
        d="M11.9756102,19 C11.4406102,19 10.9376102,18.789 10.5586102,18.407 L5.28761015,13.087 C3.75061015,11.535 3.56461015,9.074 4.86461015,7.483 C5.63461015,6.54 6.76461015,6 7.96561015,6 C9.03661015,6 10.0416102,6.421 10.7986102,7.186 L11.9756102,8.372 L13.0006102,7.337 C13.8166102,6.516 14.9376102,6.044 16.0746102,6.044 C16.9936102,6.044 17.8406102,6.346 18.5256102,6.917 C19.4166102,7.66 19.9386102,8.706 19.9936102,9.862 C20.0466102,11.005 19.6196102,12.123 18.8196102,12.93 L13.3926102,18.407 C13.0136102,18.789 12.5106102,19 11.9756102,19"
        id="Fill-1"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

HeartOutline.propTypes = {
  color: PropTypes.string,
}

export default HeartOutline

import React from 'react'
import PropTypes from 'prop-types'

const Building = ({
  color = '#999999',
  className = 'fill-current w-5 h-5 mr-2 absolute left-0 top-0',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="13.588"
    height="15.529"
    viewBox="0 0 13.588 15.529"
  >
    <path
      id="Icon_awesome-building"
      data-name="Icon awesome-building"
      d="M13.224,14.559h-.607V.728A.728.728,0,0,0,11.89,0H1.7A.728.728,0,0,0,.971.728V14.559H.364A.364.364,0,0,0,0,14.923v.607H13.588v-.607A.364.364,0,0,0,13.224,14.559ZM3.882,2.305a.364.364,0,0,1,.364-.364H5.46a.364.364,0,0,1,.364.364V3.518a.364.364,0,0,1-.364.364H4.246a.364.364,0,0,1-.364-.364Zm0,2.912a.364.364,0,0,1,.364-.364H5.46a.364.364,0,0,1,.364.364V6.43a.364.364,0,0,1-.364.364H4.246a.364.364,0,0,1-.364-.364ZM5.46,9.706H4.246a.364.364,0,0,1-.364-.364V8.129a.364.364,0,0,1,.364-.364H5.46a.364.364,0,0,1,.364.364V9.342A.364.364,0,0,1,5.46,9.706Zm2.305,4.853H5.823V12.011a.364.364,0,0,1,.364-.364H7.4a.364.364,0,0,1,.364.364ZM9.706,9.342a.364.364,0,0,1-.364.364H8.129a.364.364,0,0,1-.364-.364V8.129a.364.364,0,0,1,.364-.364H9.342a.364.364,0,0,1,.364.364Zm0-2.912a.364.364,0,0,1-.364.364H8.129a.364.364,0,0,1-.364-.364V5.217a.364.364,0,0,1,.364-.364H9.342a.364.364,0,0,1,.364.364Zm0-2.912a.364.364,0,0,1-.364.364H8.129a.364.364,0,0,1-.364-.364V2.305a.364.364,0,0,1,.364-.364H9.342a.364.364,0,0,1,.364.364Z"
      fill={color}
    />
  </svg>
)

Building.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
}

export default Building

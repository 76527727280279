/* eslint-disable camelcase */
import { select, put, call, takeLatest, all, take, takeEvery } from 'redux-saga/effects';
import { eventChannel as EventChannel } from 'redux-saga';
import messageActions from 'actions/messages/actions';
import * as types from 'actions/messages/types';
import Ws, { connect as initWsConnect } from 'constants/ws';
import { apiConfig } from 'constants/api';

const instance = (role) => {
  return role === 'admin' ? apiConfig({ baseURL: '/api/admin' }) : apiConfig({ baseURL: '/api' })
}

const {
  receiveMessage,
  wsInitialized,
  adminWsInit,
  inquiryFetched,
  messagesSeen,
  typingFetched,
  totalUnreadFetched
} = messageActions;

let userWsChannel = null

// On page load
export function* subscribeConversation(ws, conversationId) {
  return new EventChannel((emit) => {
    const conversation = ws()
      .subscribe(`conversation:${conversationId}`);
    // conversation.on('message', message => emit(receiveMessage(message)));
    conversation.on('inquiryUpdated', res => {
      res.conversationId = conversationId
      emit(inquiryFetched(res))
    })
    return () => {
      conversation.close()
    };
  });
}

export function* initWsConnection() {
  return new EventChannel((emit) => {
    const ws = initWsConnect()
    ws.on('open', () => {
      emit(adminWsInit())
      emit(wsInitialized())
    })
    return () => {
      ws.close()
    };
  });
}

export function* subscribeUserChannel(ws, userId) {
  return new EventChannel((emit) => {
    const newMessageChannel = ws()
      .subscribe(`channel:${userId}`);
    // emit get total unread
    newMessageChannel.emit('totalUnread', {})

    // listen event
    newMessageChannel.on('message', message => {
      console.log(`new message on channel of user ${userId}`, message)
      emit(receiveMessage(message))
    })
    newMessageChannel.on('seen', message => {
      emit(messagesSeen(message))
    })
    newMessageChannel.on('typing', res => {
      emit(typingFetched(res))
    })
    newMessageChannel.on('inquiryUpdated', res => {
      emit(inquiryFetched(res))
    })
    newMessageChannel.on('totalUnread', res => {
      emit(totalUnreadFetched(res))
    })
    return () => {
      newMessageChannel.close()
    }
  })
}

export function* subscribeAdminChannel(ws) {
  return new EventChannel((emit) => {
    const channel = ws()
      .subscribe('admin');
    channel.on('message', message => {
      console.log('new message for admin')
      emit(receiveMessage(message))
    })
    return () => {
      channel.close()
    }
  })
}


function* sendMessage(role, message) {
  try {
    const { data } = yield call(instance(role).post, '/conversations/send-message', message)
    return data
  } catch (err) {
    console.log(err);
  }
}

function* setMessage({ payload }) {
  try {
    yield call(initWsConnection)
    console.log(payload)
    const authUserId = yield select(state => state.auth.user.id)
    const conversationId = payload.conversationId
    const existingConversation = yield select(state => {
      return state.messages.conversation.list.find(conversation => conversation.id === Number(conversationId));
    });
    const receiver = existingConversation.members.find(member => member.id !== authUserId);
    const role = yield select(state => state.auth.user.roles[0])
    const effects = []
    if (payload.attachment && payload.attachment.length) {
      effects.push(call(sendMessage, role, {
        conversation_id: conversationId,
        content: payload.attachment,
        content_type: 'attachment',
        recipient_id: receiver.id
      }))
    }
    if (payload.message) {
      effects.push(call(sendMessage, role, {
        conversation_id: conversationId,
        content: payload.message,
        content_type: 'text',
        recipient_id: receiver.id
      }))
    }
    const results = yield all(effects)
    const messageIndex = yield select(state => state.messages.index)
    const newMessages = []
    results.forEach((data) => {
      const msg = {
        id: data.id,
        body: data.body,
        conversation_id: data.conversation_id,
        sender_id: data.sender_id,
        recipient_id: data.recipient_id,
        type: data.type || 'message',
        updated_at: data.updated_at
      }
      newMessages.push(msg)
    })
    messageIndex.messages = [...messageIndex.messages, ...newMessages]
    yield put({ type: types.MESSAGES_SENT, index: messageIndex })
  } catch (error) {
    console.log(error)
  }
}

function* fetchConversations({ payload = {} }) {
  try {
    const role = yield select(state => state.auth.user.roles[0])
    const { data } = yield call(instance(role).get, `/conversations`, {
      params: payload
    })
    yield put({ type: types.CONVERSATIONS_FETCHED, ...data });
  } catch (error) {
    console.log(error)
  }
}

function* fetchMessages({ payload }) {
  try {
    const role = yield select(state => state.auth.user.roles[0])
    const { data } = yield call(instance(role).get, `/conversations/${payload}`)
    yield put({ type: types.MESSAGES_FETCHED, index: data })
  } catch (error) {
    console.log(error)
  }
}

function* seenMessages() {
  yield call(initWsConnection)
  const userId = yield select(state => state.auth.user.id)
  userWsChannel = Ws()
    .getSubscription(`channel:${userId}`)
  const activeConversation = yield select(state => state.messages.index)
  if (!activeConversation.is_read && userWsChannel) {
    userWsChannel.emit('seen', { conversation_id: activeConversation.id })
  }
}

function* useChannel(ws) {
  yield call(initWsConnection)
  const topic = yield select(state => state.auth && state.auth.user && state.auth.user.id)
  const subscription = yield topic && call(subscribeUserChannel, ws, topic);
  while (true) {
    const action = yield topic && take(subscription);
    yield topic && put(action);
  }
}

function* adminChannel(ws) {
  yield call(initWsConnection)
  const topic = yield select(state => state.auth && state.auth.user && state.auth.user.roles[0])
  if (topic === 'admin') {
    const subscription = yield topic && call(subscribeAdminChannel, ws);
    while (true) {
      const action = yield topic && take(subscription);
      yield topic && put(action);
    }
  }
}

function* unsubscribe() {
  const conversations = yield select(state => state.messages.conversation.list);
  try {
    conversations.forEach(conversation => {
      const subcription = Ws()
        .getSubscription(`conversation:${conversation.id}`)
      if (subcription) {
        subcription.close()
      }
    })
  } catch (error) {
    console.log(error.message);
  }
}

export function* watchInitWsConnection() {
  const wsSubscription = yield call(initWsConnection)
  // while (true) {
  const action = yield take(wsSubscription)
  yield put(action)

  // }
}

function* searchUsers({ payload }) {
  try {
    const role = yield select(state => state.auth.user.roles[0])
    const { data } = yield call(instance(role).post, '/user/quick-search', {
      user_name: payload,
      email: payload,
      size: 5
    })
    yield put({ type: types.SEARCH_FETCHED, results: data })
  } catch (error) {
    console.log(error)
  }
}

function* quickMessage({ payload }) {
  try {
    const role = yield select(state => state.auth.user.roles[0])
    const { data } = yield call(instance(role).post, '/conversations/send-new-message', {
      recipient_ids: payload.users.map(usr => usr.id),
      content_type: 'message',
      content: payload.message || ''
    })

    console.log(data)
    console.log('todo reload conversations')
    yield put({ type: types.GET_CONVERSATIONS })
    yield put({ type: types.GET_MESSAGES, payload: data[0] })
  } catch (error) {
    console.log(error)
  }
}

function* acceptMessages({ payload }) {
  try {
    const role = yield select(state => state.auth.user.roles[0])
    const { data } = yield call(instance(role).put, `/inquiries/${payload}`, {
      status: 'accepted'
    })
    console.log(data)
    const conversation = yield select(state => state.messages.conversation.list)
    const acceptConversation = conversation.find(con => con.inquiry_id === payload)
    acceptConversation.isAccept = true
    acceptConversation.status = 'accepted'
    yield put({ type: types.CONVERSATIONS_ACCEPT, ...{ list: conversation } });
  } catch (error) {
    console.log(error)
  }
}

function* getNewConversation({ payload, resolve, reject }) {
  try {
    const role = yield select(state => state.auth.user.roles[0])
    const { data } = yield call(instance(role).get, `/conversations/${payload}`)
    const { id, title, members, lastMessage, date, status, inquiry_id, isAccept } = data
    yield put({
      type: types.NEW_CONVERSATION,
      conversation: { id, title, members, lastMessage, date, status, inquiry_id, isAccept }
    })
    resolve && resolve(data)
  } catch (error) {
    reject && reject(error)
  }
}

function* receiveMsgConversation() {
  const newConversation = yield select(state => state.messages.newConversation)
  if (newConversation) {
    yield call(getNewConversation, { payload: newConversation })
  }
}

function* conversationTyping({ payload }) {
  const conversation_id = yield select(state => state.messages.index.id)
  const isRead = yield select(state => state.messages.index.is_read)
  if (userWsChannel) {
    userWsChannel.emit('typing', { typingId: payload.typingId, conversation_id, type: 'on' })
    if (!isRead) {
      userWsChannel.emit('seen', { conversation_id })
    }
  }
}

function* conversationBlur({ payload }) {
  const conversation_id = yield select(state => state.messages.index.id)
  yield call(initWsConnection)
  if (userWsChannel) {
    userWsChannel.emit('typing', { typingId: payload.typingId, conversation_id, type: 'off' })
  }
}

function* chatWithExpert({ resolve, reject }) {
  try {
    const role = yield select(state => state.auth.user.roles[0])
    const { data } = yield call(instance(role).post, '/conversations/chat-with-expert')
    resolve && resolve(data)
  } catch (error) {
    reject && reject(error)
  }
}

export default function* () {
  yield all([
    takeLatest(types.MESSAGE_USER, setMessage),
    takeLatest(types.CHAT_WITH_EXPERT, chatWithExpert),
    takeLatest(types.GET_CONVERSATIONS, fetchConversations),
    takeLatest(types.CLOSE_SUBSCRIPTIONS, unsubscribe),
    takeLatest(types.GET_MESSAGES, fetchMessages),
    takeLatest(types.SEND_MESSAGE, setMessage),
    takeLatest(types.ACCEPT_MESSAGE, acceptMessages),
    takeLatest(types.RECEIVE_MESSAGE, receiveMsgConversation),
    takeLatest(types.SEARCH_USERS, searchUsers),
    takeLatest(types.GET_NEW_CONVERSATION, getNewConversation),
    takeLatest(types.QUICK_MESSAGE, quickMessage),
    takeLatest(types.CONVERSATION_TYPING, conversationTyping),
    takeLatest(types.MESSAGES_FETCHED, seenMessages),
    takeLatest(types.CONVERSATION_BLUR, conversationBlur),
    takeEvery(types.WS_INITIALIZED, useChannel, Ws),
    takeEvery(types.ADMIN_WS_INIT, adminChannel, Ws),
    // fork(watchInitWsConnection),
  ])
}

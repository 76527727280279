import * as types from 'actions/amenities/types'
import { handleActions } from 'redux-actions'
import remove from 'lodash/remove'

const initialState = {
  headers: [
    { name: 'AMENITIY NAME', field: 'name' },
    { name: 'LEVEL', field: 'level' },
    { name: 'STATUS', field: 'status' },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  options: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
  categoryAmenities: [],
  venueAmenities: [],
  buildingAmenities: [],
}

const amenitiesReducer = handleActions(
  {
    [types.FETCH_AMENITIES](state) {
      return {
        ...state,
        list: [],
        fetching: true,
      }
    },
    [types.FETCHED_AMENITIES](state, { response }) {
      const { data, page, total, lastPage } = response

      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_AMENITY](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.FETCHED_AMENITY](state, { amenity }) {
      return {
        ...state,
        details: amenity,
        fetching: false,
      }
    },
    [types.CREATE_AMENITY](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_AMENITY](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_AMENITY](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_AMENITY](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.DELETE_AMENITY](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.DELETED_AMENITY](state, { id }) {
      return {
        ...state,
        list: remove(state.list, (value) => value.id !== id),
        fetching: false,
      }
    },
    [types.FETCH_AMENITY_OPTIONS](state) {
      return {
        ...state,
        options: [],
        fetching: true,
      }
    },
    [types.FETCHED_AMENITY_OPTIONS](state, { options }) {
      return {
        ...state,
        options,
        fetching: false,
      }
    },
    [types.FETCHED_BUILDING_AMENITIES](state, { amenities }) {
      const newAmenities = amenities.map((item) => ({ label: item.name, value: item.id }))
      return { ...state, buildingAmenities: newAmenities }
    },
    [types.FETCHED_CATEGORY_AMENITY](state, { amenities }) {
      const newAmenities = amenities.map((item) => ({ label: item.name, value: item.id }))
      return {
        ...state,
        categoryAmenities: newAmenities,
      }
    },
    [types.FETCHED_VENUE_AMENITY](state, { amenities }) {
      return {
        ...state,
        venueAmenities: amenities,
      }
    },
  },
  initialState
)

export default amenitiesReducer

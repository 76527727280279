import api, { apiConfig } from 'constants/api'
import FileDownload from 'js-file-download'
import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import * as types from 'actions/leads/types'
import * as notifTypes from 'actions/notifications/types'
import { GET_MESSAGES } from 'actions/messages/types'
import moment from 'moment'
import formSelector from './selectors/form'

const apiAdmin = apiConfig({ baseURL: '/api/admin' })

function* scheduleTour({ payload }) {
  try {
    const {
      space: { value: spaceId },
      place,
      isAuth,
      schedule,
      time,
      firstName,
      lastName,
      phone,
      message,
      password,
    } = payload
    let { email } = payload
    const scheduleFormatted = schedule ? moment(schedule, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
    const timeFormatted = time ? moment(time).format('HH:mm') : null
    const withSignUp = !isAuth
    email = !isAuth ? email : null // cos authenticated user doesn't need an email response

    const { data } = yield call(api.post, 'inquiries/tour', {
      spaceId,
      schedule: `${scheduleFormatted} ${timeFormatted}`,
      // time: timeFormatted,
      withSignUp,
      firstName,
      lastName,
      email,
      phone,
      message,
      password,
      place,
    })

    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: types.SCHEDULED_TOUR })])
  } catch (error) {
    console.log(error)
  }
}

function* remindBookingToMerchant({ payload }) {
  try {
    const { conversationId } = payload
    const { data } = yield call(api.post, 'conversations/remind', {
      conversationId,
    })
    yield put({ type: GET_MESSAGES, payload: conversationId })
    yield put({ type: notifTypes.SUCCESS, message: data.message })
    return data
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: response.data.message })
  }
}

function* refreshInquiry({ payload }) {
  try {
    const { inquiryId, conversationId, scheduleDate, scheduleTime } = payload
    const schedule = moment(`${scheduleDate} ${scheduleTime}`).format('YYYY-MM-DD HH:mm:ss')
    const { data } = yield call(api.post, '/conversations/refresh-inquiry', {
      inquiryId,
      conversationId,
      schedule,
    })
    yield put({ type: GET_MESSAGES, payload: conversationId })
    yield put({ type: notifTypes.SUCCESS, message: data.message })
    return data.type
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: response.data.message })
  }
}

function* messageSpace({ payload }) {
  try {
    const {
      space: { value: spaceId },
      place,
      isAuth,
      schedule,
      firstName,
      lastName,
      phone,
      message,
      password,
      similarSpaces,
      uncertain,
    } = payload
    let { email } = payload
    const scheduleFormatted = schedule
      ? moment(schedule, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
      : null
    const withSignUp = !isAuth
    email = !isAuth ? email : null // cos authenticated user doesn't need an email response

    const { data } = yield call(api.post, 'inquiries/message-space', {
      spaceId,
      schedule: scheduleFormatted,
      withSignUp,
      firstName,
      lastName,
      email,
      phone,
      message,
      password,
      place,
      similarSpaces,
      uncertain,
    })

    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: types.MESSAGED_SPACE })])
  } catch ({ response }) {
    if (response) console.warn(response.data)
    yield put({ type: notifTypes.ERROR, message: response.data.error || response.data[0].message })
  }
}

function* adminFetchLeads({ payload }) {
  try {
    const selector = yield formSelector('admin-leads-filter-form')
    const searchBar = yield select((state) => selector(state, 'admin_search_filter'))
    const active = yield select((state) => selector(state, 'active_filter'))
    let isActive
    if (active) isActive = active === 'active'
    const type = yield select((state) => selector(state, 'type_filter'))
    const primarySecondary = yield select((state) => selector(state, 'primary_secondary_filter'))
    let isPrimary
    if (primarySecondary) isPrimary = primarySecondary === 'primary'
    const merchantFilter = yield select((state) => selector(state, 'merchant_filter'))
    const merchantIds = merchantFilter ? merchantFilter.map((m) => m.value) : []
    const places = yield select((state) => selector(state, 'place_filter'))
    const placeIds = places ? places.map((place) => place.value) : []
    const statusFilter = yield select((state) => selector(state, 'status_filter'))
    const status = statusFilter ? statusFilter.map((val) => val.value) : []
    const dateFilter = yield select((state) => selector(state, 'date_filter'))
    const { limit, page } = payload
    const { data } = yield call(apiAdmin.get, '/inquiries', {
      params: {
        size: limit,
        page,
        is_active: isActive,
        type,
        is_primary: isPrimary,
        status,
        place_ids: placeIds,
        from_date: dateFilter ? moment(dateFilter[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
        to_date: dateFilter ? moment(dateFilter[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
        merchant_ids: merchantIds,
        search_bar: searchBar,
      },
    })
    yield put({ type: types.ADMIN_FETCHED_LEADS, inquiry: data.inquiry, role: 'admin' })
  } catch ({ response }) {
    if (response) console.warn(response.data)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* fetchLeadDetails({ payload }) {
  try {
    const { data } = yield call(apiAdmin.get, `/inquiries/${payload}`)
    data.is_primary = data.is_primary === 1
    const requestDate = data.requestDate
    data.visitTime = new Date(moment(requestDate).format('YYYY-MM-DD HH:mm:ss'))
    data.requestDate = moment(requestDate).format('LL')
    data.lead_status = data.status
    data.merchant_last_online = moment(data.merchant_last_online).format('YYYY-MM-DD HH:mm:ss')
    data.user_last_online = moment(data.user_last_online).format('YYYY-MM-DD HH:mm:ss')
    data.conversations = data.conversations ? data.conversations : []
    yield put({ type: types.FETCHED_LEAD_DETAILS, data })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}

function* updateLeadDetails({ payload }) {
  try {
    payload.status = payload.lead_status
    payload.type = payload.requestType
    const scheduleDate = moment(payload.requestDate).format('YYYY-MM-DD')
    payload.schedule = !payload.visitTime
      ? `${scheduleDate} 00:00:00`
      : `${scheduleDate} ${moment(payload.visitTime).format('HH:mm:ss')}`
    yield call(apiAdmin.put, `/inquiries/${payload.id}`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success edit lead.' })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* deleteLead({ payload }) {
  try {
    yield call(apiAdmin.delete, `/inquiries/bulk-delete?ids[0]=${payload}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Success delete lead!' })
    const page = yield select((state) => state.leads.admin.page)
    const limit = yield select((state) => state.leads.admin.limit)
    yield call(adminFetchLeads, { payload: { page, limit } })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* createNewLead({ payload }) {
  try {
    if (payload.schedule) payload.schedule = moment(payload.schedule).format('YYYY-MM-DD HH:mm:ss')
    yield call(apiAdmin.post, `inquiries`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success create lead!' })
  } catch ({ response }) {
    let message
    if (Array.isArray(response.data)) {
      message = response.data.map((err) => err.message).join(', ')
    } else {
      message = response?.data?.message || ''
    }

    yield put({ type: notifTypes.ERROR, message })
  }
}

function* bulkDeleteLead({ payload }) {
  try {
    yield call(apiAdmin.delete, '/inquiries/bulk-delete', {
      data: {
        ids: payload,
      },
    })
    const page = yield select((state) => state.leads.admin.page)
    const limit = yield select((state) => state.leads.admin.limit)
    yield call(adminFetchLeads, { payload: { page, limit } })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* exportLeads() {
  try {
    const selector = yield formSelector('admin-leads-filter-form')
    const searchBar = yield select((state) => selector(state, 'admin_search_filter'))
    const active = yield select((state) => selector(state, 'active_filter'))
    let isActive
    if (active) isActive = active === 'active'
    const type = yield select((state) => selector(state, 'type_filter'))
    const primarySecondary = yield select((state) => selector(state, 'primary_secondary_filter'))
    let isPrimary
    if (primarySecondary) isPrimary = primarySecondary === 'primary'
    const merchantFilter = yield select((state) => selector(state, 'merchant_filter'))
    const merchantIds = merchantFilter ? merchantFilter.map((m) => m.value) : []
    const places = yield select((state) => selector(state, 'place_filter'))
    const placeIds = places ? places.map((place) => place.value) : []
    const statusFilter = yield select((state) => selector(state, 'status_filter'))
    const status = statusFilter ? statusFilter.map((val) => val.value) : []
    const dateFilter = yield select((state) => selector(state, 'date_filter'))

    const response = yield call(apiAdmin.get, '/export-leads', {
      responseType: 'arraybuffer',
      params: {
        is_active: isActive,
        type,
        is_primary: isPrimary,
        status,
        place_ids: placeIds,
        from_date: dateFilter ? moment(dateFilter[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
        to_date: dateFilter ? moment(dateFilter[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
        merchant_ids: merchantIds,
        search_bar: searchBar,
      },
    })
    FileDownload(response.data, 'leads.xlsx')
    yield put({ type: types.EXPORTED_LEADS })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error Export Leads' })
  }
}

export default function* () {
  yield takeLatest(types.ADMIN_FETCH_LEADS, adminFetchLeads)
  yield takeLatest(types.CREATE_LEAD, createNewLead)
  yield takeLatest(types.DELETE_LEAD, deleteLead)
  yield takeLatest(types.BULK_DELETE_LEAD, bulkDeleteLead)
  yield takeLatest(types.FETCH_LEAD_DETAILS, fetchLeadDetails)
  yield takeLatest(types.UPDATE_LEAD_DETAILS, updateLeadDetails)
  yield takeLatest(types.SCHEDULE_TOUR, scheduleTour)
  yield takeLatest(types.MESSAGE_SPACE, messageSpace)
  yield takeLatest(types.EXPORT_LEADS, exportLeads)
  yield takeLatest(types.REMIND_BOOKING_TO_MERCHANT, remindBookingToMerchant)
  yield takeLatest(types.REFRESH_INQUIRY, refreshInquiry)
}

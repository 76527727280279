import * as types from 'actions/spaceFunction/types'
import { handleActions } from 'redux-actions'
import remove from 'lodash/remove'

const initialState = {
  headers: [
    { name: 'SPACE FUNCTION NAME', field: 'name' },
    { name: 'TYPE', field: 'type' },
    { name: 'SPACE CATEGORY', field: 'spaceCategory' },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
  categorySpaceFunction: {
    tags: [],
  },
}

const spaceFunctionReducer = handleActions(
  {
    [types.FETCH_SPACE_FUNCTIONS](state) {
      return {
        ...state,
        fetching: true,
        list: [],
      }
    },
    [types.FETCHED_SPACE_FUNCTIONS](state, { response }) {
      const { data, page, total, lastPage } = response

      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCHED_SPACE_BUILDINGS](state, { response }) {
      return {
        ...state,
        buildingList: response,
        fetching: false,
      }
    },
    [types.FETCH_SPACE_FUNCTION](state) {
      return {
        ...state,
        fetching: true,
        details: {},
      }
    },
    [types.FETCHED_SPACE_FUNCTION](state, { spaceFunction }) {
      return {
        ...state,
        fetching: false,
        details: spaceFunction,
      }
    },
    [types.CREATE_SPACE_FUNCTION](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_SPACE_FUNCTION](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_SPACE_FUNCTION](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_SPACE_FUNCTION](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.DELETE_SPACE_FUNCTION](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.DELETED_SPACE_FUNCTION](state, { id }) {
      return {
        ...state,
        list: remove(state.list, (value) => value.id !== id),
        fetching: false,
      }
    },
    [types.FETCHED_CATEGORY_SPACE_FUNCTION](state, { data }) {
      let { tags } = data
      tags = tags.map((tag) => {
        const newTag = {
          label: tag.name,
          value: tag.id,
        }
        return newTag
      })
      return {
        ...state,
        categorySpaceFunction: {
          tags,
        },
      }
    },
    [types.RESET_CATEGORY_SPACE_FUNCTION](state) {
      return {
        ...state,
        categorySpaceFunction: {
          tags: [],
        },
      }
    },
  },
  initialState
)

export default spaceFunctionReducer

import api, { apiConfig } from 'constants/api'
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects'
import * as types from 'actions/amenities/types'
import * as notifTypes from 'actions/notifications/types'

import formSelector from './selectors/form'

const apiAdmin = apiConfig({ baseURL: '/api/admin' })

function* getAmenities({ payload }) {
  try {
    const statePage = yield select((state) => state.amenities.page)
    const stateLimit = yield select((state) => state.amenities.limit)
    const { limit = stateLimit, page = statePage } = payload

    const selector = yield formSelector('admin-amenities-filter-form')
    const keyword = yield select((state) => selector(state, 'admin_search_filter'))
    const isPublished = yield select((state) => selector(state, 'publish_filter'))
    // isPublished = isPublished ? isPublished.value : null

    const { data } = yield call(api.get, '/amenities', {
      params: {
        limit,
        page,
        name: keyword,
        isPublished,
      },
    })

    yield delay(300) // to simulate tableloader
    yield put({ type: types.FETCHED_AMENITIES, response: data.amenities })
  } catch ({ response }) {
    if (response) console.error(response.data)
  }
}

function* getAmenity({ payload }) {
  try {
    const { data } = yield call(api.get, `/amenities/${payload}`)

    yield put({ type: types.FETCHED_AMENITY, amenity: data.amenity })
  } catch ({ response }) {
    if (response) console.warn(response.data)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* createAmenity({ payload }) {
  try {
    const { categories, level } = payload
    const categoriesPayload = level === 'space' ? categories.map((cat) => cat.value) : []
    const { data } = yield call(api.post, '/amenities', {
      ...payload,
      isPublished: payload.published,
      categories: categoriesPayload,
    })

    yield all([put({ type: types.CREATED_AMENITY }), put({ type: notifTypes.SUCCESS, ...data })])
  } catch ({ response }) {
    if (response) console.log(response.data)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* editAmenity({ payload }) {
  try {
    const { categories, level } = payload
    const categoriesPayload = level === 'space' ? categories.map((cat) => cat.value) : []
    const { data } = yield call(api.put, `/amenities/${payload.id}`, {
      ...payload,
      isPublished: payload.published,
      categories: categoriesPayload,
    })

    yield all([put({ type: types.EDITED_AMENITY }), put({ type: notifTypes.SUCCESS, ...data })])
  } catch ({ response }) {
    if (response) console.log(response.data)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* deleteAmenity({ payload }) {
  try {
    const { data } = yield call(api.delete, `/amenities/${payload}`)

    yield all([
      put({ type: notifTypes.SUCCESS, ...data }),
      put({ type: types.DELETED_AMENITY, id: payload }),
    ])
  } catch ({ response }) {
    if (response) console.log(response.message)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getAmenityOptions({ payload }) {
  try {
    const { data } = yield call(api.get, `/amenities/options/${payload}`)

    yield put({ type: types.FETCHED_AMENITY_OPTIONS, options: data.amenities })
  } catch (err) {
    console.warn(err)
  }
}

function* getAmenityByCategory({ payload }) {
  try {
    const { data } = yield call(api.get, `/categories/${payload}/amenities`)
    yield put({ type: types.FETCHED_CATEGORY_AMENITY, amenities: data })
  } catch (err) {
    console.warn(err)
  }
}

function* getAmenitiesVenue() {
  try {
    const { data } = yield call(api.get, `/amenities/options/venue`)
    yield put({ type: types.FETCHED_VENUE_AMENITY, amenities: data.amenities })
  } catch (err) {
    console.warn(err)
  }
}

function* getBuildingAmenities() {
  try {
    const { data } = yield call(apiAdmin.get, `/building/amenities`)
    yield put({ type: types.FETCHED_BUILDING_AMENITIES, amenities: data })
  } catch (error) {
    console.log(error)
  }
}

export default function* () {
  yield takeLatest(types.FETCH_AMENITIES, getAmenities)
  yield takeLatest(types.FETCH_VENUE_AMENITY, getAmenitiesVenue)
  yield takeLatest(types.FETCH_AMENITY, getAmenity)
  yield takeLatest(types.FETCH_CATEGORY_AMENITY, getAmenityByCategory)
  yield takeLatest(types.CREATE_AMENITY, createAmenity)
  yield takeLatest(types.EDIT_AMENITY, editAmenity)
  yield takeLatest(types.DELETE_AMENITY, deleteAmenity)
  yield takeLatest(types.FETCH_AMENITY_OPTIONS, getAmenityOptions)
  yield takeLatest(types.FETCH_BUILDING_AMENITIES, getBuildingAmenities)
}

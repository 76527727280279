import * as types from 'actions/buildings/types'
import { handleActions } from 'redux-actions'

const initialState = {
  admin: {
    headers: [
      { name: 'ID', field: 'id' },
      { name: 'BUILDING NAME', field: 'buildingName' },
      { name: 'AREA', field: 'area' },
      { name: 'ADDITIONAL NAME', field: 'additional_name' },
      { name: 'CITY', field: 'city' },
      { name: 'BUILDING USE', field: 'buildingUse' },
      { name: 'NUMBER OF FLOORS', field: 'numberOffloors' },
      { name: 'BUILDING CLASSFICATION', field: 'buildingClassfication' },
      { name: 'BRAND', field: 'brand' },
      { name: 'TYPICAL FLOOR AREA', field: 'typicalFloorArea' },
      { name: 'NET LEFTTABLE AREA', field: 'term' },
      // temporary to check before deploy
      // { name: 'AVERAGE PRICE PER SQFT/SQM', field: 'averagePricePerSqftSqm' },
      { name: 'STATUS', field: 'status' },
      { name: 'ACTIONS', field: 'actions' },
    ],
    selectedBuilding: null,
    list: {
      data: [],
      page: 1,
      lastPage: 0,
      total: 0,
      perPage: 10,
    },
    fetching: false,
    details: {},
  },
  public: {
    details: {},
    suggestions: [],
    listingVenues: {
      list: [],
      page: 1,
      limit: 6,
      lastPage: 1,
      total: 0,
    },
  },
}

const buildingReducer = handleActions(
  {
    [types.ADMIN_FETCHED_BUILDINGS](state, { buildings }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          list: buildings,
        },
      }
    },
    [types.ADMIN_FETCHED_BUILDING_AREA](state, { buildingArea }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          buildingArea,
        },
      }
    },
    [types.ADMIN_FETCHED_BUILDING_USE](state, { buildingUse }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          buildingUse,
        },
      }
    },
    [types.FETCHED_GMAP](state) {
      const newState = {
        ...state,
        admin: { ...state.admin, gmap_fetching: false },
      }

      return newState
    },
    [types.FETCH_GMAP](state) {
      const newState = {
        ...state,
        admin: { ...state.admin, gmap_fetching: true },
      }

      return newState
    },
    [types.ADMIN_FETCHED_BUILDING_DETAILS](state, { building }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          details: building,
        },
      }
    },
    [types.FETCHED_PUBLIC_TRANSPORTS](state, { payload }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          public_transports: payload,
        },
      }
    },
    [types.SELECT_BUILDING](state, { payload }) {
      return {
        ...state,
        admin: {
          selectedBuilding: payload,
        },
      }
    },
    [types.FETCH_BUILDING_DETAILS_SUCCESS](state, { payload }) {
      return {
        ...state,
        public: {
          ...state.public,
          details: { ...payload },
        },
      }
    },
    [types.FETCH_SUGGESTION_BUILDING_SUCCESS](state, { payload }) {
      return {
        ...state,
        public: {
          ...state.public,
          suggestions: payload,
        },
      }
    },
    [types.FETCHED_LAND_LORD](state, { payload }) {
      const data = payload?.map((val) => ({ value: val.id, label: val.name }))
      return {
        ...state,
        admin: {
          ...state.admin,
          land_lords: data,
        },
      }
    },
    [types.FETCH_BUILDING_LISTING_VENUE_SUCCESS](state, { payload }) {
      return {
        ...state,
        public: {
          ...state.public,
          listingVenues: { ...payload },
        },
      }
    },
    [types.ADMIN_FETCH_BUILDING_GM_IMGS](state) {
      return {
        ...state,
        admin: {
          ...state.admin,
          fetching: true,
        },
      }
    },
    [types.ADMIN_FETCHED_BUILDING_GM_IMGS](state, { payload }) {
      let newImages = [...(state.admin.details?.images ?? []), ...payload]
      newImages = newImages.map((item, idx) => ({ ...item, order: idx + 1 }))
      return {
        ...state,
        admin: {
          ...state.admin,
          details: { ...state.admin.details, images: newImages },
          fetching: false,
        },
      }
    },
    [types.SET_FETCH](state, { payload }) {
      return { ...state, admin: { ...state.admin, fetching: payload } }
    },
    [types.FETCHED_COMMERCIAL_BUILDING_AREAS](state, { payload }) {
      return { ...state, public: { ...state.public, commercialBuildingAreas: payload } }
    },
    [types.FETCHED_COMMERCIAL_BUILDINGS](state, { payload }) {
      return { ...state, public: { ...state.public, commercialBuildings: payload } }
    },
    [types.FETCHED_BUILDING_OFFICE_TABLE](state, { payload }) {
      return {
        ...state, public: { ...state.public, officesTable: payload }
      }
    },
    [types.FETCHED_BUILDING_OFFICE_VENUE_CARD](state, { payload }) {
      const { results, isFirstTimeFetch } = payload
      // Merge current office spaces with new office spaces
      const { public: { officesVenueCard } } = state
      let stateOfficeSpaces = []
      if (!isFirstTimeFetch && officesVenueCard?.results) {
        stateOfficeSpaces = officesVenueCard?.results
      }
      const officeSpaces = [...stateOfficeSpaces, ...results]

      return {
        ...state, public: { ...state.public, officesVenueCard: { ...payload, results: officeSpaces } }
      }
    }
  },
  initialState
)

export default buildingReducer

import { takeLatest, put, all } from 'redux-saga/effects'
import { stopSubmit, setSubmitFailed, change } from 'redux-form'
import * as types from 'actions/notifications/types'

function* notify({ type, message }) {
  try {
    yield put({ type, message })
  } catch (error) {
    console.warn(error)
  }
}

function* getSyncErrorMessages({ type, message, payload }) {
  const { errors, formName } = payload ?? {}
  if (errors && errors.length > 0) {
    const fieldErrors = errors.reduce((acc, error) => {
      acc[error.field] = error.message
      return acc
    }, {})
    yield all([
      ...errors.map(() => put(setSubmitFailed(formName))),
      ...errors.map(() => put(change(formName))),
      put(stopSubmit(formName, fieldErrors)),
      notify({ type, message }),
    ])
  }
}

export default function* () {
  yield takeLatest(types.NOTIFY, notify)
  yield takeLatest(types.ERROR, getSyncErrorMessages)
}

import * as types from 'actions/places/types'
import { handleActions } from 'redux-actions'

const initialState = {
  headers: [
    { name: 'PLACE NAME', field: 'place_name' },
    { name: 'STATUS', field: 'status' },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  options: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
  spaceCount: {},
}

const placeReducer = handleActions(
  {
    [types.FETCH_PLACES](state) {
      return {
        ...state,
        list: [],
        fetching: true,
      }
    },
    [types.FETCHED_PLACES](state, { response }) {
      const { data, page, total, lastPage } = response
      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_PLACE](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_PLACE](state, { place }) {
      return {
        ...state,
        details: place,
        fetching: false,
      }
    },
    [types.FETCH_PLACE_OPTIONS](state) {
      return {
        ...state,
        options: [],
        fetching: true,
      }
    },
    [types.FETCHED_PLACE_OPTIONS](state, { options }) {
      return {
        ...state,
        options,
        fetching: false,
      }
    },
    [types.CREATE_PLACE](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_PLACE](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_PLACE](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_PLACE](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.FETCH_PLACE_SPACE_COUNT](state, { city }) {
      return {
        ...state,
        spaceCount: {
          ...state.spaceCount,
          city,
          count: 0,
        },
        fetching: true,
      }
    },
    [types.FETCHED_PLACE_SPACE_COUNT](state, { count }) {
      return {
        ...state,
        spaceCount: {
          ...state.spaceCount,
          count,
        },
        fetching: false,
      }
    },
  },
  initialState,
)

export default placeReducer

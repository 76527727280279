import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modalhandler from 'components/shared/modal/modal-handler'
import ClientAuthModal from 'components/shared/modals/client-auth'
import HeartIcon from 'components/shared/icons/heart'
import HeartOutlineIcon from 'components/shared/icons/heart-outline'
import authActions from 'actions/auth/actions'
import notifactionActions from 'actions/notifications/actions'

const FavoriteHeartTextHandler = ({
  id,
  userRole,
  notifyError,
  favoriteList,
  selectAsFavorite,
  children,
  className = 'flex cursor-pointer items-center',
}) => {
  const [isFave, setFave] = useState(false)
  useEffect(() => {
    const isFavorite = favoriteList.some((fave) => fave.id === id)
    setFave(isFavorite)
  }, [id])

  const handleOnClick = () => {
    if (userRole && userRole[0].name !== 'client') {
      notifyError({
        message: 'You must login to a client account in order to add favorites.',
      })
      return
    }
    selectAsFavorite({ id, isFave: !isFave })
    setFave(!isFave)
  }

  // allow only the client
  const renderAuthenticated = () => (
    <>
      {userRole[0]?.name === 'client' ? (
        <span className="authenticated">{isFave ? <HeartIcon /> : <HeartOutlineIcon />}</span>
      ) : (
        <span className="authenticated">
          <HeartOutlineIcon />
        </span>
      )}
    </>
  )

  return (
    <div className={className} onClick={handleOnClick} style={{ color: isFave && '#ff396a' }}>
      {userRole && userRole.length ? (
        <>
          {renderAuthenticated()}
          {children}
        </>
      ) : (
        <Modalhandler title={<HeartOutlineIcon />}>
          {({ toggle }) => <ClientAuthModal toggle={toggle} initial={'sign-in'} />}
        </Modalhandler>
      )}
    </div>
  )
}

FavoriteHeartTextHandler.propTypes = {
  id: PropTypes.number,
  selectAsFavorite: PropTypes.func.isRequired,
  className: PropTypes.string,
  favoriteList: PropTypes.array,
  children: PropTypes.node,
  userRole: PropTypes.array,
  notifyError: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  userRole: auth.user ? auth.user.roles : null,
  favoriteList: auth.user ? auth.user.favorites : [],
})

const mapDispatchToProps = {
  selectAsFavorite: authActions.selectAsFavorite,
  notifyError: notifactionActions.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteHeartTextHandler)

import flatten from 'lodash/flatten'

// @TODO
// venues uses name
// brands?
// landmarks uses title
// how could i intergate this?
// like changing the name/title
// to a standard version
export const listMapper = data => console.log(data)

export const quickSearchMapper = data => flatten(Object.values(data))

export const capacityMapper = data => console.log(data)

export const amenityMapper = data => console.log(data)

export const brandMapper = data => console.log(data)

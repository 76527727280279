import { handleActions } from 'redux-actions'
import * as types from 'actions/passport/types'

export const initialState = {
  options: {},
  fetching: false,
  fetchingMembers: false,
  bookings: {
    owner: {
      headers: [
        { name: 'BOOKING DATE', field: 'bookingDate' },
        { name: 'SPACE', field: 'space' },
        { name: 'STATUS', field: 'status' },
        { name: '', field: 'actions' },
      ],
      list: [],
      page: 1,
      lastPage: 1,
      total: 0,
      limit: 10,
    },
    guest: {
      headers: [
        { name: 'BOOKING DATE', field: 'bookingDate' },
        { name: 'SPACE', field: 'space' },
        { name: 'STATUS', field: 'status' },
        { name: 'GUESTS', field: 'pax' },
      ],
      list: [],
      page: 1,
      lastPage: 1,
      total: 0,
      limit: 10,
    }
  },
  bookingMembers: {
    list: [],
    bookingDate: '',
    numberOfBookings: 0,
    space: '',
    spaceUrl: '',
  },
  search: {
    list: [],
    page: 1,
    lastPage: 1,
    total: 0,
    limit: 20,
    searchBox: {
      results: [],
      fetching: false,
    },
  },
  planDetails: {},
  processing: false,
  admin: {
    subscription: {
      data: [],
      page: 1,
      lastPage: 1,
      total: 0,
      limit: 20,
      fetching: false,
      details: {}
    },
    invitation: {
      data: [],
      page: 1,
      lastPage: 1,
      total: 0,
      limit: 20,
      fetching: false,
      details: {}
    }
  }
}

const passportReducer = handleActions(
  {
    [types.SEARCH_PASSPORT](state) {
      return {
        ...state,
        search: { ...state.search, list: [] },
        fetching: true,
      }
    },
    [types.SEARCHED_PASSPORT](state, { list, page, limit, total, lastPage }) {
      return {
        ...state,
        search: { ...state.search, list, page, limit, total, lastPage },
        fetching: false,
      }
    },
    [types.FETCH_PASSPORT_BOOKINGS](state) {
      return {
        ...state,
        bookings: { ...state.bookings, list: [] },
        fetching: true,
      }
    },
    [types.FETCHED_PASSPORT_BOOKINGS](state, { bookings, guestBookings }) {
      return {
        ...state,
        bookings: {
          owner: { ...state.bookings.owner, ...bookings },
          guest: { ...state.bookings.guest, ...guestBookings },
        },
        fetching: false,
      }
    },
    [types.FETCH_BOOKING_MEMBERS](state) {
      return {
        ...state,
        bookingMembers: {
          ...state.bookingMembers,
          list: []
        },
        fetchingMembers: true,
      }
    },
    [types.FETCHED_BOOKING_MEMBERS](state, { payload }) {
      return {
        ...state,
        bookingMembers: payload,
        fetchingMembers: false,
      }
    },
    [types.FETCHED_PASSPORT_PAYMENT_DETAIL](state, { paymentDetail }) {
      return {
        ...state,
        paymentDetail
      }
    },
    [types.SEARCH_PASSPORT_LOOKOUT](state) {
      return {
        ...state,
        search: { ...state.search, searchbox: { results: [], fetching: true } },
      }
    },
    [types.SEARCHED_PASSPORT_LOOKOUT](state, { results }) {
      return {
        ...state,
        search: { ...state.search, searchbox: { results, fetching: true } },
      }
    },
    [types.SEARCH_PASSPORT_LOOKOUT_RESET](state) {
      return {
        ...state,
        search: { ...state.search, searchbox: { results: [] } },
      }
    },
    [types.FETCH_PASSPORT_PLAN_DETAILS](state) {
      return {
        ...state,
        planDetails: {},
        fetching: true,
      }
    },
    [types.FETCHED_PASSPORT_PLAN_DETAILS](state, { planDetails }) {
      return {
        ...state,
        planDetails,
        fetching: false,
      }
    },
    [types.PAY_PASSPPORT_SUBSCRIPTION](state) {
      return {
        ...state,
        processing: true,
      }
    },
    [types.PAID_PASSPPORT_SUBSCRIPTION](state) {
      return {
        ...state,
        processing: false,
      }
    },
    [types.FETCHED_PASSPORT](state, { data, passportType }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          [passportType]: {
            ...state.admin[passportType],
            ...data,
            fetching: false
          }
        }
      }
    },
    [types.FETCH_PASSPORT](state) {
      return {
        ...state,
        admin: {
          ...state.admin,
          subscription: {
            ...state.admin.subscription,
            fetching: true
          }
        }
      }
    },
    [types.FETCHED_PASSPORT_SUBSCRIPTION_DETAILS](state, { data }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          subscription: {
            ...state.admin.subscription,
            details: data
          }
        }
      }
    },
    [types.PASSPORT_CORPORATE_REGIS](state) {
      return {
        ...state,
        fetching: true
      }
    },
    [types.PASSPORT_CORPORATE_REGIS_DONE](state) {
      return {
        ...state,
        fetching: false
      }
    },
    [types.PASSPORT_CANCELLED](state, { id }) {
      const list = state.bookings.owner.list
      const passport = list.find(p => p.id === id)
      if (passport) passport.status = 'cancelled'
      return {
        ...state,
        bookings: {
          ...state.bookings,
          list: [...list]
        }
      }
    },
    [types.EXPORT_PASSPORTS](state) {
      return {
        ...state,
        fetching: true
      }
    },
    [types.EXPORTED_PASSPORTS](state) {
      return {
        ...state,
        fetching: false
      }
    }
  },
  initialState,
)

export default passportReducer

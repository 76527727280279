import * as types from 'actions/developer/types';
import { handleActions } from 'redux-actions';

const initialState = {
  selectedDeveloper: null,
  lastPage: 1,
  page: 1,
  perPage: 20,
  data: []
};

const developerReducer = handleActions(
  {
    [types.FETCHED_DEVELOPER](state, { developers }) {
      return {
        ...state,
        data: developers,
      };
    },
    [types.SELECT_DEVELOPER](state, { payload }) {
      return {
        ...state,
        selectedDeveloper: payload,
      }
    },
  },
  initialState,
);

export default developerReducer

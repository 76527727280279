import Ws from '@adonisjs/websocket-client';

let _ws;

export const connect = () => {
  try {
    const env = 'prod'
    let socketUrl = ''
    if (env === 'local') {
      socketUrl = 'ws://localhost:3333';
    } else if (env === 'staging') {
      socketUrl = 'wss://stg.flyspaces.com';
    } else if (env === 'beta') {
      socketUrl = 'https://beta.flyspaces.com:4000/';
    } else if (env === 'prod') {
      socketUrl = 'wss://flyspaces.com';
    } else if (env === 'local-device') {
      socketUrl = 'ws://192.168.3.9:3333/';
    } else if (env === 'mel') {
      socketUrl = 'http://192.168.0.219:3000/';
    } else if (env === 'jason') {
      socketUrl = 'http://192.168.0.210:3000/';
    } else if (env === 'qa') {
      socketUrl = 'http://qa.flyspaces.com:4000/';
    }
    _ws = Ws(socketUrl)
      .connect();
    console.log('websocket client initialized', _ws)
    return _ws
  } catch (error) {
    console.log('ws initialize error:', error)
  }
}

const ws = () => _ws;

export default ws;

import axios from 'axios';

const api = axios && axios.create({
  baseURL: '/api',
  Accept: 'application/json',
  Content: 'application/json',
});

export const apiConfig = ({ baseURL, headers }) => axios && axios.create({
  baseURL,
  headers,
});

export default api;

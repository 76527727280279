import * as types from 'actions/landmarks/types'
import { handleActions } from 'redux-actions'
import remove from 'lodash/remove'

const initialState = {
  headers: [
    { name: 'LANDMARK NAME', field: 'name' },
    { name: 'AREA', field: 'area' },
    { name: 'PLACE', field: 'place' },
    { name: 'STATUS', field: 'published' },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
  areaLandmarks: []
}

const landmarkReducer = handleActions(
  {
    [types.FETCH_LANDMARKS](state) {
      return {
        ...state,
        fetching: true,
        list: [],
      }
    },
    [types.FETCHED_LANDMARKS](state, { response }) {
      const { data, page, total, lastPage } = response

      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_LANDMARK](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_LANDMARK](state, { landmark }) {
      return {
        ...state,
        details: landmark,
        fetching: false,
      }
    },
    [types.CREATE_LANDMARK](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_LANDMARK](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_LANDMARK](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_LANDMARK](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.DELETE_LANDMARK](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.DELETED_LANDMARK](state, { id }) {
      return {
        ...state,
        list: remove(state.list, value => value.id !== id),
        fetching: false,
      }
    },
    [types.FETCHED_LANDMARK_PER_AREA](state, { landmarks }) {
      return {
        ...state,
        areaLandmarks: landmarks
      }
    },
    [types.RESET_LANDMARK_PER_AREA](state) {
      return {
        ...state,
        areaLandmarks: []
      }
    },
  },
  initialState,
)

export default landmarkReducer

import * as types from 'actions/merchants/types';
import { handleActions } from 'redux-actions';

const initialState = {
  merchantInfo: {},
  list: [],
  venues: [],
  venueList: [], // utilized by merchant bookings
  fetching: false,
};

const defaultValue = {
  text: '',
  value: '',
  placeSlug: '',
  area: '',
  placeId: '',
};

const merchantReducer = handleActions(
  {
    [types.FETCHED_MERCHANTS](state, { merchants }) {
      return {
        ...state,
        list: [defaultValue, ...merchants],
      };
    },
    [types.MERCHANT_VENUES_UPDATED](state, { payload }) {
      return {
        ...state,
        venues: payload.length > 0 ? [defaultValue, ...payload.map(venue => ({
          area: venue.area_object && venue.area_object.name,
          placeSlug: venue.place_object && venue.place_object.slug,
          text: venue.name,
          value: venue._id,
          placeId: venue.place_object && venue.place_object._id,
        }))].filter(Boolean) : [defaultValue],
      };
    },
    [types.FETCH_MERCHANT_VENUES](state) {
      return {
        ...state,
        venueList: [],
        fetching: true,
      }
    },
    [types.FETCHED_MERCHANT_VENUES](state, { venues }) {
      return {
        ...state,
        venueList: venues,
        fetching: false,
      }
    },
  },
  initialState,
);

export default merchantReducer;

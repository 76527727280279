import api from 'constants/api'
import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import * as types from 'actions/review/types'
import * as notifTypes from 'actions/notifications/types'
import moment from 'moment'

function* getReviews({ payload }) {
  try {
    console.log(`tobe getReviews: ${payload}`)
    const statePage = yield select((state) => state.review.page)
    const stateLimit = yield select((state) => state.review.limit)
    const { limit = stateLimit, page = statePage } = payload
    const adminSearchFilter = payload.admin_search_filter
    const merchantFilters = payload.merchant_filter?.map((m) => m.value)
    const ratingFilters = payload.rating_filter?.map((r) => r.value)
    const { data } = yield call(api.get, '/reviews', {
      params: {
        limit,
        page,
        adminSearchFilter,
        merchantFilters,
        ratingFilters,
      },
    })
    yield delay(300) // to simulate tableloader
    yield put({ type: types.FETCHED_REVIEWS, response: data.reviews })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* getVenueReviews({ payload }) {
  try {
    const statePage = yield select((state) => state.review.page)
    const stateLimit = yield select((state) => state.review.limit)
    const { limit = stateLimit, page = statePage } = payload
    const adminSearchFilter = payload.admin_search_filter
    const merchantFilters = payload.merchant_filter?.map((m) => m.value)
    const ratingFilters = payload.rating_filter?.map((r) => r.value)
    const { data } = yield call(api.get, '/venue-reviews', {
      params: {
        limit,
        page,
        adminSearchFilter,
        merchantFilters,
        ratingFilters,
      },
    })
    yield delay(300) // to simulate tableloader
    yield put({ type: types.FETCHED_VENUE_REVIEWS, response: data.reviews })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* getReview({ payload }) {
  try {
    const { data } = yield call(api.get, `/reviews/${payload}`)
    console.log('getReview', data)
    yield put({ type: types.FETCHED_REVIEW, review: data.review })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* getReviewOptions({ payload }) {
  try {
    const { data } = yield call(api.get, '/reviews/options', {
      params: {
        type: payload,
      },
    })

    yield put({ type: types.FETCHED_REVIEW_OPTIONS, options: data })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* sendReview({ payload }) {
  try {
    const user = yield select((state) => state.auth.user)
    const userId = user?.id
    if (userId) {
      const { data } = yield call(api.post, 'reviews', payload)
      const review = {
        comment: data.comment,
        date: moment(data.created_at).format('MMMM YYYY'),
        stars: data.rating,
        id: data.id,
        user: user.name,
      }
      yield put({ type: types.SENT_REVIEW, review })
      yield put({ type: notifTypes.SUCCESS, message: 'Thank for your review' })
    } else {
      yield put({ type: notifTypes.ERROR, message: `Please login to review this venue/space.` })
    }
  } catch ({ message }) {
    yield put({ type: notifTypes.ERROR, message: 'Error post review.' })
  }
}

function* reloadPage() {
  const form = yield select((state) => state.form)
  const payload = form['admin-review-filter-form'].values
  yield call(getReviews, payload ? { payload } : { payload: {} })
}

function* deleteReview({ payload }) {
  try {
    yield call(api.delete, `reviews/${payload}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Review Deleted' })
    yield call(reloadPage, {})
  } catch (err) {
    yield put({ type: notifTypes.ERROR, message: 'Error delete review.' })
  }
}

function* venueReloadPage() {
  const form = yield select((state) => state.form)
  const payload = form['admin-review-filter-form'].values
  yield call(getVenueReviews, payload ? { payload } : { payload: {} })
}

function* venueDeleteReview({ payload }) {
  try {
    yield call(api.delete, `reviews/${payload}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Review Deleted' })
    yield call(venueReloadPage, {})
  } catch (err) {
    yield put({ type: notifTypes.ERROR, message: 'Error delete review.' })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_REVIEWS, getReviews)
  yield takeLatest(types.FETCH_VENUE_REVIEWS, getVenueReviews)
  yield takeLatest(types.SEND_REVIEW, sendReview)
  yield takeLatest(types.FETCH_REVIEW, getReview)
  yield takeLatest(types.FETCH_REVIEW_OPTIONS, getReviewOptions)
  yield takeLatest(types.DELETE_REVIEW, deleteReview)
  yield takeLatest(types.VENUE_DELETE_REVIEW, venueDeleteReview)
}

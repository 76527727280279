import api from 'constants/api'
import { takeLatest, put, call, delay, all, select } from 'redux-saga/effects'
import { searchOptions, searchOptionCities, searchOptionCategories } from 'sagas/selectors/search'
import { quickSearchMapper } from 'mappers/search'
import * as types from 'actions/search/types'
import * as notifTypes from 'actions/notifications/types'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'

const PUBLIC_SEARCH_API_ROUTE = {
  fast: '/spaces',
  slow: '/venues',
  building: '/building/commercial-buildings',
}

const ADDITIONAL_CATEGORIES = [
  {
    name: 'All Meeting and Events',
    slug: 'all-short-term-workspace',
    pace: 'fast',
  },
  {
    name: 'All Office Space',
    slug: 'office-space',
    pace: 'slow',
  },
]

function* getTagCount(city) {
  try {
    const { data } = yield call(api.get, `tags/count-by-city-id/${city}`)

    return data
  } catch ({ response }) {
    console.log(response)
  }
}

function* getSearchOptions(city) {
  try {
    const { data } = yield call(api.get, '/search-options')
    const { categories } = data

    const appendedCategories = [...ADDITIONAL_CATEGORIES, ...categories]
    let tagCount = []
    if (typeof city === 'number') tagCount = yield call(getTagCount, city)
    yield put({
      type: types.SEARCH_OPTIONS_FETCHED,
      options: { ...data, categories: appendedCategories, tags: tagCount },
    })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getSearchLookOut({
  payload: {
    cityName,
    searchTerm,
    categorySlug,
    capacity,
    priceTermId,
    priceMin,
    priceMax,
    amenityIds,
  },
}) {
  try {
    const categories = yield select(searchOptionCategories)
    const categoryId = categories.find((category) => category.slug === categorySlug).id || ''
    const cities = yield select(searchOptionCities)

    // do quick find for data. this leads to problem if user tries to modify the url
    const cityId =
      (cities || []).find((city) => city.placeName === startCase(cityName)).placeId || 1

    const { data } = yield call(api.get, '/quick-search/suggestions', {
      params: {
        cityId,
        searchTerm,
        categoryId,
        capacity,
        priceTermId,
        priceMin,
        priceMax,
        amenityIds,
      },
    })

    // cleaning
    const list = quickSearchMapper(data)

    yield delay(200)
    yield put({ type: types.SEARCHED_LOOKOUT, results: list })
  } catch (err) {
    console.log(err)
    // yield put({ type: notifTypes.ERROR, ...response.data })
    yield put({ type: types.SEARCHED_LOOKOUT, results: [] })
  }
}

function* searchPublic({ payload: { city, category, params, isBuilding } }) {
  try {
    const {
      a: areaIds,
      am: amenityIds,
      px: capacity,
      b: brandIds,
      pt: priceTermId,
      t: tagIds,
      lat: latQuery,
      lng: lngQuery,
      pmn: priceMin,
      pmx: priceMax,
      west: minLng,
      east: maxLng,
      south: minLat,
      north: maxLat,
      ...rest
    } = params

    const options = yield select(searchOptions)
    console.log(city)
    yield isEmpty(options) && call(getSearchOptions, city)

    const cities = yield select(searchOptionCities)
    const categories = yield select(searchOptionCategories)

    const { placeId: cityId } = (cities || []).find(({ slug }) => slug === city) || {}
    const {
      id: categoryId,
      pace,
      slug,
    } = (categories || []).find(({ slug }) => slug === category) || {}
    // have to hard code with traditional => show space result
    const publicRoute = isBuilding
      ? PUBLIC_SEARCH_API_ROUTE.building
      : PUBLIC_SEARCH_API_ROUTE[slug === 'traditional-office' ? 'fast' : pace]
    const { data } = yield call(api.get, publicRoute, {
      params: {
        cityId,
        categoryId,
        areaIds,
        amenityIds,
        capacity,
        brandIds,
        priceTermId,
        tagIds,
        latQuery,
        lngQuery,
        priceMin,
        priceMax,
        maxLat,
        maxLng,
        minLng,
        minLat,
        ...rest,
      },
    })

    const {
      lastPage,
      page,
      limit,
      total,
      results,
      data: buidingsResult,
      budget,
      tags,
      brands,
      map,
      placeInfo,
    } = data

    const finalList = isBuilding ? buidingsResult : results

    if (results) {
      const mapRp = new Map()
      results.forEach((i) => {
        const kM = JSON.stringify({ lat: i.coordinates.lat, lng: i.coordinates.lng })
        let vM = []
        if (mapRp.has(kM)) {
          vM = mapRp.get(kM)
        }
        vM.push(i.title)
        mapRp.set(kM, vM)
      })
      console.log(mapRp)
    }

    yield put({
      type: types.SEARCHED_PUBLIC,
      pace,
      list: finalList,
      budget,
      lastPage,
      page,
      limit,
      tags,
      total,
      brands,
      map,
      placeInfo,
    })
  } catch ({ response }) {
    yield all([
      // put({ type: notifTypes.ERROR, ...response.data }), @BUG causing repetetive refresh
      put({
        type: types.SEARCHED_PUBLIC,
        pace: 'fast',
        list: [],
        lastPage: 1,
        page: 1,
        limit: 20, // @TODO change how to handle an error.
        total: 0,
      }),
    ])
  }
}

export default function* () {
  yield takeLatest(types.FETCH_SEARCH_OPTIONS, getSearchOptions)
  yield takeLatest(types.SEARCH_LOOKOUT, getSearchLookOut)
  yield takeLatest(types.SEARCH_PUBLIC, searchPublic)
}

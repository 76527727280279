import api from 'constants/api'
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects'
import * as types from 'actions/landmarks/types'
import * as notifTypes from 'actions/notifications/types'
import formSelector from './selectors/form'

function* getLandmarks({ payload }) {
  try {
    const statePage = yield select(state => state.landmarks.page)
    const stateLimit = yield select(state => state.landmarks.limit)
    const { limit = stateLimit, page = statePage } = payload

    const selector = yield formSelector('admin-landmarks-filter-form')
    const keyword = yield select(state => selector(state, 'admin_search_filter'))
    const place = yield select(state => selector(state, 'admin_place_filter'))
    const placePayload = place ? [place] : null
    const area = yield select(state => selector(state, 'admin_area_filter'))
    const published = yield select(state => selector(state, 'admin_filter_status'))
    const areaPayload = area && area.map(a => a.value)

    const { data } = yield call(api.get, '/landmarks', {
      params: {
        limit,
        page,
        keyword,
        placeKeywords: placePayload,
        areaKeywords: areaPayload,
        published
      },
    })

    yield delay(300) // to simulate tableloader
    console.log('getLandmarks', data)
    yield put({ type: types.FETCHED_LANDMARKS, response: data.landmarks })
  } catch (err) {
    console.warn(err)
    // if (response) console.error(response.message)
  }
}

function* getLandmark({ payload }) {
  try {
    const { data } = yield call(api.get, `/landmarks/${payload}`)

    console.log('getLandmark', data)
    yield put({ type: types.FETCHED_LANDMARK, landmark: data })
  } catch ({ response }) {
    if (response) console.log(response.message)
  }
}

function* createLandmark({ payload }) {
  try {
    const { area, landmark, published } = payload
    const areaPayload = area && area.map(a => a.value)

    const { data } = yield call(api.post, '/landmarks', {
      title: landmark,
      areas: areaPayload,
      is_published: published,
    })

    console.log('createLandmark', data)
    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: types.CREATED_LANDMARK })])
  } catch ({ response }) {
    if (response) console.log(response.data)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* editLandmark({ payload }) {
  try {
    const { landmarkId, area, landmark, published } = payload
    const areaPayload = area && area.map(a => a.value)

    const { data } = yield call(api.put, `/landmarks/${landmarkId}`, {
      title: landmark,
      areas: areaPayload,
      is_published: published,
    })

    console.log('editLandmark', data)
    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: types.EDITED_LANDMARK })])
  } catch ({ response }) {
    if (response) console.log(response.data)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* deleteLandmark({ payload }) {
  try {
    const { data } = yield call(api.delete, `/landmarks/${payload}`)
    yield all([
      put({ type: notifTypes.SUCCESS, ...data }),
      put({ type: types.DELETED_LANDMARK, id: payload }),
    ])
  } catch ({ response }) {
    if (response) console.log(response.message)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* fetchLandmarkPerArea({ payload }) {
  try {
    const { data } = yield call(api.get, `/landmarks/by-area-id/${payload}`)
    yield put({ type: types.FETCHED_LANDMARK_PER_AREA, landmarks: data.landmarks })
  } catch ({ response }) {
    if (response) console.log(response.message)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_LANDMARKS, getLandmarks)
  yield takeLatest(types.FETCH_LANDMARK_PER_AREA, fetchLandmarkPerArea)
  yield takeLatest(types.FETCH_LANDMARK, getLandmark)
  yield takeLatest(types.CREATE_LANDMARK, createLandmark)
  yield takeLatest(types.EDIT_LANDMARK, editLandmark)
  yield takeLatest(types.DELETE_LANDMARK, deleteLandmark)
}

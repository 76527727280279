import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/shared/modal'
import LoginForm from 'forms/auth/modal-version/login'
import RegisterForm from 'forms/auth/modal-version/register'

const ClientAuth = ({ toggle, initial = 'sign-in' }) => {
  const [selected, setSelected] = useState(initial)

  const renderForm = () => {
    switch (selected) {
      case 'sign-in':
        return <LoginForm />
      case 'sign-up':
        return <RegisterForm />
      default:
        return null
    }
  }

  return (
    <Modal
      onClose={toggle}
      className="modal-container bg-white w-11/12 md:max-w-xl m-auto rounded shadow-lg z-20"
    >
      <div className="modal-header flex justify-center">
        <div className="mx-auto flex justify-between lato-reg text-primary-lightgray">
          <p
            className={`text-base lg:text-2xl mx-5 cursor-pointer ${selected === 'sign-in' &&
              'text-primary-orange'}`}
            onClick={() => setSelected('sign-in')}
          >
            Sign in
          </p>
          <p
            className={`text-base lg:text-2xl mx-5 cursor-pointer ${selected === 'sign-up' &&
              'text-primary-orange'}`}
            onClick={() => setSelected('sign-up')}
          >
            Join
          </p>
        </div>
      </div>
      <div className="modal-body my-5">{renderForm()}</div>
    </Modal>
  )
}

ClientAuth.propTypes = {
  toggle: PropTypes.func,
  initial: PropTypes.string,
}

export default ClientAuth

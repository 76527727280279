import api from 'constants/api';
import { takeLatest, put } from 'redux-saga/effects';
import * as types from 'actions/merchants/types';
import * as notifTypes from 'actions/notifications/types'
// import * as spaceTypes from 'actions/spaces/types';

function* getMerchants() {
  try {
    const { data } = yield api.get('/api/merchant');
    yield put({
      type: types.FETCHED_MERCHANTS,
      merchants: data.map((merchant) => {
        return {
          value: merchant._id,
          text: merchant.last_name ? `${merchant.first_name} ${merchant.last_name}` : merchant.first_name,
        };
      }),
    });
  } catch (error) {
    const { response } = error;
    console.log(error);
    if (response) console.error(response.message);
  }
}

// utilized by merchant bookings
function* getVenues({ payload: id }) {
  try {
    const { data } = yield api.get(`/venues/options/${id}`)
    yield put({ type: types.FETCHED_MERCHANT_VENUES, venues: data.venues })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

export default function* () {
  // yield takeLatest(spaceTypes.FETCHED_SPACE, getMerchants);
  yield takeLatest(types.FETCH_MERCHANTS, getMerchants);
  yield takeLatest(types.FETCH_MERCHANT_VENUES, getVenues)
}

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoginDisclaimer from 'components/shared/disclaimers/login'
import Button from 'forms/shared/buttons/submit';
import authActions from 'actions/auth/actions'
import LoginForm from './form'

const Login = ({ authenticate, notification: { type } }) => (
  <div className="flex flex-col">
    {type === 'success' && location.reload()}
    <p className="text-base lg:text-2xl mx-5 cursor-pointer text-center">
      Welcome back! Good to see you again.
    </p>
    <div className="my-3">
      <LoginForm form="login-modal-form" onSubmit={values => authenticate(values)} />
      <div className="button-group mt-5">
        <Button formName="login-modal-form" className="bg-primary-orange hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white w-full py-4 rounded-lg">
          SIGN IN
        </Button>
      </div>
      <div className="flex justify-between items-center mt-5 relative flex-col xs:flex-row">
        <a
          href="/auth/google"
          className="text-white p-2 py-4 bg-secondary-google rounded-sm w-full xs:w-7/8 text-center rounded-lg text-base"
        >
          <i className="fa fa-google mr-3"></i> Log in with Google
        </a>
      </div>
    </div>
    <div className="my-3">
      <LoginDisclaimer />
    </div>
  </div>
)

Login.propTypes = {
  authenticate: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
}

const mapStateToProps = ({ notification }) => ({
  notification,
})

const mapDispatchToProps = {
  authenticate: authActions.modalAuthenticate,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login)

import React from 'react'
import PropTypes from 'prop-types'

const ThumbnailLoader = ({ className }) => (
  <div className={`ph-item h-full mb-0 p-0 ${className}`}>
    <div className="ph-picture absolute h-full"></div>
  </div>
)

ThumbnailLoader.propTypes = {
  className: PropTypes.string,
}

export default ThumbnailLoader

import { handleActions } from 'redux-actions';
import * as types from 'actions/leads/types';

export const initialState = {
  admin: {
    headers: [
      { name: 'ID', field: 'id' },
      { name: 'DATE', field: 'date' },
      { name: 'TYPE', field: 'type' },
      { name: 'CHECK-IN', field: 'checkIn' },
      { name: 'MERCHANT', field: 'merchant' },
      { name: 'CLIENT', field: 'client' },
      { name: 'CITY', field: 'city' },
      { name: 'MESSAGES', field: 'messages' },
      { name: 'STATUS', field: 'status' },
      { name: '', field: '' },
    ],
    data: [],
    fetching: false,
    page: 1,
    lastPage: 0,
    total: 0,
    limit: 10,
    details: {},
    search: {
      users: []
    },
  }
};

const locationReducer = handleActions({
  [types.ADMIN_FETCHED_LEADS](state, { inquiry, role }) {
    const newState = {
      ...state
    }
    newState[role] = { ...newState[role], ...inquiry }
    return newState
  },
  [types.FETCHED_LEAD_DETAILS](state, { data }) {
    return {
      ...state,
      details: data
    }
  },
  [types.EXPORT_LEADS](state) {
    return {
      ...state,
      fetching: true
    }
  },
  [types.EXPORTED_LEADS](state) {
    return {
      ...state,
      fetching: false
    }
  }
}, initialState);

export default locationReducer;

import { handleActions } from 'redux-actions';
import * as types from 'actions/messages/types';
import omit from 'lodash/omit';

export const initialState = {
  conversation: {
    list: [],
    info: {},
  },
  assignees: [],
  index: {
    totalUnread: 0,
    assignee: {}
  },
  search: {
    results: []
  }
};

const messageReducer = handleActions({
  [types.WS_INITIALIZED](state, action) {
    // TODO: notify to user when websocket is initilazied if needed
    console.log('WS_INITIALIZED', state, action)
    return state
  },
  [types.ADMIN_WS_INIT](state) {
    return state
  },
  [types.CONVERSATIONS_FETCHED](state, action) {
    return {
      ...state,
      conversation: {
        ...state.conversation,
        list: [...action.conversations] || [],
        ...omit(action, ['conversations', 'type']),
      },
    };
  },
  [types.CONVERSATIONS_ACCEPT](state, action) {
    return {
      ...state,
      conversation: {
        ...state.conversation,
        list: action.conversations,
        ...omit(action, ['conversations', 'type']),
      },
    };
  },
  [types.RECEIVE_MESSAGE](state, { payload }) {
    const listConversation = state.conversation.list
    const index = listConversation.findIndex(con => con.id === payload.conversation_id)
    // when conversation already exist
    if (index > -1) {
      const currentMessage = state.index
      const existConversation = listConversation[index]
      existConversation.lastMessage = payload.body.content
      listConversation.splice(index, 1)
      let totalUnread = state.index.totalUnread
      if (existConversation.is_read) {
        existConversation.is_read = false
        totalUnread += 1
      }
      console.log(existConversation.is_read)
      // when new message on current conversation
      if (currentMessage.messages && currentMessage.messages[0].conversation_id === payload.conversation_id) {
        return {
          ...state,
          conversation: {
            ...state.conversation,
            list: [{ ...existConversation }, ...listConversation],
          },
          index: {
            ...currentMessage,
            messages: [...currentMessage.messages, payload],
            totalUnread,
            is_read: false,
          },
          newConversation: null
        }
      }
      // when new message on other conversation
      return {
        ...state,
        conversation: {
          ...state.conversation,
          list: [existConversation, ...listConversation],
        },
        newConversation: null,
        index: {
          ...state.index,
          totalUnread
        }
      }
    }
    return {
      ...state,
      newConversation: payload.conversation_id,
      index: {
        ...state.index,
        totalUnread: state.index.totalUnread + 1
      }
    }
  },
  [types.MESSAGES_FETCHED](state, { index }) {
    return {
      ...state,
      index: {
        ...state.index,
        ...index
      }
    }
  },
  [types.SEARCH_FETCHED](state, { results }) {
    return {
      ...state,
      search: {
        results
      }
    }
  },
  [types.INQUIRY_FETCHED](state, { payload }) {
    const list = state.conversation.list
    const conversation = list.find(con => con.id === payload.conversation_id)
    if (conversation) {
      conversation.status = payload.status
      if (payload.status === 'accepted') {
        conversation.isAccept = true
      }
      return {
        ...state,
        conversation: {
          ...state.conversation,
          list
        }
      }
    }
    return state
  },
  [types.MESSAGES_SENT](state, { index }) {
    const listConversation = state.conversation.list
    const i = listConversation.findIndex(con => con.id === index.id)
    const currentConversation = listConversation[i]
    currentConversation.lastMessage = index.messages[index.messages.length - 1].body.content
    listConversation.splice(i, 1)
    return {
      ...state,
      conversation: {
        ...state.conversation,
        list: [currentConversation, ...listConversation]
      },
      index,
    }
  },
  [types.NEW_CONVERSATION](state, { conversation }) {
    return {
      ...state,
      conversation: {
        ...state.conversation,
        list: [conversation, ...state.conversation.list]
      }
    }
  },
  [types.MESSAGES_SEEN](state, { payload }) {
    const conversationId = payload.conversation_id
    const list = state.conversation.list
    const conversation = list.find(con => con.id === conversationId)
    if (conversation) {
      let totalUnread = state.index.totalUnread
      if (!conversation.is_read) {
        conversation.is_read = true
        totalUnread -= 1
      }
      return {
        ...state,
        conversation: {
          ...state.conversation,
          list
        },
        index: {
          ...state.index,
          is_read: true,
          totalUnread
        }
      }
    }
    return state
  },
  [types.TYPING_FETCHED](state, { payload }) {
    if (state.index.id !== payload.conversation_id) {
      return state
    }
    const type = payload.type
    let typings = state.index.typings || []
    if (type === 'on') {
      if (!typings.find(typing => typing.typingId === payload.typingId)) {
        typings = [...typings, payload]
      }
    } else {
      typings = typings.filter(typing => typing.typingId !== payload.typingId)
    }
    return {
      ...state,
      index: {
        ...state.index,
        typings
      }
    }
  },
  [types.TOTAL_UNREAD_FETCHED](state, { payload }) {
    return {
      ...state,
      index: {
        ...state.index,
        totalUnread: payload.total || 0
      }
    }
  }
}, initialState);

export default messageReducer;

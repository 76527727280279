import * as types from 'actions/variant/types';
import { handleActions } from 'redux-actions';

const initialState = {
  home: 'search',
};

const variantReducer = handleActions(
  {
    [types.SET_VARIANT](state, { payload }) {
      const { type, variantId } = payload
      return {
        ...state,
        home: type,
        google_optimize_variant: variantId
      };
    },
  },
  initialState,
);

export default variantReducer;

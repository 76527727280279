import { put, call, takeLatest, delay, all, select } from 'redux-saga/effects'
import moment from 'moment'
import FileDownload from 'js-file-download';

import * as types from 'actions/bookings/types'
import * as notifTypes from 'actions/notifications/types'
import api, { apiConfig } from 'constants/api'
import formSelector from './selectors/form'


const apiClient = apiConfig({ baseURL: '/api/client' })
const apiMerchant = apiConfig({ baseURL: '/api/merchant' })
const apiAdmin = apiConfig({ baseURL: '/api/admin' })

function* getClientRequests() {
  try {
    const { data } = yield call(apiClient.get, '/requests')

    yield delay(200)
    yield put({ type: types.FETCHED_CLIENT_REQUESTS, list: data.requests })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getClientToPay() {
  try {
    const { data } = yield call(apiClient.get, '/to-pay')

    yield delay(200)
    yield put({ type: types.FETCHED_CLIENT_TO_PAY, list: data.toPay })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getClientConfirmed() {
  try {
    const { data } = yield call(apiClient.get, '/confirmed')

    yield delay(200)
    yield put({ type: types.FETCHED_CLIENT_CONFIRMED, list: data.confirmed })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getClientArchive() {
  try {
    const { data } = yield call(apiClient.get, '/archives')

    yield delay(200)
    yield put({ type: types.FETCHED_CLIENT_ARCHIVE, list: data.archives })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* archiveClientRequest({ payload }) {
  try {
    yield call(api.delete, `/inquiries/${payload.id}`)
    yield all([
      put({ type: types.ARCHIVED_CLIENT_REQUEST, payload }),
      put({ type: notifTypes.SUCCESS, message: 'Archived Succesfully' }),
    ])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* cancelClientRequest({ payload: { id, reason } }) {
  try {
    yield call(api.put, `/inquiries/${id}`, {
      status: 'cancelled by client',
      declineReason: reason,
    })
    yield all([
      put({ type: types.CANCELLED_CLIENT_REQUEST, id }),
      put({ type: notifTypes.SUCCESS, message: 'Cancelled Succesfully' }),
    ])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* payClientProposal({ payload: { id: proposalId, user, paymentType, card, amount, currencyName, promo_code: promoCode } }) {
  try {
    console.log(`promoCode: ${promoCode}`)
    const { id, name, email } = user;

    const { data } = yield call(apiClient.post, `proposal/pay/${proposalId}`, {
      paymentType,
      card,
      amount,
      currency: currencyName,
      name,
      email,
      userId: id,
      promoCode
    })

    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({
      type: types.PAID_CLIENT_PROPOSAL,
      id: proposalId,
      paymentType
    })])
  } catch ({ response }) {
    yield all([put({ type: notifTypes.ERROR, ...response.data }), put({ type: types.PAID_CLIENT_PROPOSAL })])
  }
}

function* getClientProposalPaymentDetails({ payload }) {
  try {
    const { data } = yield call(apiClient.get, `/proposal/pay/${payload.id}`)

    yield put({ type: types.FETCHED_CLIENT_PROPOSAL_PAYMENT_DETAILS, details: data.proposal })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getClientProposalDetails({ payload }) {
  try {
    const { data } = yield call(apiClient.get, `/proposal/${payload.id}`)
    yield put({ type: types.FETCHED_CLIENT_PROPOSAL_DETAILS, details: data.proposal })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getMerchantLeads({ payload: { selectedVenue } }) {
  try {
    const { limit, page, sort } = yield select((state) => state.bookings.merchant.leads)
    const { data } = yield call(apiMerchant.get, '/leads', {
      params: { selectedVenue, limit, page, sortField: sort.field, sortOrder: sort.order },
    })

    yield delay(200)
    yield put({ type: types.FETCHED_MERCHANT_LEADS, list: data.leads, total: data.total })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getMerchantProposals({ payload: { selectedVenue } }) {
  try {
    const { limit, page, sort } = yield select((state) => state.bookings.merchant.proposals)
    const { data } = yield call(apiMerchant.get, '/proposals', {
      params: { selectedVenue, tab: 'proposals', limit, page, sortField: sort.field, sortOrder: sort.order },
    })

    yield delay(200)
    yield put({ type: types.FETCHED_MERCHANT_PROPOSALS, list: data.proposals, total: data.total })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getMerchantPaid({ payload: { selectedVenue } }) {
  try {
    const { limit, page, sort } = yield select((state) => state.bookings.merchant.paid)
    const { data } = yield call(apiMerchant.get, '/proposals', {
      params: { selectedVenue, tab: 'paid', limit, page, sortField: sort.field, sortOrder: sort.order },
    })

    yield delay(200)
    yield put({ type: types.FETCHED_MERCHANT_PAID, list: data.proposals, total: data.total })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getMerchantWonArchivedLeads({ payload: { selectedVenue } }) {
  try {
    const { page, limit, sort } = yield select((state) => state.bookings.merchant.archive.wonLeads)

    const { data } = yield call(apiMerchant.get, '/won-archived-leads', {
      params: {
        selectedVenue,
        page,
        limit,
        sortField: sort.field,
        sortOrder: sort.order,
      },
    })

    const { leads, total } = data
    yield delay(200)
    yield put({ type: types.FETCHED_MERCHANT_WON_ARCHIVED_LEADS, leads, total })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getMerchantLostArchivedInquiries({ payload: { selectedVenue } }) {
  try {
    const { page, limit, sort } = yield select((state) => state.bookings.merchant.archive.lostInquiries)

    const { data } = yield call(apiMerchant.get, '/lost-archived-inquiries', {
      params: {
        selectedVenue,
        page,
        limit,
        sortField: sort.field,
        sortOrder: sort.order,
      },
    })

    const { leads, total } = data
    yield delay(200)
    yield put({ type: types.FETCHED_MERCHANT_LOST_ARCHIVED_INQUIRIES, leads, total })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}


function* getMerchantLostArchivedTransactions({ payload: { selectedVenue } }) {
  try {
    const { page, limit, sort } = yield select((state) => state.bookings.merchant.archive.lostTransactions)

    const { data } = yield call(apiMerchant.get, '/lost-archived-transactions', {
      params: {
        selectedVenue,
        page,
        limit,
        sortField: sort.field,
        sortOrder: sort.order,
      },
    })

    const { leads, total } = data
    yield delay(200)
    yield put({ type: types.FETCHED_MERCHANT_LOST_ARCHIVED_TRANSACTIONS, leads, total })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}


function* getMerchantUnrepondedArchivedLeads({ payload: { selectedVenue } }) {
  try {
    const { page, limit, sort } = yield select((state) => state.bookings.merchant.archive.unrespondedLeads)

    const { data } = yield call(apiMerchant.get, '/unresponded-archived-leads', {
      params: {
        selectedVenue,
        page,
        limit,
        sortField: sort.field,
        sortOrder: sort.order,
      },
    })

    const { leads, total } = data
    yield delay(200)
    yield put({ type: types.FETCHED_MERCHANT_UNRESPONDED_ARCHIVED_LEADS, leads, total })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getMerchantPassportBookings({ payload: { selectedVenue } }) {
  try {
    const { limit, page, sort } = yield select((state) => state.bookings.merchant.passport)
    const { data } = yield call(apiMerchant.get, '/passport-bookings', {
      params: { selectedVenue, limit, page, sortField: sort.field, sortOrder: sort.order },
    })

    yield delay(200)
    yield put({ type: types.FETCHED_MERCHANT_PASSPORT_BOOKINGS, list: data.passportBookings, total: data.total })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* acceptMerchantLead({ payload }) {
  try {
    yield call(api.put, `/inquiries/${payload.id}`, {
      ...payload,
    })
    yield all([
      put({ type: types.ACCEPTED_MERCHANT_LEAD, payload }),
      put({ type: notifTypes.SUCCESS, message: 'Accepted Succesfully' }),
    ])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* declineMercahntLead({ payload: { id, reason } }) {
  try {
    yield call(api.put, `/inquiries/${id}`, {
      status: 'declined by space',
      declineReason: reason,
    })
    yield all([
      put({ type: types.DECLINED_MERCHANT_LEAD, id }),
      put({ type: notifTypes.SUCCESS, message: 'Declined the Lead Succesfully' }),
    ])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* merchantCancelSpace({ payload: { id, reason } }) {
  try {
    yield call(api.put, `/inquiries/${id}`, {
      status: 'cancelled by space',
      declineReason: reason,
    })
    yield all([
      put({ type: types.MERCHANT_CANCELLED_SPACE, id }),
      put({ type: notifTypes.SUCCESS, message: 'Cancelled the Lead Succesfully' }),
    ])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* fetchMerchantProposalOptions({ payload: { id } }) {
  try {
    const { data } = yield call(api.get, `spaces/merchant-options/${id}`)

    yield put({ type: types.FETCHED_MERCHANT_PROPOSAL_OPTIONS, details: { spaces: data.spaces } })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* fetchMerchantProposalDetails({ payload: { id } }) {
  try {
    const { data } = yield call(apiMerchant.get, `/proposals/${id}`)

    yield put({ type: types.FETCHED_MERCHANT_PROPOSAL_DETAILS, details: data.proposal })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* merchantSendProposal({ payload: { inquiryId, clientId, space: spaceId, schedule, startTime, endTime, amount, notes, noOfGuests, attachments } }) {
  try {
    const checkIn = moment(schedule[0], 'YYYY-MM-DD')
      .format('YYYY-MM-DD HH:mm:ss')
    const checkOut = moment(schedule[1], 'YYYY-MM-DD')
      .format('YYYY-MM-DD HH:mm:ss')
    const startTimeFormatted = moment(startTime)
      .format('HH:mm')
    const endTimeFormatted = moment(endTime)
      .format('HH:mm')
    const amountFormatted = Number(amount.replace(/[,]/, ''))

    yield call(apiMerchant.post, `/proposals`, {
      spaceId,
      clientId,
      inquiryId,
      checkIn,
      checkOut,
      startTime: startTimeFormatted,
      endTime: endTimeFormatted,
      noOfGuests,
      amount: amountFormatted,
      notes,
      attachments
    })

    yield put({ type: notifTypes.SUCCESS, message: 'Succesfully Sent Proposal' })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* merchantEditProposal({ payload: { proposalId, space: { value: spaceId }, schedule, startTime, endTime, amount, notes, noOfGuests, attachments } }) {
  try {
    const checkIn = moment(schedule[0], 'YYYY-MM-DD')
      .format('YYYY-MM-DD HH:mm:ss')
    const checkOut = moment(schedule[1], 'YYYY-MM-DD')
      .format('YYYY-MM-DD HH:mm:ss')
    const startTimeFormatted = moment(startTime)
      .format('HH:mm')
    const endTimeFormatted = moment(endTime)
      .format('HH:mm')
    const amountFormatted = Number.isInteger(amount) ? amount : Number(amount.replace(/[,]/, '')) // received value is number. submitted is string

    yield call(apiMerchant.put, `/proposals/${proposalId}`, {
      spaceId,
      checkIn,
      checkOut,
      startTime: startTimeFormatted,
      endTime: endTimeFormatted,
      noOfGuests,
      amount: amountFormatted,
      notes,
      attachments
    })

    yield put({ type: notifTypes.SUCCESS, message: 'Succesfully Edited Proposal' })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* archiveMerchantLead({ payload }) {
  try {
    yield call(api.delete, `/inquiries/${payload.id}`)
    yield all([
      put({ type: types.ARCHIVED_MERCHANT_LEAD, payload }),
      put({ type: notifTypes.SUCCESS, message: 'Archived Succesfully' }),
    ])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* merchantConfirmPassportBooking({ payload }) {
  try {
    yield call(apiMerchant.put, `/passport-bookings/${payload.id}`)
    yield all([
      put({ type: types.MERCHANT_CONFIRMED_PASSPORT_BOOKING, payload }),
      put({ type: notifTypes.SUCCESS, message: 'Confirmed Succesfully' }),
    ])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

// ADMIN

function* adminFetchBookings({ payload }) {
  try {
    const selector = yield formSelector('admin-bookings-filter-form')
    const searchBar = yield select(state => selector(state, 'admin_search_filter'))
    const places = yield select(state => selector(state, 'place_filter'))
    const placeIds = places ? places.map(place => place.value) : []
    const termFilter = yield select(state => selector(state, 'term_filter'))
    const terms = termFilter ? termFilter.map(term => term.value) : []
    const bookingStatusFilter = yield select(state => selector(state, 'booking_status_filter'))
    const statuses = bookingStatusFilter ? bookingStatusFilter.map(status => status.value) : []
    const transitionStatusFilter = yield select(state => selector(state, 'transaction_status_filter'))
    const transactionStatuses = transitionStatusFilter ? transitionStatusFilter.map(status => status.value) : []
    const paymentMethodFilter = yield select(state => selector(state, 'payment_method_filter'))
    const paymentMethods = paymentMethodFilter ? paymentMethodFilter.map(method => method.value) : []
    const merchantFilter = yield select(state => selector(state, 'merchant_filter'))
    const merchantIds = merchantFilter ? merchantFilter.map(merchant => merchant.value) : []
    const { limit, page } = payload
    const { data } = yield call(apiAdmin.get, '/transactions', {
      params: {
        size: limit,
        page,
        search_bar: searchBar,
        place_ids: placeIds,
        terms,
        statuses,
        transaction_statuses: transactionStatuses,
        payment_methods: paymentMethods,
        merchant_ids: merchantIds
      },
    })
    yield put({ type: types.ADMIN_FETCHED_BOOKINGS, data })
  } catch ({ response }) {
    if (response) console.warn(response.data)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* updateBooking({ payload }) {
  try {
    payload.total_amount = payload.amount
    payload.guests = payload.guest
    payload.check_in = moment(payload.checkIn)
      .format('YYYY-MM-DD')
    payload.check_out = moment(payload.checkOut)
      .format('YYYY-MM-DD')
    payload.starting_hour = moment(payload.startTime)
      .format('HH:mm:ss')
    payload.ending_hour = moment(payload.endTime)
      .format('HH:mm:ss')
    yield call(apiAdmin.put, `/transactions/${payload.id}`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success edit booking.' })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}

function* fetchBookingDetails({ payload }) {
  try {
    const { data } = yield call(apiAdmin.get, `/transactions/${payload}`)
    if (data.check_in) {
      const checkInDay = moment(data.check_in).format('YYYY-MM-DD');
      data.checkIn = moment(data.check_in).format('LL')
      data.startTime = data.starting_hour ? new Date(moment(`${checkInDay} ${data.starting_hour}`).format('YYYY-MM-DD HH:mm:ss')) : null
    }

    if (data.check_out) {
      const checkOutDay = moment(data.check_out).format('YYYY-MM-DD');
      data.checkOut = moment(data.check_out).format('LL')
      data.endTime = data.ending_hour ? new Date(moment(`${checkOutDay} ${data.ending_hour}`).format('YYYY-MM-DD HH:mm:ss')) : null
    }
    yield put({ type: types.ADMIN_FETCHED_BOOKING_DETAILS, data })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}

function* createBooking({ payload }) {
  try {
    console.log(payload)
    payload.check_in = moment(payload.checkIn)
      .format('YYYY-MM-DD')
    payload.check_out = moment(payload.checkOut)
      .format('YYYY-MM-DD')
    payload.starting_hour = moment(payload.startingHour)
      .format('HH:mm:ss')
    payload.ending_hour = moment(payload.endingHour)
      .format('HH:mm:ss')
    yield call(apiAdmin.post, `/transactions`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success create booking.' })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}


function* deleteBooking({ payload }) {
  try {
    yield call(apiAdmin.delete, `/transactions/${payload}`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success delete booking.' })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  }
}

function* adminSendProposalManual({ payload }) {
  try {
    yield call(apiAdmin.post, `transactions/${payload}/manual-send-proposal`)
    yield put({ type: notifTypes.SUCCESS, message: 'Success Send Proposal To User.' })
  } catch ({ response }) {
    console.log(response)
    yield put({ type: notifTypes.ERROR, message: response.message })
  }
}

function* adminSendConfirmPayment({ payload }) {
  try {
    yield call(apiAdmin.post, `transactions/${payload}/manual-send-confirm-payment`)
    yield put({ type: notifTypes.SUCCESS, message: 'Success Send Confirm Payment To User.' })
  } catch ({ response }) {
    console.log(response)
    yield put({ type: notifTypes.ERROR, message: response.message })
  }
}

function* exportBookings() {
  try {
    const response = yield call(apiAdmin.get, '/export-bookings', { responseType: 'arraybuffer' })
    FileDownload(response.data, 'bookings.xlsx')
    yield put({ type: types.EXPORTED_BOOKINGS })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error Export Bookings' })
  }
}


export default function* () {
  yield takeLatest(types.ADMIN_CREATE_BOOKING, createBooking)
  yield takeLatest(types.ADMIN_DELETE_BOOKING, deleteBooking)
  yield takeLatest(types.ADMIN_UPDATE_BOOKING, updateBooking)
  yield takeLatest(types.ADMIN_FETCH_BOOKING_DETAILS, fetchBookingDetails)
  yield takeLatest(types.FETCH_CLIENT_REQUESTS, getClientRequests)
  yield takeLatest(types.FETCH_CLIENT_TO_PAY, getClientToPay)
  yield takeLatest(types.ADMIN_FETCH_BOOKINGS, adminFetchBookings)
  yield takeLatest(types.FETCH_CLIENT_CONFIRMED, getClientConfirmed)
  yield takeLatest(types.FETCH_CLIENT_ARCHIVE, getClientArchive)
  yield takeLatest(types.ARCHIVE_CLIENT_REQUEST, archiveClientRequest)
  yield takeLatest(types.CANCEL_CLIENT_REQUEST, cancelClientRequest)
  yield takeLatest(types.FETCH_CLIENT_PROPOSAL_PAYMENT_DETAILS, getClientProposalPaymentDetails)
  yield takeLatest(types.PAY_CLIENT_PROPOSAL, payClientProposal)
  yield takeLatest(types.FETCH_MERCHANT_LEADS, getMerchantLeads)
  yield takeLatest(types.FETCH_MERCHANT_PROPOSALS, getMerchantProposals)
  yield takeLatest(types.FETCH_MERCHANT_PAID, getMerchantPaid)
  yield takeLatest(types.FETCH_MERCHANT_WON_ARCHIVED_LEADS, getMerchantWonArchivedLeads)
  yield takeLatest(types.FETCH_MERCHANT_LOST_ARCHIVED_INQUIRIES, getMerchantLostArchivedInquiries)
  yield takeLatest(types.FETCH_MERCHANT_LOST_ARCHIVED_TRANSACTIONS, getMerchantLostArchivedTransactions)
  yield takeLatest(types.FETCH_MERCHANT_UNRESPONDED_ARCHIVED_LEADS, getMerchantUnrepondedArchivedLeads)
  yield takeLatest(types.FETCH_MERCHANT_PASSPORT_BOOKINGS, getMerchantPassportBookings)
  yield takeLatest(types.ACCEPT_MERCHANT_LEAD, acceptMerchantLead)
  yield takeLatest(types.DECLINE_MERCHANT_LEAD, declineMercahntLead)
  yield takeLatest(types.MERCHANT_CANCEL_SPACE, merchantCancelSpace)
  yield takeLatest(types.FETCH_MERCHANT_PROPOSAL_OPTIONS, fetchMerchantProposalOptions)
  yield takeLatest(types.FETCH_MERCHANT_PROPOSAL_DETAILS, fetchMerchantProposalDetails)
  yield takeLatest(types.MERCHANT_SEND_PROPOSAL, merchantSendProposal)
  yield takeLatest(types.MERCHANT_EDIT_PROPOSAL, merchantEditProposal)
  yield takeLatest(types.ARCHIVE_MERCHANT_LEAD, archiveMerchantLead)
  yield takeLatest(types.GET_CLIENT_PROPOSAL_DETAILS, getClientProposalDetails)
  yield takeLatest(types.MERCHANT_CONFIRM_PASSPORT_BOOKING, merchantConfirmPassportBooking)
  yield takeLatest(types.ADMIN_SEND_PROPOSAL_MANUAL, adminSendProposalManual)
  yield takeLatest(types.ADMIN_SEND_CONFIRM_PAYMENT, adminSendConfirmPayment)
  yield takeLatest(types.EXPORT_BOOKINGS, exportBookings)
}

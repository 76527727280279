import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Fields from './fields'
import validate from './validator'

const RegisterForm = ({ placesOptions = [] }) => <Fields placesOptions={placesOptions} />

RegisterForm.propTypes = {
  placesOptions: PropTypes.array,
}

export default reduxForm({ validate })(RegisterForm)

import { handleActions } from 'redux-actions';
import * as types from 'actions/blogs/types';

export const initialState = {
  list: [],
};

const blogReducer = handleActions({
  [types.FETCHED_BLOGS](state, { blogs }) {
    return {
      ...state,
      list: blogs,
    };
  },

}, initialState);

export default blogReducer;

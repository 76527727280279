import React from 'react'
import PropTypes from 'prop-types'

const Location = ({
  stroke,
  fill,
  className = 'fill-current w-5 h-5 mr-2 absolute left-0 top-0',
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="13.3"
    height="16.916"
    viewBox="0 0 13.3 16.916"
  >
    <path
      id="Icon_material-location-on"
      data-name="Icon material-location-on"
      d="M14.15,3A6.645,6.645,0,0,0,7.5,9.65c0,4.987,6.65,10.266,6.65,10.266S20.8,14.637,20.8,9.65A6.645,6.645,0,0,0,14.15,3Zm0,9.025A2.375,2.375,0,1,1,16.525,9.65,2.376,2.376,0,0,1,14.15,12.025Z"
      transform="translate(-7.5 -3)"
      fill={fill}
      stroke={stroke}
    />
  </svg>
)

Location.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
}

export default Location

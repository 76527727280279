export const QUESTION_TYPES = {
  INPUT: 'input',
  TEXT_AREA: 'textArea',
  DROPDOWN: 'dropdown',
  RADIO_BUTTON: 'radioButton',
  OPENING_HOURS: 'openingHours',
  MAP: 'map',
  CHECKBOX: 'checkbox'
};

export const GROUP_Q_TYPE = {
  ONE_LINE_GROUP_Q: 'oneLineGroupedQ',
};

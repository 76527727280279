import { QUESTION_TYPES } from 'constants/question';
import { PER_12_HOUR_DROPDOWN } from 'constants/date-time';

export const getFeaturedAmenetityQuestions = amenityNumber => {
  return [
    {
      id: `feature_${amenityNumber}`,
      label: `Featured Amenity ${amenityNumber}`,
      placeholder: 'Type in an amenity',
      questionType: QUESTION_TYPES.INPUT,
      customQuestionComponentClass: 'mb-8',
    },
    // {
    //   id: `featuredAmenityRadio${amenityNumber}a`,
    //   label: `Amenity`,
    //   questionType: QUESTION_TYPES.RADIO_BUTTON,
    // },
    // {
    //   id: `featuredAmenityRadio${amenityNumber}b`,
    //   label: `Amenity`,
    //   questionType: QUESTION_TYPES.RADIO_BUTTON,
    // },
    // {
    //   id: `featuredAmenityRadio${amenityNumber}c`,
    //   label: `Amenity`,
    //   questionType: QUESTION_TYPES.RADIO_BUTTON,
    // },
    // {
    //   id: `featuredAmenityRadio${amenityNumber}d`,
    //   label: `Amenity`,
    //   questionType: QUESTION_TYPES.RADIO_BUTTON,
    // },
  ]
};

export const getOpeningHoursQuestions = (day, idx) => {
  return {
    id: `openingHours${idx}`,
    label: day,
    questionType: QUESTION_TYPES.OPENING_HOURS,
    name: 'openning',
    values: PER_12_HOUR_DROPDOWN,
    customClass: 'w-full mx-6',
  };
};

import React from 'react'
import PropTypes from 'prop-types'

const CheckBoxField = ({
  input,
  label,
  type,
  meta,
  // css classes
  errorClassName,
  groupClassName,
  inputClassName,
  inputErrorClassName,
  labelClassName,
  suffix,
  styles = {},
  defaultChecked,
}) => {
  return (
    <div className={groupClassName}>
      <label className={`radio-container ${labelClassName}`}>
        {label} {suffix}
        <input
          {...input}
          defaultChecked={defaultChecked}
          className={
            meta.touched && meta.error
              ? [inputClassName, inputErrorClassName].join(' ')
              : inputClassName
          }
          placeholder={label}
          type={type}
        />
        <span className="checkmark w-4 h-4 my-auto" style={styles}></span>
      </label>
      {meta.touched && meta.error && <p className={errorClassName}>{meta.error}</p>}
    </div>
  )
}

CheckBoxField.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  inputErrorClassName: PropTypes.string,
  suffix: PropTypes.node,
  styles: PropTypes.object,
  defaultChecked: PropTypes.bool,
}

export default CheckBoxField

import api from 'constants/api'
import { takeLatest, put, call, all, select } from 'redux-saga/effects'
import * as types from 'actions/general/types'
import * as notifTypes from 'actions/notifications/types'

function* sendContact({ payload }) {
  try {
    const { data } = yield call(api.post, 'countries/send-email', { ...payload })

    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: types.SENT_CONTACT })])
  } catch ({ response }) {
    if (response) console.error(response.data)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* contactUs({ payload }) {
  try {
    const countries = yield select(state => state.countries.list || [])
    payload.country_id = countries.find(c => c.value === payload.country)?.id
    yield call(api.post, '/contact-us-form', payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success! We will contact you soon.' })
  } catch (error) {
    const { response } = error
    if (response) console.error(response.data)
    yield put({ type: notifTypes.ERROR, message: 'Error submit' })
  }
}

export default function* () {
  yield takeLatest(types.SEND_CONTACT, sendContact)
  yield takeLatest(types.CONTACT_US, contactUs)
}

import { all, call, put, takeLatest } from 'redux-saga/effects';
import api, { apiConfig } from 'constants/api';
import { arrayPush, change } from 'redux-form';
import * as types from 'actions/uploader/types';
import * as notifTypes from 'actions/notifications/types';
import compressImage from 'utils/compressImage'
import imageCompression from 'browser-image-compression';


const upploadApi = apiConfig({
  baseURL: '/api',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

function* resizeImageFn(file) {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }

  const compressedFile = yield call(imageCompression, file, options);
  return compressedFile
}

function* getPresignedUrl(fileName, type, resize) {
  const uri = resize ? '/upload/get-presigned-url-image' : '/upload/get-presigned-url'
  const { data } = yield call(api.post, uri, {
    file_name: fileName,
    type
  })
  return data
}

function* uploadToAmz(url, file, type) {
  let toBeUploaded = file;
  if (type === 'image/png' || type === 'image/jpeg' || type === 'image/jpg') {
    toBeUploaded = yield call(resizeImageFn, file, type)
  }
  const blob = new Blob([toBeUploaded], { type })
  const amzConfig = {
    headers: {
      'Content-Type': type
    }
  };
  return yield call(apiConfig(amzConfig).put, url, blob)
}

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function* uploadBase64ToAws(data, fileName, type) {
  const preRes = yield call(getPresignedUrl, fileName, type, true)
  const filePreCompress = dataURLtoFile(data, fileName)
  yield call(uploadToAmz, preRes.url, filePreCompress, type)
  const distributeUrl = preRes.distribute_url.split('?')[0]
  console.log(`distributeUrl: ${distributeUrl}`)
  return distributeUrl
}

function* uploadBasic({ payload: { target, files = [], name, apiRoute = '/upload/' } }) {
  try {
    const form = new FormData();
    form.append(name, files)
    const { data } = yield call(upploadApi.post, apiRoute, form)

    yield all([
      put(data.attachment.map(datum => arrayPush(target, name, datum))),
      put({ type: types.UPLOADED_BASIC }),
    ]);
  } catch ({ response }) {
    yield all([
      put({ type: notifTypes.ERROR, ...response.data }),
      put({ type: types.UPLOADED_BASIC }),
    ])
  }
}

function* uploadImages({ payload }) {
  const { target, files } = payload;
  const form = new FormData();
  files && files.forEach((file) => {
    form.append('images', file);
  });
  const { data } = yield call(upploadApi.post, '/upload/image', form);
  yield all([
    ...data.files.map(file => put(arrayPush(target, 'images', file))),
    put({ type: types.UPLOADED_IMAGES, images: data.files }),
  ]);
}

function* uploadSpaceImages({ payload }) {
  try {
    const { files, spaceId } = payload
    const form = new FormData();
    form.append('spaceId', spaceId)
    yield all(files.map(function* (file) {
      const compressedFile = yield compressImage(500, file)
      form.append('images[]', compressedFile)

      return compressedFile
    }))
    const { data } = yield call(upploadApi.post, '/upload/space-images', form)
    console.log(data)
  } catch (error) {
    console.log(error)
  }
}

function* uploadAvatar({ payload }) {
  try {
    if (typeof File !== 'undefined') {
      console.log(payload.files)
      const [file] = payload.files
      const type = file.type
      const data = yield call(getPresignedUrl, file.name, type, true)
      yield call(uploadToAmz, data.url, file, type)
      const { target } = payload
      yield put(change(target, 'avatar', data.distribute_url.split('?')[0]))
    }
  } catch (error) {
    console.log(error)
  }
}


function* multipleUpload({ payload, resolve, reject }) {
  try {
    const { files, max = 7, resize } = payload
    const data = yield all(Array.from(files).slice(0, max).map(function* (file, index) {
      const type = file.type
      const name = file.name
      const presigned = yield call(getPresignedUrl, name, type, resize)
      yield call(uploadToAmz, presigned.url, file, type)
      return { path: presigned.distribute_url.split('?')[0], name, index }
    }))
    resolve(data)
  } catch (error) {
    reject(error)
  }
}

function* uploadEditedImage({ payload, resolve }) {
  try {
    console.log(payload)
    const uploaded = yield call(uploadBase64ToAws, payload.data, payload.name, 'image/png')
    resolve(uploaded)
  } catch (err) {
    console.log(err)
  }
}

export default function* () {
  yield takeLatest(types.UPLOAD_BASIC, uploadBasic)
  yield takeLatest(types.MULTIPLE_UPLOAD, multipleUpload)
  yield takeLatest(types.UPLOADING_IMAGES, uploadImages);
  yield takeLatest(types.UPLOADING_AVATAR, uploadAvatar);
  yield takeLatest(types.UPLOADING_SPACE_IMAGES, uploadSpaceImages);
  yield takeLatest(types.UPLOAD_EDITED_IMAGE, uploadEditedImage)
}

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputField from 'forms/shared/fields/input'
import SelectDropdownField from 'forms/shared/fields/dropdown'
import ContactField from 'forms/shared/fields/contact'
import CheckBoxField from 'forms/shared/fields/checkbox'

const checkBoxClass = `mr-2 shadow-sm rounded-none border text-gray-700 leading-tight focus:border-0 focus:outline-none focus:shadow-outline`
const checkBoxLabel = `block text-gray-700 text-sm`

const inputErrorClass = `border-red-500 leading-tight`

const SignupFields = ({ placesOptions = [] }) => (
  <>
    <Field
      type="text"
      name="firstName"
      groupClassName="my-4"
      placeHolder="First name"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type="text"
      name="lastName"
      groupClassName="my-4"
      placeHolder="Last name"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type="text"
      name="email"
      groupClassName="my-4"
      placeHolder="Email"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type="tel"
      name="phone"
      groupClassName="my-4"
      placeHolder="Contact Number"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={ContactField}
    />
    <Field
      type="dropdown"
      name="place"
      options={placesOptions}
      placeholder={'Location'}
      groupClassName="my-4"
      inputClassName="pl-2 pt-2 regular text-xs"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={SelectDropdownField}
    />
    <Field
      type="password"
      name={'password'}
      groupClassName="my-4"
      placeHolder="Password"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type="password"
      name={'passwordConfirmation'}
      groupClassName="my-4"
      placeHolder="Re-type password"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type={'checkbox'}
      name={'terms'}
      label={
        <span>
          I agree to the
          <a href="/terms-and-conditions" rel="noopener" target="blank" className="text-primary-orange mx-1">
            terms and conditions
          </a>
          and
          <a href="/privacy-policy" rel="noopener" target="blank" className="text-primary-orange mx-1">
            privacy policy
          </a>
          .
        </span>
      }
      groupClassName="mb-4 flex items-center justify-start pt-5"
      labelClassName={checkBoxLabel}
      inputClassName={checkBoxClass}
      inputErrorClassName={inputErrorClass}
      errorClassName="text-red-500 text-xs italic"
      component={CheckBoxField}
    />
  </>
);

SignupFields.propTypes = {
  placesOptions: PropTypes.array,
}

export default SignupFields

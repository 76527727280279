import { handleActions } from 'redux-actions'
import * as types from 'actions/brands/types'


const lineMinifier = (obj = {}) => {
  return {
    list: [],
    page: 1,
    lastPage: 0,
    total: 0,
    limit: 20,
    ...obj,
  }
}

const initialState = {
  admin: {
    headers: [
      { name: 'ID', field: 'id' },
      { name: 'Merchant Name', field: 'merchant_name' },
      { name: 'Country', field: 'country' },
      { name: 'Date Created', field: 'date_created' },
      { name: 'Owner', field: 'owner' }
    ],
    list: [
    ],
    ...lineMinifier()
  }
}

const brandsReducer = handleActions(
  {
    [types.FETCH_BRANDS](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_BRAND](state, { payload }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          createdBrand: true,
          brand_id: payload.premiumMerchant.id
        }
      }
    },
    [types.FETCHED_BRANDS](state, { list }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          list: list.data
        },
        fetching: false,
      }
    },
    [types.FETCHED_BRAND](state, { data }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          detail: data
        },
        fetching: false
      }
    },
    [types.CLIENT_FETCHED_BRAND](state, { data }) {
      return {
        ...state,
        client: {
          detail: data
        },
        fetching: false
      }
    }
  },
  initialState
)

export default brandsReducer

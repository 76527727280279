import api from 'constants/api'
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects'
import * as types from 'actions/spaceFunction/types'
import * as notifTypes from 'actions/notifications/types'
import formSelector from './selectors/form'

function* getSpaceFunctions({ payload }) {
  try {
    const statePage = yield select((state) => state.category.page)
    const stateLimit = yield select((state) => state.category.limit)
    const { limit = stateLimit, page = statePage } = payload

    const selector = yield formSelector('admin-space-functions-filter-form')
    const keyword = yield select((state) => selector(state, 'admin_search_filter'))
    const type = yield select((state) => selector(state, 'admin_type_filter'))
    let categories = yield select((state) => selector(state, 'admin_category_filter'))
    // type = type ? type.value : type
    categories = categories && categories.length ? categories.map((cat) => cat.value) : []

    const { data } = yield call(api.get, '/tags', {
      params: {
        limit,
        page,
        type,
        keyword,
        categories,
      },
    })

    yield delay(300) // to simulate tableloader
    console.log('getSpaceFunctions', data)
    yield put({ type: types.FETCHED_SPACE_FUNCTIONS, response: data.spaceFunctions })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* getSpaceBuildings({ payload }) {
  try {
    const { data } = yield call(api.get, `/building/options?place_id=${payload.spaceId}`)
    yield put({ type: types.FETCHED_SPACE_BUILDINGS, response: data })
  } catch (error) {
    console.log(error)
  }
}

function* createSpaceFunction({ payload }) {
  try {
    let { categories } = payload
    categories = categories && categories.length ? categories.map((cat) => cat.value) : []
    const { data } = yield call(api.post, '/tags', {
      ...payload,
      categories,
      is_published: payload.published,
    })

    console.log('createSpaceFunction', data)
    yield all([
      put({ type: notifTypes.SUCCESS, ...data }),
      put({ type: types.CREATED_SPACE_FUNCTION }),
    ])
  } catch ({ response }) {
    if (response) console.log(response.message)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* editSpaceFunction({ payload }) {
  try {
    let { categories } = payload
    categories = categories && categories.length ? categories.map((cat) => cat.value) : []
    const { data } = yield call(api.put, `/tags/${payload.id}`, {
      ...payload,
      categories,
      isPublished: payload.published,
    })

    console.log('editSpaceFunction', data)
    yield all([
      put({ type: notifTypes.SUCCESS, ...data }),
      put({ type: types.EDITED_SPACE_FUNCTION }),
    ])
  } catch ({ response }) {
    if (response) console.log(response.message)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* deleteSpaceFunction({ payload }) {
  try {
    const { data } = yield call(api.delete, `/tags/${payload}`)
    console.log('deleteSpaceFunction', data)
    yield all([
      put({ type: notifTypes.SUCCESS, ...data }),
      put({ type: types.DELETED_SPACE_FUNCTION, id: payload }),
    ])
  } catch ({ response }) {
    if (response) console.log(response.message)
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getSpaceFunction({ payload }) {
  try {
    const { data } = yield call(api.get, `/tags/${payload}`)
    yield put({ type: types.FETCHED_SPACE_FUNCTION, spaceFunction: data.spaceFunction })
  } catch (error) {
    console.log(error)
  }
}

function* fetchCategorySpaceFunction({ payload }) {
  try {
    const { data } = yield call(api.get, `/categories/${payload}/tags`)
    yield put({ type: types.FETCHED_CATEGORY_SPACE_FUNCTION, data })
  } catch (error) {
    console.log(error)
  }
}

export default function* () {
  yield takeLatest(types.FETCH_SPACE_FUNCTIONS, getSpaceFunctions)
  yield takeLatest(types.FETCH_CATEGORY_SPACE_FUNCTION, fetchCategorySpaceFunction)
  yield takeLatest(types.FETCH_SPACE_FUNCTION, getSpaceFunction)
  yield takeLatest(types.CREATE_SPACE_FUNCTION, createSpaceFunction)
  yield takeLatest(types.EDIT_SPACE_FUNCTION, editSpaceFunction)
  yield takeLatest(types.DELETE_SPACE_FUNCTION, deleteSpaceFunction)
  yield takeLatest(types.FETCH_SPACE_BUILDINGS, getSpaceBuildings)
}

import { handleActions } from 'redux-actions'
import * as types from 'actions/notifications/types'

export const initialState = {
  message: null,
  redirect: null,
  type: '',
}

const notificationReducer = handleActions(
  {
    [types.SUCCESS](state, { type = 'success', message }) {
      console.log('%c Success ', 'background: #48bb78; color: white', message)
      return {
        ...state,
        type,
        message,
      }
    },
    [types.ERROR](state, { type = 'error', message, payload }) {
      console.log('%s Error ', 'background: #dc3545; color: white', message)
      const { errors } = payload ?? []
      return {
        ...state,
        type,
        message: message ?? (payload?.message || ''),
        errors,
      }
    },
    [types.WARNING](state, { type = 'warning', message }) {
      console.log('%c Warning ', 'background: #ff8e2d; color: white', message)
      return {
        ...state,
        type,
        message,
      }
    },
    [types.REDIRECT](state, { type = 'redirect', message, redirect }) {
      console.log('%c Redirected to ', 'background: #4acfd9; color: white', redirect)
      return {
        ...state,
        type,
        message,
        redirect,
      }
    },
    [types.RESET](state) {
      return {
        ...state,
        type: '',
        message: null,
        redirect: null,
      }
    },
  },
  initialState
)

export default notificationReducer

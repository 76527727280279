export const FETCH_PASSPORT = 'fetchPassport'
export const FETCHED_PASSPORT = 'fetchedPassport'
export const FETCH_BOOKING_MEMBERS = 'fetchBookingMembers'
export const FETCHED_BOOKING_MEMBERS = 'fetchedBookingMembers'
export const FETCH_PASSPORT_SUBSCRIPTION_DETAILS = 'fetchPassportSubscriptionDetails'
export const FETCHED_PASSPORT_SUBSCRIPTION_DETAILS = 'fetchedPassportSubscriptionDetails'
export const DELETE_PASSPORT_SUBSCRIPTION = 'deletePassportSubscription'
export const DELETE_PASSPORT_INVITATION = 'deletePassportInvitation'
export const CLIENT_DELETE_PASSPORT_INVITATION = 'clientDeletePassportInvitation'

export const UPDATE_PASSPORT_SUBSCRIPTION = 'updatePassportSubscription'
export const SEND_PASSPORT_INVITE = 'sendPassportInvite'

export const SEARCH_PASSPORT = 'searchPassport';
export const SEARCHED_PASSPORT = 'searchedPassport';

export const SEARCH_PASSPORT_LOOKOUT = 'searchPassportLookOut'
export const SEARCHED_PASSPORT_LOOKOUT = 'searchedPassportLookOut'
export const SEARCH_PASSPORT_LOOKOUT_RESET = 'searchPassportLookOutReset'

export const FETCH_PASSPORT_BOOKINGS = 'fetchPassportBookings'
export const FETCHED_PASSPORT_BOOKINGS = 'fetchedPassportBookings'

export const FETCH_PASSPORT_PLAN_DETAILS = 'fetchPassportPlanDetails'
export const FETCHED_PASSPORT_PLAN_DETAILS = 'fetchedPassportPlanDetails'
export const FETCH_PASSPORT_PAYMENT_DETAIL = 'fetchPassportPaymentDetail'
export const FETCHED_PASSPORT_PAYMENT_DETAIL = 'fetchedPassportPaymentDetail'

export const PAY_PASSPPORT_SUBSCRIPTION = 'payPassportSubscription'
export const PAID_PASSPPORT_SUBSCRIPTION = 'paidPassportSubscription'

export const PASSPORT_CHECK_IN = 'pasportCheckIn'
export const PASSPORT_CANCEL = 'passportCancel'
export const PASSPORT_CANCELLED = 'passportCancelled'
export const PASSPORT_INVITE_CORPORATE = 'passportInviteCorporate'
export const PASSPORT_CORPORATE_REGIS = 'passportCorporateRegis'
export const PASSPORT_CORPORATE_REGIS_DONE = 'passportCorporateRegisDone'

export const EXPORT_PASSPORTS = 'exportPassports'
export const EXPORTED_PASSPORTS = 'exportedPassports'


import { apiConfig } from 'constants/api'
import { call, delay, put, takeLatest } from 'redux-saga/effects'
import * as types from 'actions/brands/types'
import * as notifTypes from 'actions/notifications/types'

const apiAdmin = apiConfig({ baseURL: '/api/admin' })
const publicApi = apiConfig({ baseURL: '/api' })

function* searchBrands({ payload, resolve }) {
  try {
    const { data } = yield call(apiAdmin.get, `/brands/search`, {
      params: payload,
    })
    resolve(data)
  } catch ({ message }) {
    console.error(message)
  }
}

function* fetchBrands() {
  try {
    const { data } = yield call(apiAdmin.get, '/brands')
    yield delay(200)
    console.log(data, 'fetchBrands')
    yield put({ type: types.FETCHED_BRANDS, list: data })
  } catch ({ response }) {
    console.log(response)
  }
}

function* createBrand({ payload }) {
  try {
    const { data } = yield call(apiAdmin.post, 'brands', payload)
    console.log(data)
    yield put({ type: notifTypes.SUCCESS, message: 'Create Brand Succesfully' })
    yield put({ type: types.CREATED_BRAND, payload: data })
  } catch ({ response }) {
    console.log(response)
    yield put({ type: notifTypes.ERROR, message: response.statusText })
  }
}

function* fetchBrand({ payload }) {
  try {
    const { data } = yield call(apiAdmin.get, `brands/${payload.brandId}`)
    yield put({ type: types.FETCHED_BRAND, data })
  } catch (err) {
    console.log(err)
  }
}

function* clientFetchBrand({ payload }) {
  try {
    const { data } = yield call(publicApi.get, `brands/${payload.brandId}`)
    yield put({ type: types.CLIENT_FETCHED_BRAND, data })
  } catch (err) {
    console.log(err)
  }
}

function* updateBrand({ payload }) {
  try {
    yield call(apiAdmin.put, `brands/${payload.id}`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Update Succesfully' })
    yield put({ type: types.FETCH_BRAND, payload: { brandId: payload.id } })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: response.statusText })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_BRANDS, fetchBrands)
  yield takeLatest(types.SEARCH_BRAND, searchBrands)
  yield takeLatest(types.CREATE_BRAND, createBrand)
  yield takeLatest(types.FETCH_BRAND, fetchBrand)
  yield takeLatest(types.UPDATE_BRAND, updateBrand)
  yield takeLatest(types.CLIENT_FETCH_BRAND, clientFetchBrand)
}

import { put, call, takeLatest, select, all } from 'redux-saga/effects'
import * as types from 'actions/auth/types'
import * as notifTypes from 'actions/notifications/types'
import authActions from 'actions/auth/actions'
import Cookies from 'js-cookie'
import api, { apiConfig } from 'constants/api'

const apiClient = apiConfig({ baseURL: '/api/client' })

function* Login({ payload }) {
  try {
    const { data } = yield call(api.post, '/auth', { ...payload })
    Cookies.set('credentials', { type: data.type, token: data.token })
    yield put(authActions.authenticated({ token: data.token }))
  } catch (error) {
    console.log(error.response)
  }
}

function* getUserInfo() {
  try {
    const stateToken = yield select((state) => state.auth.token)
    const credentials = yield Cookies.getJSON('credentials')
    const token = credentials ? credentials.token : null
    const bearerToken = yield !stateToken ? token : stateToken

    if (bearerToken) {
      const apiWithHeaders = apiConfig({
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      })
      const { data } = yield call(apiWithHeaders.get, '/auth/me')
      yield all([put({ type: types.PROFILE_STORED, user: data })])
    }
  } catch (error) {
    console.log('ERROR GET USER', error)
  }
}

function* changeProfile({ payload }) {
  try {
    const { first_name: firstName, last_name: lastName } = payload
    const { data } = yield call(api.put, `users/${payload.id}`, {
      ...payload,
      firstName,
      lastName,
    })
    yield all([put({ type: notifTypes.SUCCESS, ...data }), put({ type: types.PROFILE_UPDATED })])
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* modalAuthenticate({ payload }) {
  try {
    const { data } = yield call(api.post, '/login-modal', { ...payload })
    yield put({ type: notifTypes.SUCCESS, ...data })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* modalRegister({ payload }) {
  try {
    const { data } = yield call(api.post, '/register-modal', { ...payload })
    yield put({ type: notifTypes.SUCCESS, ...data })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* changePassword({ payload }) {
  try {
    console.log(payload) // @TODO
    const { data } = yield call(api.post, '/change-password', { ...payload })

    yield put({ type: notifTypes.SUCCESS, ...data })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* selectAsFavorite({ payload: { id, isFave } }) {
  try {
    if (isFave) {
      yield call(apiClient.post, '/favorites', { id })
      yield put({ type: types.ADD_FAVORITE, id })
    } else {
      yield call(apiClient.delete, `/favorites/${id}`)
      yield put({ type: types.REMOVE_FAVORITE, id })
    }
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* getUserFavorites() {
  try {
    const { data } = yield call(apiClient.get, '/favorites')
    const categoryResponse = yield call(api.get, '/categories')
    let categories = categoryResponse.data.categories.data.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    const favoriteCategories = data.results.map((item) => item.category_id) ?? []

    categories = categories.filter((item) => favoriteCategories.includes(item.id))
    categories = categories.map((item) => ({ id: item.id, name: item.name }))

    yield put({
      type: types.FETCHED_USER_FAVORITES,
      favoritesData: { favorites: data.results, categories },
    })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

export default function* () {
  yield takeLatest(types.AUTHENTICATE, Login)
  yield takeLatest(types.AUTHENTICATED, getUserInfo)
  yield takeLatest(types.UPDATE_PROFILE, changeProfile)
  yield takeLatest(types.MODAL_AUTHENTICATE, modalAuthenticate)
  yield takeLatest(types.MODAL_REGISTER, modalRegister)
  yield takeLatest(types.CHANGE_PASSWORD, changePassword)
  yield takeLatest(types.SELECT_AS_FAVORITE, selectAsFavorite)
  yield takeLatest(types.FETCH_USER_FAVORITES, getUserFavorites)
}

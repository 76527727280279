import { handleActions } from 'redux-actions'
import * as types from 'actions/locations/types'

export const initialState = {
  city: '',
  country: '',
  loading: true,
}

const locationReducer = handleActions(
  {
    [types.CURRENT_CITY](state, { city, country, id, status, slug }) {
      return {
        ...state,
        city,
        country,
        id,
        status,
        slug,
        loading: false,
      }
    },
  },
  initialState
)

export default locationReducer

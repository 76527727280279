import api from 'constants/api';
import { change, arrayRemove } from 'redux-form';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from 'actions/images/types';
import getImage from 'utils/getImage';
import { scaleX, scaleY, cropXvalue, cropYvalue, cropWidthValue, cropHeightValue } from 'utils/cropHelpers';
import qs from 'utils/queryString';
import formSelector from './selectors/form';

function* updateImageParams({ payload }) {
  try {
    const { crop } = payload;
    const selector = formSelector(payload.target);
    const image = payload.imageProps;
    const images = yield select(state => selector(state, 'images'));
    const { naturalWidth, naturalHeight, width, height } = yield image;
    const scaleXvalue = scaleX(naturalWidth, width);
    const scaleYvalue = scaleY(naturalHeight, height);
    const left = cropXvalue(crop.x, scaleXvalue);
    const top = cropYvalue(crop.y, scaleYvalue);
    const cropWidth = cropWidthValue(crop.width, scaleXvalue);
    const cropHeight = cropHeightValue(crop.height, scaleYvalue);
    const url = {
      cropWidth,
      cropHeight,
      left,
      top,
    };
    console.log(url);
    const imageToUpdate = [{ ...images.find(image => image.filename === payload.filename), url: getImage(`${payload.filename}?${qs(url)}`) }];
    const updatedImages = [...images.map(image => imageToUpdate.find(img => img.filename === image.filename) || image)];
    yield put(change(payload.target, 'images', updatedImages));
  } catch (error) {
    console.log(error);
  }
}

function* removeImage({ payload }) {
  try {
    yield put(arrayRemove(payload.form, 'images', payload.index));
    const filename =
      /\/api\/v2\/image\//.test(payload.filename)
        ? payload.filename.replace(/\/api\/v2\/image\//, '') : payload.filename;
    console.log(filename);
    const { data } = yield call(api.delete, `/v2/image/${filename}`);
    console.log(data);
  } catch (error) {
    console.log(error);
  }
}

export default function* () {
  yield takeLatest(types.GET_CROP_VALUES, updateImageParams);
  yield takeLatest(types.DELETE_IMAGE, removeImage);
}

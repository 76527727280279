import { handleActions } from 'redux-actions'
import * as types from 'actions/search/types'

export const initialState = {
  options: {},
  pace: 'fast',
  list: [],
  budget: [],
  tags: [],
  page: 1,
  lastPage: 1,
  total: 0,
  limit: 4,
  fetching: false,
  searchBox: {
    results: [],
    fetching: false,
  },
  brands: [],
}

const searchReducer = handleActions(
  {
    [types.SEARCH_OPTIONS_FETCHED](state, { options }) {
      return {
        ...state,
        options,
      }
    },
    [types.SEARCH_PUBLIC](state) {
      return {
        ...state,
        list: [],
        tags: [],
        budget: [],
        fetching: true,
      }
    },
    [types.SEARCHED_PUBLIC](
      state,
      {
        pace,
        list,
        page,
        limit,
        total,
        lastPage,
        tags = [],
        budget = [],
        brands = [],
        map,
        placeInfo,
      }
    ) {
      return {
        ...state,
        pace,
        list,
        page,
        limit,
        total,
        lastPage,
        budget,
        tags,
        fetching: false,
        brands,
        map,
        placeInfo,
      }
    },
    [types.SEARCH_LOOKOUT](state) {
      return {
        ...state,
        searchBox: { ...state.searchBox, results: [], fetching: true },
      }
    },
    [types.SEARCHED_LOOKOUT](state, { results }) {
      return {
        ...state,
        searchBox: { ...state.searchBox, results, fetching: false },
      }
    },
    [types.SEARCH_LOOKOUT_RESET](state) {
      return {
        ...state,
        searchBox: { ...state.searchBox, results: [] },
      }
    },
  },
  initialState
)

export default searchReducer

import React from 'react'
import PropTypes from 'prop-types'

const VenuePrice = ({ categorySummaries }) => {
  const renderCategories = () => {
    return categorySummaries.map((category, key) => {
      const { category: title, price, capacity } = category
      return (
        <div className="w-full flex py-1" key={key}>
          <div className="lato-reg w-full pr-2 text-left">
            <p className="leading-none">{title}</p>
            <p className="text-xs text-gray-600">{capacity}</p>
          </div>
          <div className="lato-reg w-full pl-2 text-right">{price}</div>
        </div>
      )
    })
  }
  return (
    <div className="space-profile-body flex flex-col justify-between pt-3 border-t">
      {renderCategories()}
    </div>
  )
}

VenuePrice.propTypes = {
  categorySummaries: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      price: PropTypes.string,
      capacity: PropTypes.string,
    })
  ),
}

export default VenuePrice

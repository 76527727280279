import api from 'constants/api';
import { takeLatest, put, call } from 'redux-saga/effects';
import * as types from 'actions/blogs/types';

function* getCityBlogs({ payload }) {
  try {
    const { data } = yield call(api.post, 'blogs/topic', { topic: payload })
    yield put({ type: types.FETCHED_BLOGS, blogs: data })
  } catch ({ reponse }) {
    console.log('Error.getCityBlogs', reponse.message)
  }
}
function* getRecentBlogs() {
  try {
    const { data } = yield call(api.get, 'blogs/recent')
    yield put({ type: types.FETCHED_BLOGS, blogs: data })
  } catch ({ reponse }) {
    console.log('Error.getCityBlogs', reponse.message)
  }
}

export default function* () {
  yield takeLatest(types.FETCH_CITY_BLOGS, getCityBlogs);
  yield takeLatest(types.FETCH_RECENT_BLOGS, getRecentBlogs)
}

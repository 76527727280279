import { apiConfig } from 'constants/api'
import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import * as types from 'actions/promocodes/types'
import * as notifTypes from 'actions/notifications/types'

const startCase = require('lodash/startCase')

const apiAdmin = apiConfig({ baseURL: '/api/admin' })
const apiClient = apiConfig({ baseURL: '/api' })

function* getPromoCodes({ payload }) {
  try {
    const statePage = yield select(state => state.promocodes.page)
    const stateLimit = yield select(state => state.promocodes.limit)
    const { limit = stateLimit, page = statePage } = payload

    const { data } = yield call(apiAdmin.get, '/promotion', {
      params: {
        limit,
        page,
      },
    });
    yield delay(300) // to simulate tableloader
    yield put({ type: types.FETCHED_PROMOCODES, response: data })
  } catch ({ response }) {
    if (response) console.warn(response.message)
  }
}

function convertToFormData(data) {
  const campaignDate = [data.from_date, data.to_date]
  return {
    id: data.id,
    campaign_name: data.name,
    campaign_date: campaignDate,
    is_ad_image: data.is_ad_image === 1,
    is_promo_code: data.is_promo_code === 1,
    promo: {
      type: data.promotion?.type,
      code: data.promotion?.code,
      discount_type: data.promotion.discount_type,
      number_of_pass: data.promotion.amount,
      plan: data.promotion.plan.split(',')
        .map(p => {
          return {
            value: p,
            label: startCase(p)
          }
        }),
      cities: data.promotion.places ? data.promotion.places.map(p => {
        return {
          label: p.name,
          value: p.slug
        }
      }) : [],
      space_categories: data.promotion.categories ? data.promotion.categories.map(c => {
        return {
          label: c.name,
          value: c.slug
        }
      }) : []
    }
  }
}

function* getPromoCode({ payload }) {
  try {
    const { data } = yield call(apiAdmin.get, `/promotion/${payload}`)
    yield put({ type: types.FETCHED_PROMOCODE, promocode: convertToFormData(data) })
  } catch ({ response }) {
    if (response) console.warn(response.message)
  }
}

function convertFormData(payload) {
  const promo = payload.promo || {}
  const campaignDate = payload.campaign_date || []
  return {
    ...payload,
    campaign_name: payload.campaign_name,
    from_date: campaignDate.length > 0 ? campaignDate[0] : '',
    to_date: campaignDate.length > 0 ? campaignDate[1] : '',
    is_ad_image: payload.is_ad_image || false,
    is_promo_code: payload.is_promo_code || false,
    promo: {
      type: promo.type,
      code: promo.code,
      cities: promo.cities?.map(c => c.value) || [],
      space_categories: promo.space_categories?.map(sc => sc.value) || [],
      discount_type: promo.discount_type,
      number_of_pass: promo.number_of_pass,
      plan: promo.plan?.map(p => p.value) || []
    }
  }
}

function* createPromoCode({ payload }) {
  try {
    const { data } = yield call(apiAdmin.post, 'promotion', convertFormData(payload))
    yield put({ type: notifTypes.SUCCESS, message: `Create Promotion Successfully ${data.message}` })
    console.log(`todo push successfully ${data.promotion.id}`)
    yield put({ type: types.CREATED_PROMOCODE, promoCodeId: data.promotion.id })
  } catch ({ response }) {
    if (response) console.warn(response.message)
  }
}

function* editPromoCode({ payload }) {
  try {
    const { data } = yield call(apiAdmin.put, `promotion/${payload.id}`, convertFormData(payload))
    yield put({ type: notifTypes.SUCCESS, message: `Update Promotion Successfully ${data.message}` })
    yield put({ type: types.EDITED_PROMOCODE })
  } catch ({ response }) {
    if (response) console.warn(response.message)
  }
}

function* getPromoCodeOptions() {
  try {
    yield put({ type: types.FETCHED_PROMOCODE_OPTIONS })
  } catch ({ response }) {
    if (response) console.warn(response.message)
  }
}

function* deletePromotionCode({ payload }) {
  try {
    yield call(apiAdmin.delete, `promotion/${payload}`)
    yield put({ type: notifTypes.SUCCESS, message: `Delete Promotion Successfully` })
    yield put({ type: types.FETCH_PROMOCODES, payload })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: `Fail Delete Promo ${payload}` })
  }
}

function* clientApplyCode({ payload }) {
  console.log(`todo check promotion code: ${JSON.stringify(payload)}`)
  try {
    console.log(payload)
    payload.transactionId = payload.id
    payload.promotionCode = payload.promoCode
    const { data } = yield call(apiClient.post, 'promotion/check-promotion', payload)
    const valid = data.valid
    if (!valid) {
      yield put({ type: notifTypes.ERROR, message: data.message })
    }
    yield put({ type: types.CLIENT_APPLIED_CODE, data })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: `Fail Check Promo ${payload}` })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_PROMOCODES, getPromoCodes)
  yield takeLatest(types.FETCH_PROMOCODE, getPromoCode)
  yield takeLatest(types.CREATE_PROMOCODE, createPromoCode)
  yield takeLatest(types.EDIT_PROMOCODE, editPromoCode)
  yield takeLatest(types.FETCH_PROMOCODE_OPTIONS, getPromoCodeOptions)
  yield takeLatest(types.DELETE_PROMOTION_CODE, deletePromotionCode)
  yield takeLatest(types.CLIENT_APPLY_CODE, clientApplyCode)
}

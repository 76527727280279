import api from 'constants/api';
import { takeLatest, put, call } from 'redux-saga/effects';
import * as types from 'actions/countries/types';

function* getCountries() {
  try {
    const { data } = yield call(api.get, '/countries')
    yield put({ type: types.FETCHED_COUNTRIES, list: data })
  } catch ({ reponse }) {
    console.log('Error.getCountries', reponse.message)
  }
}

function* getCountryOptions() {
  try {
    const { data } = yield call(api.get, '/countries')

    yield put({ type: types.FETCHED_COUNTRY_OPTIONS, options: data })
  } catch (err) {
    console.warn(err)
  }
}

function* getCountryCities({ payload }) {
  try {
    const { data } = yield call(api.get, `/countries/${payload}/cities`)
    yield put({ type: types.FETCHED_COUNTRY_CITIES, data: { countryId: payload, list: data } })
  } catch (err) {
    console.warn(err)
  }
}

function* getCountriesWithCities() {
  try {
    const { data } = yield call(api.get, '/countries/cities');
    const listWithCities = data.map(({ name: label, slug: value, cities }) => ({ label, value, cities: cities.map(c => ({ label: c, value: c })) }));
    yield put({ type: types.FETCHED_COUNTRIES_WITH_CITIES, listWithCities });
  } catch ({ response }) {
    console.error('Error.getCountriesWithCities', response.message);
  }
}

export default function* () {
  yield takeLatest(types.FETCH_COUNTRIES, getCountries);
  yield takeLatest(types.FETCH_COUNTRY_CITIES, getCountryCities);
  yield takeLatest(types.FETCH_COUNTRIES_WITH_CITIES, getCountriesWithCities);
  yield takeLatest(types.FETCH_COUNTRY_OPTIONS, getCountryOptions);
}

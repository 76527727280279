import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Image from 'react-image'
import LazyLoad from 'react-lazyload'
import NoImage from 'assets/images/no-image.jpg'
import ThumbnailLoader from 'components/shared/loaders/thumb'
import startCase from 'lodash/startCase'
import ResizeImage from 'utils/resizeImage'

const Arrow = ({ onClick, isNext }) => {
  if (isNext) {
    return (
      <div className="w-auto h-full absolute right-0 bottom-0 top-0 z-20 table icon">
        <div className="table-cell align-middle h-auto">
          <div
            onClick={onClick}
            className="icon-next w-7 h-7 bg-white text-center table rounded mr-3 invisible"
          >
            <i
              className="text-gray-600 fa fa-chevron-right table-cell align-middle"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="w-auto h-full absolute bottom-0 top-0 left-0 z-20 table icon">
      <div className="table-cell align-middle h-auto">
        <div
          onClick={onClick}
          className="icon-prev w-7 h-7 bg-white text-center table rounded ml-3 invisible"
        >
          <i
            className="text-gray-600 fa fa-chevron-left table-cell align-middle"
            aria-hidden="true"
          ></i>
        </div>
      </div>
    </div>
  )
}

Arrow.propTypes = {
  onClick: PropTypes.func,
  isNext: PropTypes.bool,
}

const ImageSlider = ({ className, images, onClick, w }) => {
  const slider = useRef()
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dotsClass: 'slick-dots absolute bottom-0 py-2',
    nextArrow: (
      <Arrow
        isNext
        onClick={() => {
          if (slider && slider.current) {
            slider.current.slickNext()
          }
        }}
      />
    ),
    prevArrow: (
      <Arrow
        onClick={() => {
          if (slider && slider.current) {
            slider.current.slickPrev()
          }
        }}
      />
    ),
  }

  return (
    <Slider ref={slider} className={`${className} space-card stretch-list`} {...settings}>
      {images ? (
        images.map((image, key) => {
          let alt = image.alt
          if (!alt) {
            alt = image.path
              ? startCase(image.path.split('/')[image.path.split('/').length - 1].split('.')[0])
              : `No image`
          }
          return key !== 0 ? (
            <LazyLoad className="h-full" offset={100} placeholder={<ThumbnailLoader/>} key={key}>
              <Image
                layout="responsive"
                width="337"
                height="211"
                onClick={onClick}
                src={[ResizeImage(image.path, { w }), NoImage]}
                alt={alt}
                className="rounded object-cover h-full w-full"
                loader={<ThumbnailLoader/>}
              />
            </LazyLoad>
          ) : (
            <Image
              key={key}
              onClick={onClick}
              src={[ResizeImage(image.path, { w }), NoImage]}
              alt={alt}
              className="rounded object-cover h-full w-full"
              loader={<ThumbnailLoader/>}
            />
          )
        })
      ) : (
        <Image
          onClick={onClick}
          src={[NoImage]}
          alt="No image"
          className="rounded object-cover h-full w-full"
          loader={<ThumbnailLoader/>}
        />
      )}
    </Slider>
  )
}

ImageSlider.propTypes = {
  className: PropTypes.string,
  images: PropTypes.array,
  onClick: PropTypes.func,
  w: PropTypes.number,
}

export default ImageSlider

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { submit } from 'redux-form'

const Button = ({ className, formName, dispatch, type = 'submit', children, isLoad = false }) => {
  return <button className={className} type={type} onClick={() => !isLoad && dispatch(submit(formName))}>{children}</button>
}

Button.propTypes = {
  formName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  type: PropTypes.string,
  isLoad: PropTypes.bool
}

export default connect(null)(Button)

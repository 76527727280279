const getLocation = () => {
  try {
    const geoLocation = navigator.geolocation
    return new Promise((resolve, reject) => {
      geoLocation.getCurrentPosition(resolve, reject, { timeout: 3000 })
    })
  } catch (error) {
    return error
  }
}

export default getLocation

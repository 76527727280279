import React, { useEffect } from 'react'
import Card from 'components/shared/card/vertical'
import venueActions from 'actions/venues/actions'

import PropTypes from 'prop-types'
import LoadingOverlay from 'components/shared/loaders/loading-overlay'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Logo404 } from 'constants/imageMapping'
import ResponseStatus from './response-status'

const NotFound = ({
  locationStatus,
  coworkingSpaces,
  locationSlug,
  loading,
  servicedOffices,
  fetchVenueList,
  cityId,
}) => {
  useEffect(() => {
    if (locationStatus) {
      fetchVenueList({ categoryId: 6, cityId, page: 'page404_coworking_spaces', limit: 3 })
      fetchVenueList({ categoryId: 5, cityId, page: 'page404_serviced_offices', limit: 6 })
    }
  }, [locationStatus])

  return (
    <ResponseStatus code={404}>
      <LoadingOverlay loading={loading}>
        <div className="container pt-36">
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-1 flex-col justify-center pb-6 md:pb-0">
              <p className="py-2">
                <span className="text-secondary-greynotfound lato-bold pr-2">404</span>
                <span className="text-secondary-greypricetype">Page not found</span>
              </p>
              <p>
                Unfortunately the request space is no longer available. Take a look at the Long Term
                Office Space and Short Term Workspaces in your city
              </p>
            </div>
            <div className="flex flex-1 items-center justify-center">
              <img className="w-auto inline-block" src={Logo404} alt="404 logo"/>
            </div>
          </div>
          <section className="pt-20">
            <h2 className="text-2xl lato-reg text-center mb-4 mx-auto">Serviced Offices</h2>
            <div className="flex flex-wrap items-stretch mx--15px">
              {servicedOffices.map((venue) => (
                <div key={venue.id} className="w-full md:w-1/2 xl:w-1/3 mb-6 py-3 px-15px">
                  <Card data={venue}/>
                </div>
              ))}
            </div>
            <div className="text-center pb-6">
              <Link
                to={`${locationSlug}/serviced-office`}
                className="inline-block text-center hover:bg-secondary-darkorange w-72 transition lg duration-300 ease-in-out whitespace-no-wrap lg:mt-3 xl:mt-0 text-white px-1 py-4 rounded-lg bg-primary-orange focus:outline-none"
              >
                See 300 more office spaces
              </Link>
            </div>
          </section>
          <section>
            <h2 className="text-2xl lato-reg text-center mb-4 mx-auto">Coworking Spaces</h2>
            <div className="flex flex-wrap items-stretch mx--15px">
              {coworkingSpaces.map((venue) => (
                <div key={venue.id} className="w-full md:w-1/2 xl:w-1/3 mb-6 py-3 px-15px">
                  <Card data={venue}/>
                </div>
              ))}
            </div>
            <div className="text-center pb-12">
              <Link
                to={`${locationSlug}/coworking-space`}
                className="inline-block text-center hover:bg-secondary-darkorange transition lg duration-300 ease-in-out whitespace-no-wrap w-72 lg:mt-3 xl:mt-0 text-white px-1 py-4 rounded-lg bg-primary-orange focus:outline-none"
              >
                See 150 more coworking spaces
              </Link>
            </div>
          </section>
        </div>
      </LoadingOverlay>
    </ResponseStatus>
  )
}

NotFound.propTypes = {
  locationStatus: PropTypes.string,
  locationSlug: PropTypes.string,
  coworkingSpaces: PropTypes.array,
  loading: PropTypes.bool,
  servicedOffices: PropTypes.array,
  fetchVenueList: PropTypes.func,
  cityId: PropTypes.number,
}
const mapStateToProps = ({ location, venue }) => {
  return {
    locationSlug: location.slug || 'singapore',
    loading:
      venue.page404_coworking_spaces.loading ||
      venue.page404_serviced_offices.loading ||
      location.loading,
    locationStatus: location.status,
    cityId: location.id || 3,
    coworkingSpaces: venue.page404_coworking_spaces?.list || [],
    servicedOffices: venue.page404_serviced_offices?.list || [],
  }
}

const mapDispatchToProps = {
  fetchVenueList: venueActions.fetchVenueList,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound)

export const FETCH_CATEGORIES = 'fetchCategories'
export const FETCHED_CATEGORIES = 'fetchedCategories'
export const FETCH_CATEGORY = 'fetchCategory'
export const FETCHED_CATEGORY = 'fetchedCategory'

export const CREATE_CATEGORY = 'createCategory'
export const CREATED_CATEGORY = 'createdCategory'
export const EDIT_CATEGORY = 'editCategory'
export const EDITED_CATEGORY = 'editedCategory'

export const DELETE_CATEGORY = 'deleteCategory'

export const FETCH_CATEGORY_OPTIONS = 'fetchCategoryOptions'
export const FETCHED_CATEGORY_OPTIONS = 'fetchedCategoryOptions'

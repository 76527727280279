import { call, takeLatest, put } from 'redux-saga/effects'
import api from 'constants/api'
import * as types from 'actions/cities/types'

function* fetchCities() {
  try {
    const { data } = yield call(api.get, `/places`)
    const places = data.places
    places.data = places.data.map((place) => {
      return {
        label: place.name,
        value: place.id,
      }
    })
    yield put({ type: types.FETCHED_CITIES, places })
  } catch (error) {
    console.log(error)
  }
}

function* fetchCityManager({ payload }) {
  const { city } = payload
  try {
    const { data } = yield call(api.get, `/places?city=${city}`)

    yield put({ type: types.FETCHED_CITY_MANAGER, city: data.place })
  } catch (e) {
    console.log(e)
  }
}

export default function* () {
  yield takeLatest(types.FETCH_CITIES, fetchCities)
  yield takeLatest(types.FETCH_CITY_MANAGER, fetchCityManager)
}

import { fork, put, call } from 'redux-saga/effects'
import getCoordinates from 'utils/getCoordinates'
import api from 'constants/api'
import * as types from 'actions/locations/types'

function* getLocation() {
  try {
    const { coords } = yield getCoordinates()
    const { data } = yield call(api.get, '/location', {
      params: {
        lat: coords.latitude,
        lon: coords.longitude,
      },
    })

    yield put({ type: types.CURRENT_CITY, ...data, status: 'allow' })
  } catch (error) {
    yield put({ type: types.CURRENT_CITY, status: 'block' })
  }
}

export default function* () {
  yield fork(getLocation)
}

import api from 'constants/api';
import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'actions/hubspot/types';
import * as notifTypes from 'actions/notifications/types'


function* sendHubspotForm({ payload }) {
  try {
    const submittedAt = new Date()
    const { data } = yield call(api.post, 'hubspot-form/submit', { ...payload, submittedAt })
    yield put({ type: notifTypes.SUCCESS, message: data.message })
  } catch (error) {
    yield put({ type: notifTypes.ERROR, message: error.message })
  } finally {
    yield put({ type: types.SEND_HUBSPOT_FORM_DONE })
  }
}

export default function* () {
  yield takeLatest(types.SEND_HUBSPOT_FORM, sendHubspotForm);
}

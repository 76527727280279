import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, createStore } from 'redux'
import rootSaga from 'sagas'
import reducers from 'reducers'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

const useStore = (composer, preloadedState) => {
  const composeStore = composer(applyMiddleware(...middlewares))(createStore)

  const store = composeStore(reducers, preloadedState)

  sagaMiddleware.run(rootSaga)

  return store
}

export default useStore

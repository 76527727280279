import * as types from 'actions/countries/types'
import { handleActions } from 'redux-actions'

const initialState = {
  list: [],
  listWithCities: [],
  options: [],
  fetching: false,
  cityPerCountry: {
    list: [],
  },
}

const countriesReducer = handleActions(
  {
    [types.FETCH_COUNTRIES](state) {
      return {
        ...state,
        list: [],
        fetching: true,
      }
    },
    [types.FETCHED_COUNTRIES](state, { list }) {
      const venueTypes = [
        {
          value: 'flexible',
          label: 'Flexible',
        },
        {
          value: 'landlord_building',
          label: 'Landlord/Building',
        },
      ]
      return {
        ...state,
        list,
        venueTypes,
        fetching: false,
      }
    },
    [types.FETCH_COUNTRIES_WITH_CITIES](state) {
      return {
        ...state,
        listWithCities: [],
        fetching: true,
      }
    },
    [types.FETCHED_COUNTRIES_WITH_CITIES](state, { listWithCities }) {
      const venueTypes = [
        {
          value: 'flexible',
          label: 'Flexible',
        },
        {
          value: 'landlord_building',
          label: 'Landlord/Building',
        },
      ]
      return {
        ...state,
        listWithCities,
        venueTypes,
        fetching: false,
      }
    },
    [types.FETCH_COUNTRY_OPTIONS](state) {
      return {
        ...state,
        options: [],
        fetch: true,
      }
    },
    [types.FETCHED_COUNTRY_OPTIONS](state, { options }) {
      const venueTypes = [
        {
          value: 'flexible',
          label: 'Flexible',
        },
        {
          value: 'landlord_building',
          label: 'Landlord/Building',
        },
      ]
      return {
        ...state,
        options,
        venueTypes,
        fetch: false,
      }
    },
    [types.FETCHED_COUNTRY_CITIES](state, { data }) {
      const venueTypes = [
        {
          value: 'flexible',
          label: 'Flexible',
        },
        {
          value: 'landlord_building',
          label: 'Landlord/Building',
        },
      ]
      return {
        ...state,
        cityPerCountry: data,
        venueTypes,
      }
    },
    [types.RESET_COUNTRY_CITIES](state) {
      return {
        ...state,
        cityPerCountry: {
          list: [],
        },
      }
    },
  },
  initialState
)

export default countriesReducer

import FavoriteHeartHandler from 'components/shared/favorites/heart'
import BuildingIcon from 'components/shared/icons/building'
import Location from 'components/shared/icons/location'
import ThumbnailLoader from 'components/shared/loaders/thumb'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import isEmpty from 'lodash/isEmpty';
import ImageSlider from './image-slider'
import SpacePrice from './space-price'
import VenuePrice from './venue-price'


const VerticalCard = ({ data, w, loading }) => {
  const {
    id,
    title,
    url,
    address,
    landmarks,
    prices,
    capacity,
    categorySummaries,
    images,
    floor_size,
    officeSpaceCardInfoList
  } = data
  const detailLink = useRef()

  const handleOnImageClick = (e) => {
    e.preventDefault()
    if (detailLink.current) {
      detailLink.current.click()
    }
  }

  if (loading)
    return (
      <div
        className="space-profile flex flex-col h-full border border-secondary-graywhite rounded-lg transition-shadow duration-500 ease-in-out hover:shadow-card"
      >

        <ThumbnailLoader className="card-placeholder" />
      </div>
    )

  const renderFooterCard = isEmpty(officeSpaceCardInfoList) ? (
    <div>
      {categorySummaries && <VenuePrice categorySummaries={categorySummaries} />}
      {prices && <SpacePrice prices={prices} capacity={capacity} />}
      {floor_size && <span>{floor_size}</span>}
    </div>
  ) : (
    <div className={'border-t'}>
      {
        officeSpaceCardInfoList.map((info, key) => (
          <div className="space-profile-body flex flex-row justify-between pt-2" key={key}>
            <div className="lato-reg w-full pr-2 text-left">
              <p className={'leading-none'}>{info.label}</p>
            </div>
            <div className="lato-reg w-full pl-2 text-right">{info.value}</div>
          </div>
        ))
      }
    </div>
  )

  return (
    <div
      className="space-profile flex flex-col h-full border border-secondary-graywhite rounded-lg transition-shadow duration-500 ease-in-out hover:shadow-card"
    >
      <meta content={`Space`} />
      <div className="relative pb-5/8 cursor-pointer max-h-max">
        <ImageSlider
          className="absolute rounded w-full h-full"
          images={images}
          onClick={handleOnImageClick}
          w={w}
        />
        <FavoriteHeartHandler
          id={id}
          className="rounded-full m-3 h-8 w-8 flex absolute items-center justify-center bg-secondary-graywhite cursor-pointer right-0"
        />
      </div>
      <div className="space-profile-header text-left px-5 py-3">
        <a ref={detailLink} href={url}>
          <h3 className="text-xl mb-1 clamp-1">
            {title}
          </h3>
        </a>
        <p className="text-base font-medium text-primary-lightgray flex relative pl-6 clamp-1">
          <Location className="fill-current w-4 h-4 absolute left-0 top-0 mt-1" fill="#eba142" />
          <span itemScope itemType={`http://schema.org/PostalAddress`}>
            {address}
          </span>
        </p>
        <p className="text-base font-medium text-primary-lightgray mb-4 flex relative pl-6 clamp-1">
          <BuildingIcon
            className="fill-current w-4 h-4 absolute left-0 top-0 mt-1"
            color="#eba142"
          />{' '}
          {landmarks}
        </p>
        {renderFooterCard}
      </div>
    </div>
  )
}

VerticalCard.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
  w: PropTypes.number,
}

export default VerticalCard

import * as types from 'actions/contacts/types'
import { handleActions } from 'redux-actions'

const initialState = {
  headers: [{ name: 'NAME', field: 'name' }],
  details: {},
  list: [],
  fetching: false,
}

const contactsReducer = handleActions(
  {
    [types.CLEAR_CONTACTS](state) {
      return {
        ...state,
        list: [],
      }
    },
    [types.FETCH_CONTACTS](state) {
      return {
        ...state,
        list: [],
        fetching: true,
      }
    },
    [types.FETCHED_CONTACTS](state, { list }) {
      return {
        ...state,
        list,
        fetching: false,
      }
    },
    [types.FETCH_CONTACT](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_CONTACT](state, { contact }) {
      return {
        ...state,
        details: contact,
        fetching: false,
      }
    },
    [types.CREATE_OR_EDIT_CONTACT](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_CONTACT](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_CONTACT](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_CONTACT](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.NEW_CONTACT](state, { contact }) {
      const newList = [contact, ...state.list]
      return {
        ...state,
        list: newList,
        fetching: false,
      }
    },
    [types.ADMIN_EDITED_CONTACT](state, { contact }) {
      const list = state.list
      const index = list.findIndex((c) => c.id === contact.id)
      if (index > -1) list[index] = contact
      return {
        ...state,
        fetching: false,
        list: [...list],
      }
    },
    [types.ADMIN_DELETED_CONTACT](state, { contacts }) {
      return {
        ...state,
        fetching: false,
        list: [...contacts],
      }
    },
    [types.CONTACT_DISABLE_LOADING](state) {
      return {
        ...state,
        fetching: false,
      }
    },
  },
  initialState
)

export default contactsReducer

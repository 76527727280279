import * as types from 'actions/ems/types';
import { handleActions } from 'redux-actions';

const initialState = {
  headers: [
    { name: 'DATE', field: 'date' },
    { name: 'MERCHANT', field: 'merchant' },
    { name: 'CLIENT NAME', field: 'clientName' },
    { name: 'EMAIL', field: 'email' },
    { name: 'PHONE', field: 'phone' },
    { name: 'COUNTRY', field: 'country' },
    { name: 'SUBJECT', field: 'subject' },
    { name: 'SALES PERSON', field: 'salesPerson' },
    { name: 'SENT?', field: 'sent' },
    { name: '', field: 'action' },
  ],
  data: [],
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
  fetching: false,
  status: ''
};

const emsReducer = handleActions(
  {
    [types.FETCHED_EMS](state, { data }) {
      return {
        ...state,
        fetching: false,
        ...data
      };
    },
    [types.ENABLE_LOADING](state) {
      return {
        ...state,
        fetching: true,
      };
    },
    [types.DISABLE_LOADING](state) {
      return {
        ...state,
        fetching: false,
      };
    },
    [types.SET_EMS_STATUS](state, { status }) {
      console.log(status)
      return {
        ...state,
        status
      };
    },
  },
  initialState,
);

export default emsReducer

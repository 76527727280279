import * as types from 'actions/general/types'
import * as hubspotTypes from 'actions/hubspot/types'
import { handleActions } from 'redux-actions'

const initialState = {
  fetching: false,
}

const generalReducer = handleActions(
  {
    [types.SEND_CONTACT](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.SENT_CONTACT](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [hubspotTypes.SEND_HUBSPOT_FORM](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [hubspotTypes.SEND_HUBSPOT_FORM_DONE](state) {
      return {
        ...state,
        fetching: false,
      }
    }
  },
  initialState,
)

export default generalReducer

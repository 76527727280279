import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import placesActions from 'actions/places/actions'
import Button from 'forms/shared/buttons/submit'
import authActions from 'actions/auth/actions'
import RegisterForm from './form'

const Register = ({ register, places, fetchPlaceOptions, notification: { type } }) => {
  useEffect(() => { fetchPlaceOptions({ published: true }) }, [])

  return (
    <div className="flex flex-col">
      {type === 'success' && location.reload()}
      <p className="text-base lg:text-2xl mx-5 cursor-pointer text-center">
        Join us and be part of the new way to office!
      </p>
      <div className="flex justify-between items-center my-3 relative flex-col xs:flex-row">
        <a
          href="/auth/google"
          className="text-white p-2 py-4 bg-secondary-google rounded-sm w-full xs:w-7/8 text-center rounded-lg"
        >
          <i className="fa fa-google mr-3"></i> Join via Google
        </a>
      </div>
      <div className="my-3">
        <RegisterForm form="register-modal-form" placesOptions={places} onSubmit={values => register(values)} />
        <div className="button-group mt-5">
          <Button
            formName="register-modal-form"
            className="bg-primary-orange hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white w-full py-3 rounded-lg"
          >
            JOIN
          </Button>
        </div>
      </div>
    </div>
  )
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
  places: PropTypes.array,
  fetchPlaceOptions: PropTypes.func.isRequired,
}

const mapStateToProps = ({ notification, places }) => ({
  notification,
  places: places.options,
})

const mapDispatchToProps = {
  register: authActions.modalRegister,
  fetchPlaceOptions: placesActions.fetchPlaceOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)

import { call, takeLatest, put } from 'redux-saga/effects'
import api from 'constants/api'
import * as types from 'actions/seo/types'

function* getSeoContent({ payload }) {
  try {
    const { data } = yield call(api.get, `/seo${payload}`)
    yield put({ type: types.FETCHED_SEO_CONTENT, seo: data })
  } catch (error) {
    console.error('SEO no found')
  }
}

export default function* () {
  yield takeLatest(types.FETCH_SEO_CONTENT, getSeoContent)
}

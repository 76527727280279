import React from 'react'
import PropTypes from 'prop-types'

const SpacePrice = ({ prices, capacity }) => {
  return (
    <div className="space-profile-body flex flex-row justify-between pt-3 border-t">
      <div className="flex flex-col font-bold">
        {prices.map((price, key) => (
          <p key={key}>{price}</p>
        ))}
      </div>
      <p className="text-primary-lightgray">{capacity}</p>
    </div>
  )
}

SpacePrice.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string),
  capacity: PropTypes.string,
}

export default SpacePrice

import React from 'react'
import PropTypes from 'prop-types'

const InputField = ({
  input,
  type,
  meta,
  disabled,
  readOnly,
  groupClassName,
  inputClassName,
  errorClassName,
  inputErrorClassName,
  inputGroupClassName,
  onClick,
  placeHolder,
  labelClassName,
  label
}) => {
  const inputClasses = `form-control ${
    meta.touched && meta.error ? [inputClassName, inputErrorClassName].join(' ') : inputClassName
  }`

  const inputGroupClasses = `form-control w-full ${
    meta.touched && meta.error ? [inputGroupClassName, inputErrorClassName].join(' ') : inputGroupClassName
  }`

  return (
    <div className={`form-group ${groupClassName}`}>
      {label && <label className={`block ${labelClassName}`}>
        {label}
      </label>}
      <div className={inputGroupClasses}>
        <input
          {...input}
          className={inputClasses}
          placeholder={placeHolder}
          type={type}
          autoComplete="new-password"
          onClick={onClick}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
      {meta.touched && meta.error && <p className={`form-error ${errorClassName}`}>{meta.error}</p>}
    </div>
  )
}

InputField.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  groupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  inputErrorClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeHolder: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string
}

export default InputField

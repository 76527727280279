import * as types from 'actions/categories/types'
import { handleActions } from 'redux-actions'

const initialState = {
  headers: [
    { name: 'CATEGORY NAME', field: 'category_name' },
    { name: 'TYPE', field: 'type' },
    { name: 'STATUS', field: 'status' },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  options: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
}

const categoryReducer = handleActions(
  {
    [types.FETCH_CATEGORIES](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.FETCHED_CATEGORIES](state, { response }) {
      const { data, page, total, lastPage } = response

      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_CATEGORY](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_CATEGORY](state, { category }) {
      return {
        ...state,
        details: category,
        fetching: false,
      }
    },
    [types.FETCH_CATEGORY_OPTIONS](state) {
      return {
        ...state,
        options: [],
        fetching: true,
      }
    },
    [types.FETCHED_CATEGORY_OPTIONS](state, { options }) {
      return {
        ...state,
        options,
        fetching: false,
      }
    },
    [types.CREATE_CATEGORY](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_CATEGORY](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_CATEGORY](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_CATEGORY](state) {
      return {
        ...state,
        fetching: false,
      }
    },
  },
  initialState,
)

export default categoryReducer

import { apiConfig } from 'constants/api';
import FileDownload from 'js-file-download';
import { takeLatest, call, select, put, all } from 'redux-saga/effects';
import * as types from 'actions/ems/types';
import * as notifTypes from 'actions/notifications/types'
import formSelector from './selectors/form'


const apiAdmin = apiConfig({ baseURL: '/api/admin' })


function* fetchEms({ payload }) {
  try {
    yield put({ type: types.ENABLE_LOADING })
    const selector = yield formSelector('admin-ems-filter-form')
    // const keywordFilter = yield select(state => selector(state, 'admin_search_filter'))
    const countryFilter = yield select(state => selector(state, 'country_filter'))
    const country = (countryFilter || []).map(c => c.value)
    const merchantFilter = yield select(state => selector(state, 'merchant_filter'))
    const merchant = (merchantFilter || []).map(m => m.value)
    const { limit, page } = payload
    const { data } = yield call(apiAdmin.get, '/ems', {
      params: {
        size: limit,
        merchant,
        country,
        page
      }
    })
    yield put({ type: types.FETCHED_EMS, data })
  } catch (error) {
    console.log(error)
  }
}

function* createEms({ payload }) {
  try {
    yield put({ type: types.ENABLE_LOADING })
    // eslint-disable-next-line camelcase
    const { phone, name, message, email, merchant, country, category, product_type } = payload
    const countries = yield select(state => state.countries.list)
    const categories = yield select(state => state.category.options)
    // eslint-disable-next-line camelcase
    const country_id = country ? countries.find(c => c.value === country).id : ''
    // eslint-disable-next-line camelcase
    const category_id = category ? categories.find(c => c.value === category).id : ''
    yield call(apiAdmin.post, '/ems', {
      phone,
      name,
      message,
      email,
      country_id,
      merchant,
      category_id,
      product_type
    })
    yield all([
      put({ type: types.DISABLE_LOADING }),
      put({ type: notifTypes.SUCCESS, message: 'Success create EMS.' })
    ])
  } catch (error) {
    console.log(error)
    yield put({ type: notifTypes.ERROR, message: 'Error create EMS.' })
  }
}

function* exportEms() {
  const response = yield call(apiAdmin.get, '/export-ems', { responseType: 'arraybuffer' })
  FileDownload(response.data, 'ems.xlsx')
}

export default function* () {
  yield takeLatest(types.FETCH_EMS, fetchEms);
  yield takeLatest(types.CREATE_EMS, createEms);
  yield takeLatest(types.EXPORT_EMS, exportEms)
}

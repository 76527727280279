import api from 'constants/api';
import { takeLatest, call } from 'redux-saga/effects';
import * as types from 'actions/clients/types';

function* getPassportBookings({ payload }) {
  try {
    // @ todo bind to front end components
    // api call for passport subscription
    const { data } = yield call(api.get, `/api/client/passport-subscription/${payload}`)
    // if (data) {
    //   //api call for passport bookings
    // }
    console.log(data)
  } catch ({ reponse }) {
    console.log(reponse)
  }
}

export default function* () {
  yield takeLatest(types.FETCH_CLIENT_PASSPORT_BOOKINGS, getPassportBookings);
}

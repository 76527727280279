import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const Dropdown = ({
  options,
  input,
  meta,
  disabled,
  groupClassName,
  label,
  labelClassName,
  // selectClassName,
  errorClassName,
  // inputErrorClassName,
  onClick,
  placeholder,
}) => {
  // const classNameSelect = `form-control w-full ${
  //   meta.touched && meta.error ? [selectClassName, inputErrorClassName].join(' ') : selectClassName
  // }`

  const selectStyles = {
    control: styles => ({
      ...styles,
      borderRadius: '0.5rem',
      border: '1px solid #e2e8f0',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #e2e8f0',
      },
    }),
    menu: styles => ({
      ...styles,
      zIndex: 20, // to overcome react-intl contact library zIndex: 1
    }),
    valueContainer: styles => ({
      ...styles,
      padding: '0.5rem',
    }),
    dropdownIndicator: (styles, { selectProps }) => ({
      ...styles,
      transform: selectProps.menuIsOpen ? 'scaleY(-1)' : null,
    }),
    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    option: styles => ({ ...styles, borderTop: 0 }),
  }

  const handleSelectedValue = () => {
    const value = input.value.value ? input.value.value : input.value
    const find = options.find(option => option.value === value)
    return find || ''
  }

  return (
    <div className={`form-group text-sm ${groupClassName}`}>
      {label && <label className={labelClassName}>
        {label}
      </label>}
      <div className={`form-control w-full`}>
        <Select
          styles={selectStyles}
          classNamePrefix="react-select"
          {...input}
          value={handleSelectedValue()}
          options={options}
          onChange={({ value }) => {
            input.onChange(value)
          }}
          onBlur={() => input.onBlur(input.value)}
          onClick={onClick}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {meta.touched && meta.error && <p className={`form-error ${errorClassName}`}>{meta.error}</p>}
    </div>
  )
}

Dropdown.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  selectClassName: PropTypes.string,
  groupClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  inputErrorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default Dropdown

import React from 'react'
import PropTypes from 'prop-types'

const Heart = ({ color = '#FF396A' }) => (
  <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons-/-Color-/-Favourite" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M4.5798082,13.7908727 C2.7278082,11.9228727 2.4258082,8.88887269 4.0918082,6.85087269 C6.0018082,4.51287269 9.4408082,4.38987269 11.5118082,6.48187269 L11.9778082,6.95087269 L12.2938082,6.63287269 C14.1458082,4.76787269 17.1508082,4.46687269 19.1688082,6.14887269 C21.4828082,8.07787269 21.6038082,11.5438727 19.5318082,13.6338727 L14.1048082,19.1108727 C12.9298082,20.2958727 11.0258082,20.2958727 9.8508082,19.1108727 L4.5798082,13.7908727 Z"
        id="Fill-1"
        fill={color}
      />
    </g>
  </svg>
)

Heart.propTypes = {
  color: PropTypes.string,
}

export default Heart

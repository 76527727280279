import keys from 'lodash/keys'
import { createQueryString } from './queryString'

export default (image, params = {}) => {
  if (!image) return ''
  const mainPath = image.split('?')[0]
  keys(params)
    .forEach((key) => {
      !params[key] && delete params[key]
    })
  const query = createQueryString(params)
  return query ? `${mainPath}?${query}` : mainPath
}

import api, { apiConfig } from 'constants/api'
import { takeLatest, call, put } from 'redux-saga/effects'
import * as types from 'actions/contacts/types'
import * as notifTypes from 'actions/notifications/types'

const configIntance = (config) => {
  return apiConfig(config)
}

function* getContacts({ payload: { id } }) {
  try {
    const { data } = yield call(api.get, `/venues/contacts/${id}`)

    yield put({ type: types.FETCHED_CONTACTS, list: data })
  } catch (err) {
    console.warn(err)
  }
}

function* createOrEditContact({ payload }) {
  const action = payload.action
  try {
    const file = payload.file
    if (file instanceof File) {
      const type = file.type
      const presignedConfig = {
        baseURL: '/api',
      }
      const { data } = yield call(
        configIntance(presignedConfig).post,
        '/upload/get-presigned-url',
        {
          file_name: file.name,
          type,
        }
      )
      const blob = new Blob([file], { type })
      const amzConfig = {
        headers: {
          'Content-Type': type,
        },
      }
      yield call(configIntance(amzConfig).put, data.url, blob)
      payload.image_filename = data.distribute_url.split('?')[0]
    }
    const merchantConfig = {
      baseURL: '/api/merchant',
    }
    delete payload.file
    if (action === 'edit') {
      yield call(configIntance(merchantConfig).put, `/contacts/${payload.id}`, payload)
      yield put({ type: types.EDITED_CONTACT })
    } else {
      const { data } = yield call(configIntance(merchantConfig).post, '/contacts', payload)
      yield put({ type: types.NEW_CONTACT, contact: data.contact })
    }
    yield put({ type: notifTypes.SUCCESS, message: `Success ${action} contact.` })
  } catch ({ response = {} }) {
    yield put({ type: types.CONTACT_DISABLE_LOADING })
    const { data } = response
    const message =
      data && data.length
        ? data.map((val) => val.message).join('\n')
        : data.error || data.message || `Error ${action} contact.`
    yield put({ type: notifTypes.ERROR, message })
  }
}

function* editContact({ payload }) {
  try {
    // const { data } = yield call(api.put, `/contacts`)

    console.log('editContact', payload)
  } catch (err) {
    console.warn(err)
  }
}

function* adminEditContact({ payload }) {
  const adminConfig = {
    baseURL: '/api/admin',
  }
  yield call(configIntance(adminConfig).put, `/contacts/${payload.id}`, payload)
  yield put({ type: types.ADMIN_EDITED_CONTACT, contact: payload })
}
function* adminDeleteContact({ payload }) {
  const adminConfig = {
    baseURL: '/api/admin',
  }
  try {
    const { data } = yield call(configIntance(adminConfig).delete, `/contacts/${payload.id}`)
    yield put({ type: types.ADMIN_DELETED_CONTACT, contacts: data })
  } catch {
    yield put({ type: notifTypes.ERROR, message: 'Error delete contact' })
  }
}

function* adminCreateContact({ payload }) {
  const adminConfig = {
    baseURL: '/api/admin',
  }
  const { data } = yield call(configIntance(adminConfig).post, `/contacts`, payload)

  yield put({ type: types.NEW_CONTACT, contact: data.contact })
}

export default function* () {
  yield takeLatest(types.FETCH_CONTACTS, getContacts)
  yield takeLatest(types.ADMIN_CREATE_CONTACT, adminCreateContact)
  yield takeLatest(types.ADMIN_EDIT_CONTACT, adminEditContact)
  yield takeLatest(types.CREATE_OR_EDIT_CONTACT, createOrEditContact)
  yield takeLatest(types.EDIT_CONTACT, editContact)
  yield takeLatest(types.ADMIN_DELETE_CONTACT, adminDeleteContact)
}

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const defaultOps = {
  defaultOpen: false,
  canceledOnTouchOutside: true
}

const Toggler = (WrappedComponent, opts = defaultOps) => {
  const options = {
    ...defaultOps,
    ...opts,
  }

  const { defaultOpen, canceledOnTouchOutside } = options
  class EnhancedComponent extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isOpen: defaultOpen
      }
    }

    handleToggle = () => this.setState({
      isOpen: !this.state.isOpen
    })

    escapeListener = (e) => {
      if (e.key === 'Escape' && !defaultOpen && canceledOnTouchOutside) {
        this.setState({
          isOpen: false
        })
      }
    }

    clickListener = (e) => {
      // eslint-disable-next-line react/no-find-dom-node
      const domNode = ReactDOM.findDOMNode(this);
      if (!domNode.contains(e.target) && !defaultOpen && canceledOnTouchOutside) {
        this.setState({
          isOpen: false
        })
      }
    }

    componentDidMount() {
      // Attach the listeners on component mount.
      document.addEventListener('click', this.clickListener)
      document.addEventListener('keyup', this.escapeListener)
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.clickListener)
      document.removeEventListener('keyup', this.escapeListener)
    }

    render() {
      const { forwardedRef, ...otherProps } = this.props;
      return <WrappedComponent ref={forwardedRef} isOpen={this.state.isOpen} toggle={this.handleToggle} {...otherProps} />;
    }
  }

  EnhancedComponent.propTypes = {
    forwardedRef: PropTypes.func,
    isOpen: PropTypes.bool
  }

  const forwardRef = (props, ref) => <EnhancedComponent {...props} forwardedRef={ref} />;
  return React.forwardRef(forwardRef);
}

export default Toggler;

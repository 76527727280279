import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

// Non-library reducers
import auth from 'reducers/auth';
import category from 'reducers/categories';
import space from 'reducers/space';
import merchant from 'reducers/merchant';
import amenities from 'reducers/amenities';
import upload from 'reducers/upload';
import messages from 'reducers/messages';
import user from 'reducers/user';
import notification from 'reducers/notification'
import variant from 'reducers/variant'
import city from 'reducers/city'
import blogs from 'reducers/blogs'
import location from 'reducers/location'
import venue from 'reducers/venue'
import search from 'reducers/search'
import seo from 'reducers/seo'
import areas from 'reducers/areas'
import places from 'reducers/places'
import landmarks from 'reducers/landmarks'
import spaceFunctions from 'reducers/spaceFunctions'
import countries from 'reducers/countries'
import promocodes from 'reducers/promocodes'
import passport from 'reducers/passport'
import review from 'reducers/reviews'
import general from 'reducers/general'
import bookings from 'reducers/bookings'
import brands from 'reducers/brands'
import contacts from 'reducers/contacts'
import leads from 'reducers/leads'
import ems from 'reducers/ems'
import developer from 'reducers/developer'
import building from 'reducers/buildings'
import traditionalOffice from 'reducers/traditionalOffice'

export default combineReducers({
  amenities,
  areas,
  auth,
  blogs,
  bookings,
  category,
  city,
  contacts,
  countries,
  form,
  general,
  landmarks,
  location,
  messages,
  merchant,
  notification,
  passport,
  places,
  promocodes,
  review,
  search,
  seo,
  space,
  spaceFunctions,
  upload,
  user,
  variant,
  venue,
  leads,
  ems,
  brands,
  developer,
  building,
  traditionalOffice,
});

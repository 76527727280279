export const WS_INITIALIZED = 'wsInitialized';
export const ADMIN_WS_INIT = 'adminWsInit'
export const FETCH_THREADS = 'fetchThreads';
export const FILTER_THREADS = 'filterThreads';
export const CONVERSATIONS_FETCHED = 'conversationsFetched';
export const CONVERSATION_TYPING = 'conversationTyping'
export const CONVERSATION_BLUR = 'conversationBlur'
export const TYPING_FETCHED = 'typingFetched'
export const CONVERSATIONS_ACCEPT = 'conversationsAccept'
export const NEW_CONVERSATION = 'newConversation'
export const GET_NEW_CONVERSATION = 'getNewConversation'
export const GET_CONVERSATIONS = 'getConversations';
export const RECEIVE_MESSAGE = 'receiveMessage';
export const MESSAGE_USER = 'messageUser';
export const GET_MESSAGES = 'getMessages';
export const MESSAGES_FETCHED = 'messagesFetched';
export const INQUIRY_FETCHED = 'inquiryFetched'
export const MESSAGES_SENT = 'messagesSent'
export const MESSAGES_SEEN = 'messagesSeen'
export const CLOSE_SUBSCRIPTIONS = 'closeSubscriptions';
export const SEND_MESSAGE = 'sendMessage';
export const ACCEPT_MESSAGE = 'acceptMessage'
export const SEARCH_USERS = 'searchUsers'
export const QUICK_MESSAGE = 'quickMessage'
export const SEARCH_FETCHED = 'searchFetched'
export const TOTAL_UNREAD_FETCHED = 'totalUnreadFetched'
export const CHAT_WITH_EXPERT = 'chatWithExpert'

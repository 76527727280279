import { handleActions } from 'redux-actions';
import * as types from 'actions/seo/types';


export const initialState = {
  componentType: null,
  content: {},
}

const seoReducer = handleActions({
  [types.SET_SEO_CONTENT_TYPE](state, { payload }) {
    return {
      ...state,
      componentType: payload.component_type,
    };
  },
  [types.FETCH_SEO_CONTENT](state) {
    return {
      ...state,
      content: null,
      title: '...',
      description: '',
      keywords: '',
    };
  },
  [types.FETCHED_SEO_CONTENT](state, { seo }) {
    return {
      ...state,
      componentType: seo.component_type,
      content: seo,
      title: seo.title,
      description: seo.description,
      keywords: seo.keywords,
      faqContents: seo.faq_content ? JSON.parse(seo.faq_content) : []
    };
  },
}, initialState);

export default seoReducer;

import imageCompressor from 'browser-image-compression'

const blobToFile = async (blob, file) => {
  const result = new File([blob], file.name, { type: file.type, lastModified: Date.now() });
  return result
}

const compressImage = async (maxWidth, file) => {
  const options = {
    maxWidthOrHeight: maxWidth,
  }

  const image = await imageCompressor(file, options)

  return blobToFile(image, file)
}

export default compressImage

import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })

    // @TODO: log this
    console.log(`%c Error: `, 'background: #dc3545; color: white', error, errorInfo.componentStack)
  }

  render() {
    if (this.state.hasError) {
      return <div className="flex w-full h-screen justify-center items-center">
        <div className="text-center">
          <p className="text-3xl">Oops, Something went wrong from our side.</p>
          <a href="/" className="">Click here to return to the homepage.</a>
        </div>
      </div>
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}


import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
// import loadable from '@loadable/component';

const ResponseStatus = ({ code, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.status = code;
      return children;
    }}
  />
);

ResponseStatus.propTypes = {
  code: PropTypes.number,
  children: PropTypes.node,
};

export default ResponseStatus;

import React from 'react'
import PropTypes from 'prop-types'
import Toggler from 'hoc/toggler'
import Overlay from 'components/shared/overlay'

const ModalHandler = ({
  className,
  title,
  prefix,
  children,
  isOpen,
  toggle,
  disabled = false,
  hideButton = false,
  onClick,
}) => {
  return (
    <>
      <div
        className={`${hideButton ? 'hidden' : className}`}
        onClick={() => {
          if (disabled) return
          if (onClick) {
            onClick(toggle)
          } else {
            toggle()
          }
        }}
      >
        {prefix} {title}
      </div>
      {isOpen && <Overlay onClose={toggle}>{children({ toggle })}</Overlay>}
    </>
  )
}

ModalHandler.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideButton: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Toggler(ModalHandler, { canceledOnTouchOutside: false })

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const BodyClass = ({ className }) => (
  <Helmet>
    <body className={className} />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2"></meta>
  </Helmet>
)

BodyClass.propTypes = {
  className: PropTypes.string.isRequired,
}

export default BodyClass

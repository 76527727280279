import * as types from 'actions/promocodes/types'
import { handleActions } from 'redux-actions'

const initialState = {
  headers: [
    { name: 'TITLE', field: 'title' },
    { name: 'CODE', field: 'code' },
    { name: 'PLACE', field: 'place' },
    { name: 'REDEEMED', field: 'redeemed' },
    { name: 'START DATE', field: 'startDate' },
    { name: 'END DATE', field: 'endDate' },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  options: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
}

const promoCodeReducer = handleActions(
  {
    [types.FETCH_PROMOCODES](state) {
      return {
        ...state,
        list: [],
        fetching: true,
      }
    },
    [types.FETCHED_PROMOCODES](state, { response }) {
      const { data, page, total, lastPage } = response
      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_PROMOCODE](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_PROMOCODE](state, { promocode }) {
      return {
        ...state,
        details: promocode,
        fetching: false,
      }
    },
    [types.FETCH_PROMOCODE_OPTIONS](state) {
      return {
        ...state,
        options: [],
        fetching: true,
      }
    },
    [types.FETCHED_PROMOCODE_OPTIONS](state, { options }) {
      return {
        ...state,
        options,
        fetching: false,
      }
    },
    [types.CREATE_PROMOCODE](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_PROMOCODE](state, { promoCodeId }) {
      return {
        ...state,
        fetching: false,
        createdSuccess: true,
        promoCodeId
      }
    },
    [types.EDIT_PROMOCODE](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_PROMOCODE](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.CLIENT_APPLIED_CODE](state, { data }) {
      return {
        ...state,
        promo_discount: { ...data }
      }
    }
  },
  initialState,
)

export default promoCodeReducer

import { apiConfig } from 'constants/api'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from 'actions/developer/types'
import * as notifTypes from 'actions/notifications/types'


const apiAdmin = apiConfig({ baseURL: '/api/admin' })
const publicApi = apiConfig({ baseURL: '/api' })

function* fetchDevelopers() {
  try {
    const { data } = yield call(publicApi.get, `/developer/options`)
    yield put({ type: types.FETCHED_DEVELOPER, developers: data })
  } catch (error) {
    console.log(error)
  }
}

function* createDeveloper({ payload }) {
  try {
    const { data } = yield call(apiAdmin.post, '/developers', payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Create Developer Succesfully' })
    yield put({ type: types.CREATE_DEVELOPER, payload: data })
  } catch ({ response }) {
    console.log(response)
    yield put({ type: notifTypes.ERROR, message: response.statusText })
  }
}


function* updateDeveloper({ payload }) {
  try {
    yield call(apiAdmin.put, `developers/${payload.id}`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Update Succesfully' })
    yield put({ type: types.UPDATE_DEVELOPER, payload: { developerId: payload.id } })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: response.statusText })
  }
}

function* deleteDeveloper({ payload }) {
  try {
    yield call(apiAdmin.put, `developers/${payload.id}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Delete Succesfully' })
    yield put({ type: types.DELETE_DEVELOPER, payload: { developerId: payload.id } })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: response.statusText })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_DEVELOPER, fetchDevelopers)
  yield takeLatest(types.CREATE_DEVELOPER, createDeveloper)
  yield takeLatest(types.UPDATE_DEVELOPER, updateDeveloper)
  yield takeLatest(types.DELETE_DEVELOPER, deleteDeveloper)
}

export const FETCH_SPACES = 'fetchSpaces'
export const FETCHED_SPACES = 'fetchedSpaces'
export const FETCH_SPACE = 'fetchSpace'
export const CLEAR_SPACE_DETAILS = 'clearSpaceDetail'
export const FETCHED_SPACE = 'fetchedSpace'
export const FETCH_SPACES_VENUE = 'fetchSpacesVenue'
export const FETCHED_SPACES_VENUE = 'fetchedSpacesVenue'
export const EDIT_SPACE_PUBLISH = 'editSpacePublish'
export const DELETE_SPACE = 'deleteSpace'
export const ADMIN_DELETE_SPACE = 'adminDeleteSpace'
export const EDITED_SPACE_PUBLISH = 'editedSpacePublish'
export const FETCH_SPACE_DETAILS = 'fetchSpaceDetails'
export const FETCHED_SPACE_DETAILS = 'fetchedSpaceDetails'
export const COPY_SPACE = 'copySpace'

export const FETCH_SPACE_TYPE = 'fetchSpaceType'
export const FETCH_PASSPORT_SPACE = 'fetchPassportSpace'
export const FETCH_FEATURED_PASSPORT_SPACES = 'fetchFeaturedPassportSpaces'
export const FETCHED_SPACE_TYPE = 'fetchedSpaceType'
export const FETCHED_FEATURED_PASSPORT_SPACES = 'fetchedFeaturedPassportSpaces'
export const SPACE_SUBMIT = 'spaceSubmit'
export const SPACE_SUBMITTED = 'spaceSubmitted'
export const EDIT_SPACE = 'editSpace'
export const EDITED_SPACE = 'editedSpace'
export const SPACE_UPDATED = 'spaceUpdated'
export const SPACE_LINK_CREATED = 'spaceLinkCreated'
export const DISCOUNT_TOGGLE = 'discountToggle'
export const SORT_SPACE_IMAGES = 'sortSpaceImages'
export const FETCH_SPACE_LEADS_BOOKINGS = 'fetchSpaceLeadsBookings'
export const FETCHED_SPACE_LEADS_BOOKINGS = 'fetchedSpaceLeadsBookings'
export const CATEGORY_SELECTED = 'categorySelected'
export const CREATE_NEW_SPACE = 'createNewSpace'
export const NEW_VENUE_SPACE = 'newVenueSpace'
export const ADMIN_FETCH_SPACE_DETAILS = 'adminFetchSpaceDetails'
export const ADMIN_EDIT_SPACE = 'adminEditSpace'
export const ADMIN_CREATE_SPACE = 'adminCreateSpace'
export const ADMIN_CREATE_SPACE_FAILED = 'adminCreateSpaceFailed'
export const SET_INIT_CREATE = 'setInitCreate'
export const SPACE_DISABLE_LOADING = 'spaceDisableLoading'

export const EXPORT_SPACES = 'exportSpaces'
export const EXPORTED_SPACES = 'exportedSpaces'

export const FETCH_SPACE_FEATURED = 'fetchSpaceFeatured'
export const FETCHED_SPACE_FEATURED = 'fetchedSpaceFeatured'

export const INQUIRE_SUBMIT = 'inquireSubmit'
export const INQUIRE_SUBMITED = 'inquireSubmited'

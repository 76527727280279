import * as types from 'actions/review/types'
import { handleActions } from 'redux-actions'

const initialState = {
  headers: [
    { name: 'DATE', field: 'created_at' },
    { name: 'USER', field: 'user' },
    { name: 'RATING', field: 'rating' },
    { name: 'SPACE', field: 'reviewable' },
    { name: 'MERCHANT', field: 'merchant' },
    { name: 'REVIEW', field: 'review' },
    { name: '', field: 'actions' },
  ],
  details: {},
  list: [],
  options: [],
  fetching: false,
  page: 1,
  lastPage: 0,
  total: 0,
  limit: 20,
}

const reviewReducer = handleActions(
  {
    [types.FETCH_REVIEWS](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.FETCHED_REVIEWS](state, { response }) {
      const { data, page, total, lastPage } = response

      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_VENUE_REVIEWS](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.FETCHED_VENUE_REVIEWS](state, { response }) {
      const { data, page, total, lastPage } = response

      return {
        ...state,
        list: data,
        page,
        total,
        lastPage,
        fetching: false,
      }
    },
    [types.FETCH_REVIEW](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_REVIEW](state, { review }) {
      return {
        ...state,
        details: review,
        fetching: false,
      }
    },
    [types.FETCH_REVIEW_OPTIONS](state) {
      return {
        ...state,
        options: [],
        fetching: true,
      }
    },
    [types.FETCHED_REVIEW_OPTIONS](state, { options }) {
      return {
        ...state,
        options,
        fetching: false,
      }
    },
    [types.SENT_REVIEW](state, { review }) {
      return {
        ...state,
        reviewSent: review,
      }
    },
  },
  initialState
)

export default reviewReducer

import React from 'react'

const LoginDisclaimer = () => (
  <p className="text-primary-lightgray font-normal">
    By signing in with email or LinkedIn, you are giving the thumbs up to our{' '}
    <span>
      <a href="/terms-and-conditions" className="text-primary-orange" target="_blank" rel="noopener">
        terms and conditions
      </a>
    </span>
    {` and `}
    <span>
      <a href="/privacy-policy" className="text-primary-orange" target="_blank" rel="noopener">
        privacy policy.
      </a>
    </span>
  </p>
)

export default LoginDisclaimer

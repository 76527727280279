import React from 'react'
import PropTypes from 'prop-types'
import floor from 'lodash/floor'

const TextArea = ({
  input,
  type,
  meta,
  rows,
  placeholder,
  disabled,
  groupClassName,
  inputClassName,
  errorClassName,
  inputErrorClassName,
  inputGroupClassName,
  onClick,
  labelClassName,
  label,
  suffix,
  count,
}) => {
  const inputClasses = `form-control ${
    !!meta.touched && !!meta.error
      ? [inputClassName, inputErrorClassName].join(' ')
      : inputClassName
  }`
  const inputGroupClasses = `form-control w-full ${
    meta.touched && meta.error
      ? [inputGroupClassName, inputErrorClassName].join(' ')
      : inputGroupClassName
  }`
  const length = input.value.length
  const renderCharacterCount = (
    <div className="pt-1 flex flex-col w-full">
      <div className={`w-full h-2 rounded bg-secondary-graywhite`}>
        <div
          className={`h-full rounded ${
            length < count ? 'bg-primary-orange' : 'bg-free-speech-red'
          }`}
          style={{
            width: `${floor((length / count) * 100 > 100 ? 100 : (length / count) * 100)}%`,
          }}
        ></div>
      </div>
      <div className="count text-xs text-right text-primary-lightgray">{`(${length}/${count})`}</div>
    </div>
  )

  return (
    <div className={`form-group ${groupClassName}`}>
      <label className={`block ${labelClassName}`}>
        {label} {suffix}
      </label>
      <div className={inputGroupClasses}>
        <textarea
          {...input}
          className={inputClasses}
          type={type}
          onChange={(e) => {
            const value = e.target.value
            if ((count && value.length <= count) || !count || value.length < length) {
              input.onChange(value)
            }
          }}
          placeholder={placeholder}
          rows={rows}
          autoComplete="off"
          onClick={onClick}
          disabled={disabled}
        />
      </div>
      {count && renderCharacterCount}
      {meta.touched && meta.error && <p className={`form-error ${errorClassName}`}>{meta.error}</p>}
    </div>
  )
}

TextArea.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  rows: PropTypes.number,
  groupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  inputErrorClassName: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  suffix: PropTypes.string,
  count: PropTypes.number,
}

export default TextArea

import * as types from 'actions/bookings/types'
import { handleActions } from 'redux-actions'

const lineMinifier = (obj = {}) => {
  return {
    list: [],
    page: 1,
    lastPage: 0,
    total: 0,
    limit: 10,
    sort: { field: 'createDate', order: 'desc' },
    ...obj,
  }
}

// @TODO: refactor archive for repetetive header
const initialState = {
  client: {
    requests: {
      headers: [
        { name: '', field: 'dropdown' },
        { name: 'CREATE DATE', field: 'createDate' },
        { name: 'REQUEST TYPE', field: 'requestType' },
        { name: 'REQUEST DATE', field: 'requestDate' },
        { name: 'SPACE', field: 'space' },
        { name: 'STATUS', field: 'status' },
        { name: 'NEXT STEPS', field: 'actions', align: 'right' },
      ],
      ...lineMinifier(),
    },
    toPay: {
      headers: [
        { name: 'CREATE DATE', field: 'createDate' },
        { name: 'REQUEST DATE', field: 'requestDate' },
        { name: 'SPACE', field: 'space' },
        { name: 'AMOUNT', field: 'amount' },
        { name: 'STATUS', field: 'status' },
        { name: 'NEXT STEPS', field: 'actions', align: 'right' },
      ],
      ...lineMinifier(),
    },
    confirmed: {
      headers: [
        { name: 'CREATE DATE', field: 'createDate' },
        { name: 'REQUEST DATE', field: 'requestDate' },
        { name: 'SPACE', field: 'space' },
        { name: 'ADDRESS', field: 'address' },
        { name: 'AMOUNT', field: 'amount' },
        { name: 'STATUS', field: 'status' },
        { name: 'NEXT STEPS', field: 'actions', align: 'right' },
      ],
      ...lineMinifier(),
    },
    archive: {
      headers: [
        { name: '', field: 'dropdown' },
        { name: 'CREATE DATE', field: 'createDate' },
        { name: 'REQUEST TYPE', field: 'requestType' },
        { name: 'REQUEST DATE', field: 'requestDate' },
        { name: 'SPACE', field: 'space' },
        { name: 'STATUS', field: 'status' },
      ],
      ...lineMinifier(),
    },
  },
  merchant: {
    leads: {
      headers: [
        { name: '', field: 'dropdown', sortable: false },
        { name: 'CREATE DATE', field: 'createDate', sortable: true },
        { name: 'LEAD TYPE', field: 'leadType', sortable: false },
        { name: 'REQUEST DATE', field: 'requestDate', sortable: true },
        { name: 'CLIENT NAME', field: 'client', sortable: true },
        { name: 'SPACE', field: 'space', sortable: false },
        { name: 'STATUS', field: 'status', sortable: false },
        { name: 'NEXT STEPS', field: 'actions', sortable: false },
      ],
      ...lineMinifier(),
    },
    proposals: {
      headers: [
        { name: 'CREATE DATE', field: 'createDate', sortable: true },
        { name: 'REQUEST DATE', field: 'requestDate', sortable: false },
        { name: 'CLIENT NAME', field: 'client', sortable: true },
        { name: 'SPACE', field: 'space', sortable: false },
        { name: 'STATUS', field: 'status', sortable: false },
        { name: 'NEXT STEPS', field: 'actions', sortable: false },
      ],
      ...lineMinifier(),
    },
    paid: {
      headers: [
        { name: '', field: 'dropdown', sortable: false },
        { name: 'CREATE DATE', field: 'createDate', sortable: true },
        { name: 'BOOKING DATE', field: 'bookingDate', sortable: false },
        { name: 'CLIENT NAME', field: 'client', sortable: true },
        { name: 'SPACE', field: 'space', sortable: false },
        { name: 'STATUS', field: 'status', sortable: false },
        { name: 'AMOUNT', field: 'payment_amount', sortable: true },
        { name: 'NEXT STEPS', field: 'actions', sortable: false },
      ],
      ...lineMinifier(),
    },
    archive: {
      wonLeads: {
        headers: [
          { name: '', field: 'dropdown', sortable: false },
          { name: 'CREATE DATE', field: 'createDate', sortable: true },
          { name: 'BOOKING DATE', field: 'requestDate', sortable: false },
          { name: 'CLIENT NAME', field: 'client', sortable: true },
          { name: 'SPACE', field: 'space', sortable: false },
          { name: 'STATUS', field: 'status', sortable: false },
          { name: 'AMOUNT', field: 'amount', sortable: false },
        ],
        ...lineMinifier(),
      },
      lostInquiries: {
        headers: [
          { name: '', field: 'dropdown', sortable: false },
          { name: 'CREATE DATE', field: 'createDate', sortable: true },
          { name: 'REQUEST DATE', field: 'requestDate', sortable: true },
          { name: 'CLIENT NAME', field: 'client', sortable: true },
          { name: 'SPACE', field: 'space', sortable: false },
          { name: 'STATUS', field: 'status', sortable: false },
        ],
        ...lineMinifier(),
      },
      lostTransactions: {
        headers: [
          { name: '', field: 'dropdown', sortable: false },
          { name: 'CREATE DATE', field: 'createDate', sortable: true },
          { name: 'REQUEST DATE', field: 'requestDate', sortable: true },
          { name: 'CLIENT NAME', field: 'client', sortable: true },
          { name: 'SPACE', field: 'space', sortable: false },
          { name: 'STATUS', field: 'status', sortable: false },
        ],
        ...lineMinifier(),
      },
      unrespondedLeads: {
        headers: [
          { name: '', field: 'dropdown', sortable: false },
          { name: 'CREATE DATE', field: 'createDate', sortable: true },
          { name: 'REQUEST DATE', field: 'requestDate', sortable: true },
          { name: 'CLIENT NAME', field: 'client', sortable: true },
          { name: 'SPACE', field: 'space', sortable: false },
          { name: 'STATUS', field: 'status', sortable: false },
        ],
        ...lineMinifier(),
      },
    },
    passport: {
      headers: [
        { name: '', field: 'dropdown' },
        { name: 'CREATE DATE', field: 'createDate', sortable: true },
        { name: 'BOOKING DATE', field: 'bookingDate', sortable: true },
        { name: 'CLIENT NAME', field: 'client', sortable: true },
        { name: 'SPACE', field: 'space', sortable: false },
        { name: 'NO OF GUESTS', field: 'noOfGuests', sortable: false },
        { name: 'STATUS', field: 'status', sortable: false },
        { name: 'NEXT STEPS', field: 'actions', align: 'right', sortable: false },
      ],
      ...lineMinifier(),
    },
  },
  bookingDetails: {},
  proposalDetails: {},
  proposalOptions: {
    spaces: [],
  },
  fetching: false,
  processing: false,
  admin: {
    headers: [
      { name: 'ID', field: 'id' },
      { name: 'LEAD ID', field: 'leadId' },
      { name: 'NAME', field: 'name' },
      { name: 'SPACE/MERCHANT', field: 'spaceMerchant' },
      { name: 'CITY', field: 'city' },
      { name: 'TERM', field: 'term' },
      { name: 'CHECK-IN', field: 'checkIn' },
      { name: 'CHECK-OUT', field: 'checkOut' },
      { name: 'GUESTS', field: 'guests' },
      { name: 'BOOKING STATUS', field: 'bookingStatus' },
      { name: 'TRANSACTION STATUS', field: 'transactionStatus' },
      { name: 'PAYMENT METHOD', field: 'paymentMethod' },
      { name: 'STRIPE STATUS', field: 'stripeStatus' },
      { name: 'AMOUNT', field: 'amount' },
      { name: 'SUBSCRIPTION', field: 'subscription' },
      { name: '', field: '' },
    ],
    data: [],
    fetching: false,
    page: 1,
    lastPage: 0,
    total: 0,
    limit: 10,
    details: {},
  }
}

const bookingsReducer = handleActions(
  {
    [types.FETCH_CLIENT_REQUESTS](state) {
      return {
        ...state,
        client: {
          ...state.client,
          requests: {
            ...state.client.requests,
            list: [],
          },
        },
        fetching: true,
      }
    },
    [types.FETCHED_CLIENT_REQUESTS](state, { list }) {
      return {
        ...state,
        client: {
          ...state.client,
          requests: {
            ...state.client.requests,
            list,
          },
        },
        fetching: false,
      }
    },
    [types.FETCH_CLIENT_TO_PAY](state) {
      return {
        ...state,
        client: {
          ...state.client,
          toPay: {
            ...state.client.toPay,
            list: [],
          },
        },
        fetching: true,
      }
    },
    [types.FETCHED_CLIENT_TO_PAY](state, { list }) {
      return {
        ...state,
        client: {
          ...state.client,
          toPay: {
            ...state.client.toPay,
            list,
          },
        },
        fetching: false,
      }
    },
    [types.FETCH_CLIENT_CONFIRMED](state) {
      return {
        ...state,
        client: {
          ...state.client,
          confirmed: {
            ...state.client.confirmed,
            list: [],
          },
        },
        fetching: true,
      }
    },
    [types.FETCHED_CLIENT_CONFIRMED](state, { list }) {
      return {
        ...state,
        client: {
          ...state.client,
          confirmed: {
            ...state.client.confirmed,
            list,
          },
        },
        fetching: false,
      }
    },
    [types.FETCH_CLIENT_ARCHIVE](state) {
      return {
        ...state,
        client: {
          ...state.client,
          archive: {
            ...state.client.archive,
            list: [],
          },
        },
        fetching: true,
      }
    },
    [types.FETCHED_CLIENT_ARCHIVE](state, { list }) {
      return {
        ...state,
        client: {
          ...state.client,
          archive: {
            ...state.client.archive,
            list,
          },
        },
        fetching: false,
      }
    },
    [types.ARCHIVED_CLIENT_REQUEST](state, { payload: { id } }) {
      return {
        ...state,
        client: {
          ...state.client,
          requests: {
            ...state.client.requests,
            list: state.client.requests.list.filter(value => value.id !== id),
          },
        },
      }
    },
    [types.CANCELLED_CLIENT_REQUEST](state, { id }) {
      return {
        ...state,
        client: {
          ...state.client,
          requests: {
            ...state.client.requests,
            list: state.client.requests.list.filter(value => value.id !== id),
          },
        },
      }
    },
    [types.PAY_CLIENT_PROPOSAL](state) {
      return {
        ...state,
        processing: true,
      }
    },
    [types.PAID_CLIENT_PROPOSAL](state, { id, paymentType }) {
      const allowedPaymentType = ['credit-card'] // this will trigger remove the item in reducer

      return {
        ...state,
        client: {
          ...state.client,
          toPay: {
            ...state.client.toPay,
            list: allowedPaymentType.includes(paymentType) ? state.client.toPay.list.filter(value => value.id !== id) : state.client.toPay.list,
          },
        },
        processing: false,
      }
    },
    [types.FETCH_CLIENT_PROPOSAL_PAYMENT_DETAILS](state) {
      return {
        ...state,
        bookingDetails: {},
      }
    },
    [types.FETCHED_CLIENT_PROPOSAL_PAYMENT_DETAILS](state, { details }) {
      return {
        ...state,
        bookingDetails: details,
      }
    },
    [types.FETCH_MERCHANT_LEADS](state) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          leads: {
            ...state.merchant.leads,
            list: [],
          },
        },
        fetching: true,
      }
    },
    [types.FETCHED_MERCHANT_LEADS](state, { list, total }) {
      // const { data, page, total, lastPage, limit } = response

      return {
        ...state,
        merchant: {
          ...state.merchant,
          leads: {
            ...state.merchant.leads,
            list,
            total,
          },
        },
        fetching: false,
      }
    },
    [types.PAGE_MERCHANT_LEADS](state, { payload: pagination }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          leads: {
            ...state.merchant.leads,
            ...pagination,
          },
        },
      }
    },
    [types.SORT_MERCHANT_LEADS](state, { payload: sort }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          leads: {
            ...state.merchant.leads,
            sort,
          }
        }
      }
    },
    [types.FETCH_MERCHANT_PROPOSALS](state) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          proposals: {
            ...state.merchant.proposals,
            list: [],
          },
        },
        fetching: true,
      }
    },
    [types.PAGE_MERCHANT_PROPOSALS](state, { payload: pagination }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          proposals: {
            ...state.merchant.proposals,
            ...pagination,
          },
        },
      }
    },
    [types.SORT_MERCHANT_PROPOSALS](state, { payload: sort }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          proposals: {
            ...state.merchant.proposals,
            sort,
          }
        }
      }
    },
    [types.FETCHED_MERCHANT_PROPOSALS](state, { list, total }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          proposals: {
            ...state.merchant.proposals,
            list,
            total,
          },
        },
        fetching: false,
      }
    },
    [types.FETCH_MERCHANT_PAID](state) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          paid: {
            ...state.merchant.paid,
            list: [],
          },
        },
        fetching: true,
      }
    },
    [types.PAGE_MERCHANT_PAID](state, { payload: pagination }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          paid: {
            ...state.merchant.paid,
            ...pagination,
          },
        },
      }
    },
    [types.SORT_MERCHANT_PAID](state, { payload: sort }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          paid: {
            ...state.merchant.paid,
            sort,
          }
        }
      }
    },
    [types.FETCHED_MERCHANT_PAID](state, { list, total }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          paid: {
            ...state.merchant.paid,
            list,
            total
          },
        },
        fetching: false,
      }
    },
    [types.FETCH_MERCHANT_WON_ARCHIVED_LEADS](state) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            wonLeads: {
              ...state.merchant.archive.wonLeads,
              list: [],
            },
          },
        },
        fetching: true,
      }
    },
    [types.FETCH_MERCHANT_LOST_ARCHIVED_INQUIRIES](state) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            lostInquiries: {
              ...state.merchant.archive.lostInquiries,
              list: [],
            },
          },
        },
        fetching: true,
      }
    },
    [types.FETCH_MERCHANT_LOST_ARCHIVED_TRANSACTIONS](state) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            lostTransactions: {
              ...state.merchant.archive.lostTransactions,
              list: [],
            },
          },
        },
        fetching: true,
      }
    },
    [types.FETCH_MERCHANT_UNRESPONDED_ARCHIVED_LEADS](state) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            unrespondedLeads: {
              ...state.merchant.archive.unrespondedLeads,
              list: [],
            },
          },
        },
        fetching: true,
      }
    },
    [types.PAGE_MERCHANT_ARCHIVE](state, { payload: { wonLeads, lostInquiries, lostTransactions, unrespondedLeads } }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            wonLeads: {
              ...state.merchant.archive.wonLeads,
              ...wonLeads
            },
            lostTransactions: {
              ...state.merchant.archive.lostTransactions,
              ...lostTransactions
            },
            lostInquiries: {
              ...state.merchant.archive.lostInquiries,
              ...lostInquiries
            },
            unrespondedLeads: {
              ...state.merchant.archive.unrespondedLeads,
              ...unrespondedLeads
            },
          },
        },
      }
    },
    [types.SORT_MERCHANT_ARCHIVE](state, { payload: { sort, sub } }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            [sub]: {
              ...state.merchant.archive[sub],
              sort,
            }
          },
        },
      }
    },
    [types.FETCHED_MERCHANT_WON_ARCHIVED_LEADS](state, { leads, total }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            wonLeads: {
              ...state.merchant.archive.wonLeads,
              list: leads,
              total,
            },
          },
        },
        fetching: false,
      }
    },
    [types.FETCHED_MERCHANT_LOST_ARCHIVED_INQUIRIES](state, { leads, total }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            lostInquiries: {
              ...state.merchant.archive.lostInquiries,
              list: leads,
              total,
            },
          },
        },
        fetching: false,
      }
    },
    [types.FETCHED_MERCHANT_LOST_ARCHIVED_TRANSACTIONS](state, { leads, total }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            lostTransactions: {
              ...state.merchant.archive.lostTransactions,
              list: leads,
              total,
            },
          },
        },
        fetching: false,
      }
    },
    [types.FETCHED_MERCHANT_UNRESPONDED_ARCHIVED_LEADS](state, { leads, total }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          archive: {
            ...state.merchant.archive,
            unrespondedLeads: {
              ...state.merchant.archive.unrespondedLeads,
              list: leads,
              total,
            },
          },
        },
        fetching: false,
      }
    },
    [types.FETCH_MERCHANT_PASSPORT_BOOKINGS](state) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          passport: {
            ...state.merchant.passport,
            list: [],
          },
        },
        fetching: true,
      }
    },
    [types.PAGE_MERCHANT_PASSPORT_BOOKING](state, { payload: pagination }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          passport: {
            ...state.merchant.passport,
            ...pagination,
          },
        },
      }
    },
    [types.SORT_MERCHANT_PASSPORT_BOOKING](state, { payload: sort }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          passport: {
            ...state.merchant.passport,
            sort,
          }
        }
      }
    },
    [types.FETCHED_MERCHANT_PASSPORT_BOOKINGS](state, { list, total }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          passport: {
            ...state.merchant.passport,
            list,
            total,
          },
        },
        fetching: false,
      }
    },
    [types.FETCH_MERCHANT_PROPOSAL_OPTIONS](state) {
      return {
        ...state,
        proposalOptions: {
          spaces: [],
        },
      }
    },
    [types.FETCHED_MERCHANT_PROPOSAL_OPTIONS](state, { details }) {
      return {
        ...state,
        proposalOptions: details,
      }
    },
    [types.FETCH_MERCHANT_PROPOSAL_DETAILS](state) {
      return {
        ...state,
        proposalDetails: {},
      }
    },
    [types.FETCHED_MERCHANT_PROPOSAL_DETAILS](state, { details }) {
      return {
        ...state,
        proposalDetails: details,
      }
    },
    [types.ACCEPTED_MERCHANT_LEAD](state, { payload: { id } }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          leads: {
            ...state.merchant.leads,
            list: state.merchant.leads.list.map(value => value.id === id ? { ...value, status: 'accepted' } : value),
          },
        },
      }
    },
    [types.ARCHIVED_MERCHANT_LEAD](state, { payload: { id } }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          leads: {
            ...state.merchant.leads,
            list: state.merchant.leads.list.filter(value => value.id !== id),
          },
        },
      }
    },
    [types.MERCHANT_CONFIRMED_PASSPORT_BOOKING](state, { payload: { id } }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          passport: {
            ...state.merchant.passport,
            list: state.merchant.passport.list.map(value => value.id === id ? {
                    ...value,
              status: 'confirmed'
            } : value),
          },
        },
      }
    },
    [types.MERCHANT_CANCELLED_SPACE](state, { id }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          leads: {
            ...state.merchant.leads,
            list: state.merchant.leads.list.filter(value => value.id !== id),
          },
        },
      }
    },
    [types.DECLINED_MERCHANT_LEAD](state, { id }) {
      return {
        ...state,
        merchant: {
          ...state.merchant,
          leads: {
            ...state.merchant.leads,
            list: state.merchant.leads.list.filter(value => value.id !== id),
          },
        },
      }
    },
    [types.FETCHED_CLIENT_PROPOSAL_DETAILS](state, { details }) {
      return {
        ...state,
        proposalDetails: details
      }
    },
    // admin
    [types.ADMIN_FETCHED_BOOKINGS](state, { data }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          ...data
        }
      }
    },
    [types.ADMIN_FETCHED_BOOKING_DETAILS](state, { data }) {
      return {
        ...state,
        admin: {
          ...state.admin,
          details: data
        }
      }
    },
    [types.EXPORT_BOOKINGS](state) {
      return {
        ...state,
        fetching: true
      }
    },
    [types.EXPORTED_BOOKINGS](state) {
      return {
        ...state,
        fetching: false
      }
    },
  },
  initialState,
)

export default bookingsReducer

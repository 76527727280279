export const FETCH_PLACES = 'fetchPlaces'
export const FETCHED_PLACES = 'fetchedPlaces'
export const FETCH_PLACE = 'fetchPlace'
export const FETCHED_PLACE = 'fetchedPlace'

export const CREATE_PLACE = 'createPlace'
export const CREATED_PLACE = 'createdPlace'
export const EDIT_PLACE = 'editPlace'
export const EDITED_PLACE = 'editedPlace'

export const DELETE_PLACE = 'deletePlace'

export const FETCH_PLACE_OPTIONS = 'fetchPlaceOptions'
export const FETCHED_PLACE_OPTIONS = 'fetchedPlaceOptions'

export const FETCH_PLACE_SPACE_COUNT = 'fetchPlaceSpaceCount'
export const FETCHED_PLACE_SPACE_COUNT = 'fetchedPlaceSpaceCount'

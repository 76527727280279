import api, { apiConfig } from 'constants/api'
import moment from 'moment'
import FileDownload from 'js-file-download'

import { all, select, takeLatest, put, call, delay } from 'redux-saga/effects'
import * as types from 'actions/spaces/types'
import * as notifTypes from 'actions/notifications/types'
import formSelector from './selectors/form'

const apiAdmin = apiConfig({ baseURL: '/api/admin' })

const apiMerchant = apiConfig({ baseURL: '/api/merchant' })

const configIntance = (config) => {
  return apiConfig(config)
}

const compare = (key) => (a, b) => {
  if (a[key] < b[key]) return -1
  if (a[key] > b[key]) return 1
  return 0
}

function* getFeaturedPassportSpaces({ payload }) {
  try {
    const { data } = yield call(api.get, `/passport/featured-spaces/${payload}`)
    yield put({ type: types.FETCHED_FEATURED_PASSPORT_SPACES, spaces: data.spaces })
  } catch (error) {
    console.log(error)
  }
}

function* getSpace({ payload }) {
  try {
    const { data } = yield call(api.get, `/spaces/${payload}/detail`)
    const { spaceInfo } = data
    spaceInfo.reviews = spaceInfo.reviews.sort((a, b) => b.id - a.id)
    const { reviews } = spaceInfo
    const reviewsFormat = reviews.map((review) => {
      return {
        ...review,
        date: moment(review.date).format('MMMM YYYY'),
      }
    })
    yield all([
      // other functions later
      put({ type: types.FETCHED_SPACE, spaceInfo: { ...spaceInfo, reviews: reviewsFormat } }),
    ])
  } catch ({ response }) {
    const { message } = response.data
    console.warn(message)
    // return empty space info
    yield put({ type: types.FETCHED_SPACE, spaceInfo: null })
  }
}

function* getSpaceType({ payload }) {
  try {
    const { data } = yield call(api.get, `/spaces/${payload}/type`)

    yield put({ type: types.FETCHED_SPACE_TYPE, isPassport: data.isPassport, link: data.link })
  } catch ({ response }) {
    yield put({ type: types.FETCHED_SPACE_TYPE, isPassport: null, link: null })
  }
}

function* fetchSpacesVenue({ payload }) {
  try {
    const { data } = yield call(api.get, `/spaces/options/${payload}`)
    const spaces = data.spaces
    spaces.map((space) => {
      space.link = '/'
      return space
    })
    yield put({ type: types.FETCHED_SPACES_VENUE, spaces: data.spaces })
  } catch ({ response }) {
    console.log(response)
  }
}

function* getPassportSpace({ payload }) {
  try {
    const { data } = yield call(api.get, `passport/spaces/${payload}`)
    const { spaceInfo } = data
    spaceInfo.reviews = spaceInfo.reviews.sort((a, b) => b.id - a.id)
    const { reviews = [] } = spaceInfo
    const reviewsFormat = reviews.map((review) => {
      return {
        ...review,
        date: moment(review.date).format('MMMM YYYY'),
      }
    })
    yield all([
      // other functions later
      put({ type: types.FETCHED_SPACE, spaceInfo: { ...spaceInfo, reviews: reviewsFormat } }),
    ])
  } catch ({ message }) {
    console.warn(message)
    // return empty space info
    yield put({ type: types.FETCHED_SPACE, spaceInfo: null })
  }
}

function* uploadImage(file, order) {
  const type = file.type
  const { data } = yield call(api.post, '/upload/get-presigned-url', {
    file_name: file.name,
    type,
  })
  const blob = new Blob([file], { type })
  const amzConfig = {
    headers: {
      'Content-Type': type,
    },
  }
  yield call(configIntance(amzConfig).put, data.url, blob)
  return { path: data.distribute_url.split('?')[0], order }
}

function* multipleUpload(files) {
  const effects = []
  for (let i = 0; i < files.length; i++) {
    effects.push(call(uploadImage, files[i], i + 1))
  }
  const results = yield all(effects)
  return results
}

function* createNewSpace({ payload }) {
  try {
    const files = payload.files || []
    const images = yield call(multipleUpload, files)
    if (images.length) {
      payload.images = images
    } else {
      payload.images = payload.images || []
    }
    delete payload.files

    payload.tag_ids = (payload.tags || []).map((t) => t.value)
    const { data } = yield call(apiMerchant.post, '/spaces', payload)
    const space = data.space
    space.link = '/'
    yield put({ type: notifTypes.SUCCESS, message: 'Success edit space.' })
    yield put({ type: types.NEW_VENUE_SPACE, space })
  } catch (error) {
    const response = error.response || {}
    yield put({ type: types.SPACE_DISABLE_LOADING })
    const { data } = response
    const message =
      data && data.length
        ? data.map((val) => val.message).join('\n')
        : data.error || data.message || 'Error create space.'
    yield put({ type: notifTypes.ERROR, message })
  }
}

function* editSpace({ payload }) {
  try {
    const files = payload.files || []
    const images = yield call(multipleUpload, files)
    if (images.length) {
      payload.images = images
    } else {
      payload.images = payload.images || []
    }
    delete payload.files
    payload.tag_ids = (payload.tags || []).map((t) => t.value)
    yield call(apiMerchant.put, `/spaces/${payload.spaceId}`, payload)
    yield put({ type: types.EDITED_SPACE })
    yield put({ type: notifTypes.SUCCESS, message: 'Success edit space.' })
  } catch ({ response }) {
    yield put({ type: types.SPACE_DISABLE_LOADING })
    const { data } = response
    const message =
      data && data.length
        ? data.map((val) => val.message).join('\n')
        : data.error || data.message || 'Error edit space.'
    yield put({ type: notifTypes.ERROR, message })
  }
}

function* editSpacePublish({ payload }) {
  try {
    const { publish, id } = payload
    const action = publish ? 'publish' : 'unpublish'
    yield call(apiMerchant.put, `/spaces/${id}/${action}`)
    yield put({ type: types.EDITED_SPACE_PUBLISH, data: { id, publish } })
  } catch (error) {
    console.log(error)
  }
}

function* copySpace({ payload }) {
  console.log(`start copy spaces`)
  console.log(payload)
  try {
    yield call(apiAdmin.get, `/spaces/${payload}/clone`)
    yield put({ type: types.FETCH_SPACES, payload: {} })
  } catch (error) {
    console.log(error)
  }
}

function* getSpaceDetails({ payload }) {
  try {
    const { data } = yield call(apiMerchant.get, `/spaces/${payload}`)
    yield put({ type: types.FETCHED_SPACE_DETAILS, space: data })
  } catch ({ response }) {
    const { message } = response.data
    console.warn(message)
    // return empty space info
    yield put({ type: types.FETCHED_SPACE, spaceInfo: null })
  }
}

// ADMIN
function* getSpaces({ payload }) {
  try {
    const selector = yield formSelector('admin-spaces-filter-form')
    const type = yield select((state) => selector(state, 'type_filter'))
    const typeFilter = type && type !== '*' ? type : undefined
    const places = yield select((state) => selector(state, 'place_filter'))
    const placesFilter = places ? places.map((place) => place.value) : []
    const venues = yield select((state) => selector(state, 'venue_filter'))
    const venuesFilter = venues ? venues.map((venue) => venue.value) : []
    const categories = yield select((state) => selector(state, 'category_filter'))
    const categoriesFilter = categories ? categories.map((category) => category.value) : []
    const merchants = yield select((state) => selector(state, 'merchant_filter'))
    const merchantsFilter = merchants ? merchants.map((merchant) => merchant.value) : []
    const status = yield select((state) => selector(state, 'status_filter'))
    const statusFilter = status && status !== '*' ? status : undefined
    const featured = yield select((state) => selector(state, 'featured_filter'))
    const featuredFilter = featured ? featured.map((f) => f.value) : []
    const searchBar = yield select((state) => selector(state, 'admin_search_filter'))
    const { limit, page } = payload

    const { data } = yield call(apiAdmin.get, '/spaces', {
      params: {
        limit,
        page,
        typeFilter,
        placesFilter,
        categoriesFilter,
        merchantsFilter,
        statusFilter,
        featuredFilter,
        venuesFilter,
        search_bar: searchBar,
      },
    })

    yield delay(300) // to simulate tableloader
    yield put({ type: types.FETCHED_SPACES, response: data.spaces })
  } catch (error) {
    console.log(error)
  }
}

function* adminGetSpaceDetails({ payload }) {
  try {
    const { data } = yield call(apiAdmin.get, `/spaces/${payload}`)
    const { space } = data
    space.images = data.space.images.sort(compare('order'))
    space.spaceFunctions = data.space.tags.map((t) => ({ value: t.id, label: t.name }))
    space.merchant_id = space.venue.user.id
    space.merchant_name = space.venue.user.name
    yield put({ type: types.FETCHED_SPACE_DETAILS, space })
  } catch ({ message }) {
    console.error(message)
  }
}

const getDate = (date, currentDateFormat, outputFormat) => {
  const isValid = isNaN(new Date(date).getTime())
  if (!isValid) {
    return moment(date, currentDateFormat).format(outputFormat)
  }
  return moment(date, currentDateFormat).format(outputFormat)
}
function* adminEditSpace({ payload }) {
  const { values, formName } = payload
  const date_available = getDate(values.date_available, 'DD/MM/YYYY', 'YYYY/MM/DD')
  const requestBody = {
    ...values,
    date_available,
  }

  if (date_available.toLowerCase() === 'invalid date') {
    requestBody.date_available = ''
  }

  try {
    requestBody.tags = Array.isArray(requestBody.spaceFunctions)
      ? requestBody.spaceFunctions.map((v) => v.value)
      : []
    yield call(apiAdmin.put, `/spaces/${requestBody.id}`, requestBody)
    yield put({ type: notifTypes.SUCCESS, message: 'Success edit space.' })
    yield put({ type: types.ADMIN_FETCH_SPACE_DETAILS, payload: requestBody.id })
  } catch ({ message, response }) {
    const errors = { errors: response?.data || [], formName }
    yield put({ type: notifTypes.ERROR, message: message ?? 'Error edit space', payload: errors })
  }
}

function* adminCreateSpace({ payload }) {
  const { values, formName } = payload
  try {
    values.images = values.images || []
    values.tags = Array.isArray(values.spaceFunctions)
      ? values.spaceFunctions.map((v) => v.value)
      : []
    yield call(apiAdmin.post, `/spaces`, values)
    yield put({ type: notifTypes.SUCCESS, message: 'Success create space.' })
  } catch ({ response, message }) {
    const { data } = response
    const errors = { errors: data || [], formName }
    const imageError =
      data && data.find((error) => error.field === 'images' && error.validation === 'required')
    yield put({
      type: notifTypes.ERROR,
      message: imageError?.message ?? 'Error create space',
      payload: errors,
    })
  }
}

function* fetchSpaceFeatured({ payload }) {
  try {
    const { cityId, categoryId, page, brandId, city } = payload
    const { data } = yield call(api.get, `/spaces/featured-homepage`, {
      params: {
        cityId,
        categoryId,
        brandId,
        city,
      },
    })
    const { results } = data
    yield put({ type: types.FETCHED_SPACE_FEATURED, list: results, page })
  } catch ({ response }) {
    if (response) console.error(response.message)
  }
}

function* deleteSpace({ payload }) {
  try {
    yield call(apiAdmin.delete, `/spaces/${payload.space_id}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Success delete space.' })
    yield call(fetchSpacesVenue, { payload: payload.venue_id })
  } catch ({ message }) {
    yield put({ type: notifTypes.ERROR, message: 'Error delete space.' })
  }
}

function* adminDeleteSpace({ payload }) {
  const { limit, page, deleteId } = payload
  try {
    yield call(apiAdmin.delete, `/spaces/${deleteId}`)
    yield put({ type: notifTypes.SUCCESS, message: 'Success delete space.' })
    yield call(getSpaces, { payload: { limit, page } })
  } catch ({ message }) {
    yield put({ type: notifTypes.ERROR, message: 'Error delete space.' })
  }
}

function* exportSpaces() {
  try {
    const selector = yield formSelector('admin-spaces-filter-form')
    const searchBar = yield select((state) => selector(state, 'admin_search_filter'))
    const type = yield select((state) => selector(state, 'type_filter'))
    const typeFilter = type && type !== '*' ? type : undefined
    const places = yield select((state) => selector(state, 'place_filter'))
    const placesFilter = places ? places.map((place) => place.value) : []
    const venues = yield select((state) => selector(state, 'venue_filter'))
    const venuesFilter = venues ? venues.map((venue) => venue.value) : []
    const categories = yield select((state) => selector(state, 'category_filter'))
    const categoriesFilter = categories ? categories.map((category) => category.value) : []
    const merchants = yield select((state) => selector(state, 'merchant_filter'))
    const merchantsFilter = merchants ? merchants.map((merchant) => merchant.value) : []
    const status = yield select((state) => selector(state, 'status_filter'))
    const statusFilter = status && status !== '*' ? status : undefined
    const featured = yield select((state) => selector(state, 'featured_filter'))
    const featuredFilter = featured ? featured.map((f) => f.value) : []

    const response = yield call(apiAdmin.get, '/export-spaces', {
      responseType: 'arraybuffer',
      params: {
        typeFilter,
        placesFilter,
        search_bar: searchBar,
        categoriesFilter,
        merchantsFilter,
        statusFilter,
        featuredFilter,
        venuesFilter,
      },
    })
    FileDownload(response.data, 'spaces.xlsx')
    yield put({ type: types.EXPORTED_SPACES })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error Export Spaces' })
  }
}

function* inquireSubmit({ payload }) {
  const { values, resolve, formName, options, resetForm } = payload

  try {
    const { data } = yield call(api.post, `/hubspot-${options}-submit`, values)
    yield put({
      type: notifTypes.SUCCESS,
      message: data.message ?? 'Thank you for your submission',
    })
    resetForm()
    resolve()
  } catch ({ response, message }) {
    const { data } = response
    const errors = { errors: data || [], formName }
    yield put({
      type: notifTypes.ERROR,
      message: 'Error submit form',
      payload: errors,
    })
  }
}

export default function* () {
  yield takeLatest(types.FETCH_SPACES, getSpaces)
  yield takeLatest(types.ADMIN_CREATE_SPACE, adminCreateSpace)
  yield takeLatest(types.ADMIN_EDIT_SPACE, adminEditSpace)
  yield takeLatest(types.ADMIN_FETCH_SPACE_DETAILS, adminGetSpaceDetails)
  yield takeLatest(types.FETCH_SPACE_DETAILS, getSpaceDetails)
  yield takeLatest(types.FETCH_FEATURED_PASSPORT_SPACES, getFeaturedPassportSpaces)
  yield takeLatest(types.FETCH_SPACE, getSpace)
  yield takeLatest(types.CREATE_NEW_SPACE, createNewSpace)
  yield takeLatest(types.EDIT_SPACE, editSpace)
  yield takeLatest(types.FETCH_SPACE_TYPE, getSpaceType)
  yield takeLatest(types.FETCH_PASSPORT_SPACE, getPassportSpace)
  yield takeLatest(types.FETCH_SPACES_VENUE, fetchSpacesVenue)
  yield takeLatest(types.EDIT_SPACE_PUBLISH, editSpacePublish)
  yield takeLatest(types.COPY_SPACE, copySpace)
  // yield takeLatest(types.SPACE_SUBMIT, createSpace); @@ - this code is not used
  // yield takeLatest('@@redux-form/CHANGE', headerSlugUpdater); @@ - this code is not used
  // yield takeLatest(merchantTypes.FETCHED_MERCHANTS, getMerchantById); @@ - this code is not used
  // yield takeLatest(types.SORT_SPACE_IMAGES, sortImages); @@ - this code is not used
  yield takeLatest(types.DELETE_SPACE, deleteSpace)
  yield takeLatest(types.FETCH_SPACE_FEATURED, fetchSpaceFeatured)
  yield takeLatest(types.EXPORT_SPACES, exportSpaces)
  yield takeLatest(types.INQUIRE_SUBMIT, inquireSubmit)
  yield takeLatest(types.ADMIN_DELETE_SPACE, adminDeleteSpace)
}

import React from 'react';
import { Field } from 'redux-form';
import InputField from 'forms/shared/fields/input'
import CheckboxField from 'forms/shared/fields/checkbox'

const inputErrorClass = `border-red-500 leading-tight`
const checkBoxClass = `mr-2 shadow-sm rounded-none border text-gray-700 leading-tight focus:border-0 focus:outline-none focus:shadow-outline`
const checkBoxLabel = `block text-gray-700 text-sm`

const LoginFields = () => (
  <>
    <Field
      type="text"
      name="email"
      groupClassName="my-4"
      placeHolder="Email"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <Field
      type="password"
      name="password"
      groupClassName="mt-4 mb-2"
      placeHolder="Password"
      inputClassName="block border text-sm border-grey-light w-full p-3 rounded-lg"
      inputGroupClassName=""
      inputErrorClassName="border-action-danger"
      errorClassName="text-action-danger text-xs slab"
      component={InputField}
    />
    <div className="flex justify-between items-center">
      <Field
        type={'checkbox'}
        name={'staySignedIn'}
        label={'Stay signed in'}
        groupClassName="flex items-center justify-start"
        labelClassName={checkBoxLabel}
        inputClassName={checkBoxClass}
        inputErrorClassName={inputErrorClass}
        errorClassName="text-red-500 text-xs italic"
        component={CheckboxField}
      />
      <a className="text-xs capitalize hover:opacity-75" href="/recovery">
        Forgot your password?
      </a>
    </div>
  </>
);

export default LoginFields;

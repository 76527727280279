import React from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import hasDom from 'utils/hasDom'

const portal = hasDom && document.getElementById('overlay-portal')

const OverlayPortal = ({ children }) => createPortal(
  children,
  portal)

const Overlay = ({ children, onClose = () => {} }) => (
  <OverlayPortal>
    <div
      onClick={e => {
        e.stopPropagation()
        if (e.target === e.currentTarget) { onClose() }
      }}
      className="overlay fixed w-full h-full top-0 bottom-0 flex items-center justify-center left-0 right-0 z-50 overflow-auto">
    </div>
    <div className="modal fixed w-full h-full top-0 bottom-0 items-center justify-center left-0 right-0 z-50">{children}</div>
  </OverlayPortal>
)

Overlay.propTypes = {
  children: PropTypes.node,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default Overlay

/* eslint-disable */
import api, { apiConfig } from 'constants/api'
import { takeLatest, put, call, delay, select, all } from 'redux-saga/effects'
import moment from 'moment'
import * as types from 'actions/passport/types'
import * as userTypes from 'actions/users/types'
import * as notifTypes from 'actions/notifications/types'
import kebabCase from 'lodash/kebabCase'
import formSelector from './selectors/form'
import FileDownload from 'js-file-download'

const apiClient = apiConfig({ baseURL: '/api/client' })
const apiAdmin = apiConfig({ baseURL: '/api/admin' })

function* searchPassport({ payload }) {
  try {
    const statePage = yield select((state) => state.passport.search.page)
    const stateLimit = yield select((state) => state.passport.search.limit)

    const { page: payloadPage = statePage, limit: payloadLimit = stateLimit } = payload
    const selector = yield formSelector('public-passport-search-filter-form')

    const searchKeyword = yield select((state) => selector(state, 'filter_search'))
    const city = kebabCase(yield select((state) => selector(state, 'selectedCity')))
    const map = yield select((state) => selector(state, 'filter_map'))
    const lat = map ? map[0] : null
    const lng = map ? map[1] : null

    const { data } = yield call(api.get, `/passport-listing/${city}`, {
      params: {
        page: payloadPage,
        limit: payloadLimit,
        latQuery: lat,
        lngQuery: lng,
        keyword: searchKeyword,
      },
    })

    const { lastPage, page, perPage, total } = data
    yield put({
      type: types.SEARCHED_PASSPORT,
      list: data.data,
      lastPage,
      page,
      limit: perPage,
      total,
    })
  } catch ({ response }) {
    console.warn(response)
  }
}

function* getPassportBookings({ payload }) {
  try {
    const { page, limit } = yield select((state) => state.passport.bookings.owner)
    const { page: guestPage, limit: guestLimit } = yield select((state) => state.passport.bookings.guest)

    const { userId, page: payloadPage = page, limit: payloadLimit = limit, guestPage: payloadGuestPage = guestPage, payloadGuestLimit = guestLimit } = payload
    const { data: result } = yield call(apiClient.get, `/passport-bookings/${userId}`, {
      params: {
        page: payloadPage,
        limit: payloadLimit,
        guestPage: payloadGuestPage,
        guestLimit: payloadGuestLimit,
      },
    })

    yield delay(200)
    const { bookings, guestBookings } = result;
    if (guestBookings) {
      guestBookings.list = guestBookings.data
    }

    yield put({
      type: types.FETCHED_PASSPORT_BOOKINGS,
      bookings: {
        list: bookings.data,
        lastPage: bookings.lastPage,
        page: bookings.page,
        limit: bookings.perPage,
        total: bookings.total,
      },
      guestBookings,
    })
  } catch (response) {
    console.error(response)
  }
}

function* getBookingMembers({ payload }) {
  try {
    const { data } = yield call(apiClient.get, `/passport-members/${payload.id}`)
    yield put({
      type: types.FETCHED_BOOKING_MEMBERS, payload: {
        bookingDate: data.transaction.bookingDate,
        list: data.transaction.members,
        numberOfBooking: data.transaction.numberOfBooking,
        space: data.transaction.space,
        spaceUrl: data.transaction.spaceUrl,
        status: data.transaction.status,
      }
    })
  } catch ({ response }) {
    response && console.warn(response)
  }
}

function* getPassportPlanDetails({ payload: { plan, place } }) {
  try {
    const { data } = yield call(api.get, `/passport-plan/${plan}/${place}`)
    yield put({ type: types.FETCHED_PASSPORT_PLAN_DETAILS, planDetails: data.plan })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, ...response.data })
  }
}

function* payPassportSubscription({
  payload: { place, plan, user, card, promo_code, invitationId },
}) {
  try {
    const { id: userId } = user
    const date = moment().format('YYYY-MM-DD hh:mm:ss')
    console.log('check', place, plan, userId, card, promo_code, date)
    const { data } = yield call(api.post, `passport-subscriptions`, {
      place,
      plan,
      userId,
      card,
      promo_code,
      date,
      invitationId,
    })
    yield all([
      put({ type: notifTypes.SUCCESS, ...data }),
      put({ type: types.PAID_PASSPPORT_SUBSCRIPTION }),
    ])
  } catch ({ response }) {
    yield all([
      put({ type: notifTypes.ERROR, ...response.data }),
      put({ type: types.PAID_PASSPPORT_SUBSCRIPTION }),
    ])
  }
}

function* getSearchLookOut({ payload }) {
  try {
    const { data } = yield call(api.get, '/buildingLandmark', {
      params: {
        keyword: payload,
      },
    })

    yield delay(200)
    yield put({ type: types.SEARCHED_PASSPORT_LOOKOUT, results: data.list })
  } catch ({ response }) {
    console.warn(response)
  }
}

function* filterSearch({ type, meta: { field, form } }) {
  const fields = [`selectedCity`, 'filter_category', 'filter_search', 'filter_map']

  yield delay(400)

  // components/public/passport/search
  if (
    (form === 'public-passport-search-filter-form' && fields.includes(field)) ||
    type === '@@redux-form/RESET'
  ) {
    if (form === 'public-passport-search-filter-form') {
      const limit = yield select((state) => state.passport.search.limit)

      yield put({ type: types.SEARCH_PASSPORT, payload: { page: 1, limit } })
    }
  } else if (form === 'public-passport-search-filter-form' && fields === 'searchLookOut') {
    yield put({ type: types.SEARCH_PASSPORT_LOOKOUT })
  }
}

// --ADMIN--

function* fetchPassport({ payload }) {
  console.log(payload)
  try {
    const selector = yield formSelector('admin-passport-filter-form')
    const searchBar = yield select((state) => selector(state, 'admin_search_filter'))
    const dateFilter = yield select((state) => selector(state, 'date_filter'))
    const payment = yield select((state) => selector(state, 'payment_filter'))
    const paymentFilter = payment && payment !== '*' ? payment : undefined
    let plans = yield select((state) => selector(state, 'plan_filter'))
    plans = plans && plans.length ? plans.map((plan) => plan.value) : []
    const term = yield select((state) => selector(state, 'term_filter'))
    const termFilter = term !== '*' ? term : undefined
    const status = yield select((state) => selector(state, 'status_filter'))
    const statusFilter = status !== '*' ? status : undefined
    const places = yield select((state) => selector(state, 'place_filter'))
    const placesFilter = places ? places.map((place) => place.value) : []
    const { limit, page, passportType } = payload
    const path =
      passportType === 'subscription' ? '/passport-subscriptions' : '/passport-invitation'
    const { data } = yield call(apiAdmin.get, path, {
      params: {
        size: limit,
        page,
        from_date: dateFilter ? moment(dateFilter[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
        to_date: dateFilter ? moment(dateFilter[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
        placesFilter,
        statusFilter,
        is_recurrent: termFilter,
        plans,
        payment_method: paymentFilter,
        searchBar,
      },
    })
    yield put({ type: types.FETCHED_PASSPORT, data, passportType })
  } catch ({ message }) {
    console.error(message)
  }
}

function* deletePassportSubscription({ payload }) {
  try {
    yield call(apiAdmin.delete, `/passport-subscriptions/${payload}`)
    const page = yield select((state) => state.passport.admin.subscription.page)
    const limit = yield select((state) => state.passport.admin.subscription.limit)
    yield put({ type: notifTypes.SUCCESS, message: 'Success delete passport' })
    yield call(fetchPassport, { payload: { page, limit, passportType: 'subscription' } })
  } catch ({ message }) {
    console.log(message)
    yield put({ type: notifTypes.ERROR, message: 'Error delete passport' })
  }
}

function* deletePassportInvitation({ payload }) {
  try {
    yield call(apiAdmin.delete, `/passport-invitations/${payload}`)
    const page = yield select((state) => state.passport.admin.invitation.page)
    const limit = yield select((state) => state.passport.admin.invitation.limit)
    yield put({ type: notifTypes.SUCCESS, message: 'Success delete passport invitation' })
    yield call(fetchPassport, { payload: { page, limit, passportType: 'invitation' } })
  } catch ({ message }) {
    yield put({ type: notifTypes.ERROR, message: 'Error delete passport invitation' })
  }
}

function* fetchPassportSubscriptionDetails({ payload }) {
  try {
    const { data } = yield call(apiAdmin.get, `/passport-subscriptions/${payload}`)
    data.start_date = moment(data.start_date).format('LL')
    data.is_recurrent = +data.is_recurrent
    data.paymentMethod = data.payment_method
    yield put({ type: types.FETCHED_PASSPORT_SUBSCRIPTION_DETAILS, data })
  } catch ({ message }) {
    console.error(message)
  }
}

function* updatePassportSubscription({ payload }) {
  try {
    payload.end_date = moment(payload.end_date).format('YYYY-MM-DD')
    yield call(apiAdmin.put, `/passport-subscriptions/${payload.id}`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success edit passport' })
  } catch ({ message }) {
    yield put({ type: notifTypes.ERROR, message })
  }
}

function* sendPassportInvite({ payload }) {
  try {
    payload.is_send_via_email = payload.is_send_via_email || false
    payload.is_generate_trial_plan = payload.is_generate_trial_plan || false
    payload.name = `${payload.first_name} ${payload.last_name}`
    payload.user_place = payload.city_id
    yield call(apiAdmin.post, `/passport-invitation`, payload)
    yield put({ type: notifTypes.SUCCESS, message: 'Success send invite' })
  } catch (err) {
    let message;
    const response = err.response
    if (Array.isArray(response?.data)) {
      message = response.data.map(err => err.message).join(', ');
    } else {
      message = response?.data?.message || ''
    }

    yield put({ type: notifTypes.ERROR, message })
  }
}

// --END ADMIN--

function* passportCheckIn({ payload, resolve }) {
  try {
    payload.date = moment(payload.bookingDate).format('YYYY-MM-DD')
    const { data } = yield call(api.post, `/transactions/passport-check-in`, payload)
    resolve && resolve(data)
  } catch ({ response }) {
    const message = response?.data?.message || 'Error check-in passport.'
    yield put({ type: notifTypes.ERROR, message })
  }
}

function* passportCancel({ payload }) {
  try {
    yield call(api.delete, `/transactions/passport-cancel/${payload}`)
    yield put({ type: types.PASSPORT_CANCELLED, id: payload })
    yield put({ type: notifTypes.SUCCESS, message:'Success cancel passport' })
    yield put({ type: types.FETCHED_PASSPORT_BOOKINGS })
  } catch ({ response }) {
    const message = response?.data?.message || 'Error cancel passport.'
    yield put({ type: notifTypes.ERROR, message })
  }
}

function* passportInviteCorporate({ payload }) {
  try {
    const data = yield call(api.post, `passport-invitations/invite-corporate`, payload)
    yield put({ type: userTypes.FETCH_USER_PASSPORT_SUBSCRIPTION, payload })
    yield put({ type: notifTypes.SUCCESS, message:'successful invite' })
    console.log(data)
  } catch ({ response }) {
    const message = response?.data?.message || 'Error send invite passport.'
    yield put({ type: notifTypes.ERROR, message })
  }
}

function* clientDeletePassportInvitation({ payload }) {
  try {
    const data = yield call(api.delete, `client/passport-invitations/${payload}`, payload)
    yield put({ type: userTypes.FETCH_USER_PASSPORT_SUBSCRIPTION, payload })
    yield put({ type: notifTypes.SUCCESS, message:'Remove guest successfully' })
  } catch ({ response }) {
    const message = response?.data?.message || 'Error delete passport.'
    yield put({ type: notifTypes.ERROR, message })
  }
}

function* passportCorporateRegis({ payload }) {
  try {
    yield call(api.post, `/passport-corporate/regis`, payload)
    yield put({ type: notifTypes.SUCCESS, message: `Message delivered successfully` })
  } catch ({ response }) {
    const message = response?.data?.message || 'Message delivered unsuccessfully'
    yield put({ type: notifTypes.ERROR, message })
  } finally {
    yield put({ type: types.PASSPORT_CORPORATE_REGIS_DONE })
  }
}

function* fetchPassportPaymentDetail({ payload }) {
  try {
    const { data } = yield call(api.post, `/passport/payment-detail`, payload)
    yield put({ type: types.FETCHED_PASSPORT_PAYMENT_DETAIL, paymentDetail: data })
  } catch (err) {
    console.log(err)
  }
}

function* exportPassports() {
  try {
    const response = yield call(apiAdmin.get, '/export-passports', { responseType: 'arraybuffer' })
    FileDownload(response.data, 'passports.xlsx')
    yield put({ type: types.EXPORTED_PASSPORTS })
  } catch ({ response }) {
    yield put({ type: notifTypes.ERROR, message: 'Error Export Passports' })
  }
}

export default function* () {
  yield takeLatest(types.UPDATE_PASSPORT_SUBSCRIPTION, updatePassportSubscription)
  yield takeLatest(types.FETCH_PASSPORT, fetchPassport)
  yield takeLatest(types.DELETE_PASSPORT_SUBSCRIPTION, deletePassportSubscription)
  yield takeLatest(types.DELETE_PASSPORT_INVITATION, deletePassportInvitation)
  yield takeLatest(types.CLIENT_DELETE_PASSPORT_INVITATION, clientDeletePassportInvitation)
  yield takeLatest(types.PASSPORT_CORPORATE_REGIS, passportCorporateRegis)
  yield takeLatest(types.PASSPORT_INVITE_CORPORATE, passportInviteCorporate)
  yield takeLatest(types.PASSPORT_CANCEL, passportCancel)
  yield takeLatest(types.PASSPORT_CHECK_IN, passportCheckIn)
  yield takeLatest(types.SEND_PASSPORT_INVITE, sendPassportInvite)
  yield takeLatest(types.FETCH_PASSPORT_SUBSCRIPTION_DETAILS, fetchPassportSubscriptionDetails)
  yield takeLatest(types.SEARCH_PASSPORT, searchPassport)
  yield takeLatest(types.SEARCH_PASSPORT_LOOKOUT, getSearchLookOut)
  yield takeLatest(types.FETCH_PASSPORT_BOOKINGS, getPassportBookings)
  yield takeLatest(types.FETCH_BOOKING_MEMBERS, getBookingMembers)
  yield takeLatest(types.FETCH_PASSPORT_PLAN_DETAILS, getPassportPlanDetails)
  yield takeLatest(types.PAY_PASSPPORT_SUBSCRIPTION, payPassportSubscription)
  yield takeLatest(types.FETCH_PASSPORT_PAYMENT_DETAIL, fetchPassportPaymentDetail)
  yield takeLatest('@@redux-form/CHANGE', filterSearch)
  yield takeLatest('@@redux-form/RESET', filterSearch)
  yield takeLatest(types.EXPORT_PASSPORTS, exportPassports)
}
